function getCurrentlyExecutingScriptBaseUrl() {
    var _a;
    var currentScript = (_a = document.currentScript) === null || _a === void 0 ? void 0 : _a.src;
    if (!currentScript) {
        throw Error("Cannot get currently executing script!");
    }
    return currentScript.substr(0, currentScript.lastIndexOf("/"));
}
// Note that document.currentScript only works when the script is first loading - it doesn't work in event handlers, callbacks, etc.
// Therefore, the value must be determined at load time and stored until it is needed.
export var CURRENTLY_EXECUTING_SCRIPT_BASE_URL = getCurrentlyExecutingScriptBaseUrl();
