export * from "./ITelemetryService";
export * from "./TelemetryService";
export * from "./ConsoleTelemetryProvider";
export * from "./XrmTelemetryProvider";
export * from "./BusinessTelemetryService";
export * from "./XrmEntityParametersProvider";
export * from "./TelemetryServiceDecorator";
export * from "./BusinessTelemetryLogger";
export var TelemetryServiceName = "TelemetryService";
export var TelemetryServiceDecoratorName = "TelemetryServiceDecorator";
export var BusinessTelemetryServiceName = "BusinessTelemetryService";
