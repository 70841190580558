import { PlaceholderFormat } from "@cxp-shared/contracts";
export var extractPlaceholderCategory = function (placeholder) {
    if ((placeholder === null || placeholder === void 0 ? void 0 : placeholder.category) === "condition") {
        return "PlaceholderCategoryCondition";
    }
    return "PlaceholderCategoryToken";
};
export var extractPlaceholderTypeLabel = function (placeholder) {
    if ((placeholder === null || placeholder === void 0 ? void 0 : placeholder.category) === "condition") {
        return "PlaceholderTypeCondition";
    }
    if ((placeholder === null || placeholder === void 0 ? void 0 : placeholder.category) === "list") {
        return "PlaceholderTypeList";
    }
    if (!(placeholder === null || placeholder === void 0 ? void 0 : placeholder.type) || placeholder.type.length === 0) {
        return "PlaceholderTypeUnset";
    }
    return extractLabelBasedOnType(placeholder.type, placeholder.format);
};
var extractLabelBasedOnType = function (type, format) {
    if (type == null) {
        return "PlaceholderTypeUnset";
    }
    if (type.some(function (k) { return k === "number" || k === "integer"; })) {
        return "PlaceholderTypeNumber";
    }
    if (type.some(function (k) { return k === "boolean"; })) {
        return "PlaceholderTypeBoolean";
    }
    if (type.some(function (k) { return k === "array"; })) {
        return "PlaceholderTypeList";
    }
    if (type.some(function (k) { return k === "string"; }) &&
        (format === PlaceholderFormat.DateTime || format === PlaceholderFormat.Time || format === PlaceholderFormat.Date)) {
        return "PlaceholderTypeDate";
    }
    return "PlaceholderTypeText";
};
