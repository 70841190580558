export var CommonAttributes = {
    status: "statuscode"
};
export var EntityNames = {
    msdynmkt_journeydependency: "msdynmkt_journeydependency"
};
export var LocalizationKeys = {
    LiveJourneyNotificationTextInfo: "EditingLiveJourneysInfo",
    LiveJourneyNotificationTextWarning: "EditingLiveJourneysWarning",
    LiveJourneyNotificationButtonText: "ViewJourneys"
};
export var LifecycleDialog = {
    DialogName: "msdynmkt_depedententitydialog",
    ReadOnlyMode: "readonly",
    SelectMode: "select",
    DialogFormAttributes: {
        dialog_mode: "dialog_mode",
        dialog_result: "dialog_result",
        dependent_entitylist: "dependent_entitylist",
        entity_count: "entity_count"
    },
    DialogResults: {
        continue: "continue",
        copy: "copy"
    }
};
export var JourneyDependencyAttributes = {
    msdynmkt_dependency: "msdynmkt_dependency",
    msdynmkt_journey: "msdynmkt_journey"
};
export var JourneyAttributes = {
    msdynmkt_name: "msdynmkt_name",
    msdynmkt_journeyid: "msdynmkt_journeyid",
    msdynmkt_journeystarttime: "msdynmkt_journeystarttime",
    msdynmkt_journeyendtime: "msdynmkt_journeyendtime",
    msdynmkt_flags: "msdynmkt_flags"
};
export var ShowPageHeaderNotificationEvent = "SHOW_PAGE_HEADER_NOTIFICATION";
export var DismissPageHeaderNotificationEvent = "DISMISS_PAGE_HEADER_NOTIFICATION";
