var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var databaseName = "PersonalizationUICache";
var tableName = "cache";
var fallbackCacheKey = "__PersonalizationUICache";
var storage = null;
var storeItem = function (key, value) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
                var store, request;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getStore()];
                        case 1:
                            store = _a.sent();
                            if (store == null) {
                                window[fallbackCacheKey][key] = value;
                                resolve();
                                return [2 /*return*/];
                            }
                            request = store.put(value, key);
                            request.onsuccess = function () {
                                resolve();
                            };
                            request.onerror = function () {
                                reject();
                            };
                            return [2 /*return*/];
                    }
                });
            }); })];
    });
}); };
var getItem = function (key) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
                var store, request;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getStore("readonly")];
                        case 1:
                            store = _a.sent();
                            if (store == null) {
                                resolve(window[fallbackCacheKey][key] || null);
                                return [2 /*return*/];
                            }
                            request = store.get(key);
                            request.onsuccess = function () {
                                resolve(request.result);
                            };
                            request.onerror = function () {
                                reject();
                            };
                            return [2 /*return*/];
                    }
                });
            }); })];
    });
}); };
var removeItem = function (key) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
                var store, request;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getStore()];
                        case 1:
                            store = _a.sent();
                            if (store == null) {
                                delete window[fallbackCacheKey][key];
                                resolve();
                                return [2 /*return*/];
                            }
                            request = store.delete(key);
                            request.onsuccess = function () {
                                resolve();
                            };
                            request.onerror = function () {
                                reject();
                            };
                            return [2 /*return*/];
                    }
                });
            }); })];
    });
}); };
var init = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        storage = new Promise(function (resolve, reject) {
            var request = indexedDB.open(databaseName);
            request.onsuccess = function () {
                resolve(request.result);
            };
            request.onerror = function () {
                resolve(null);
            };
            request.onupgradeneeded = function (event) {
                var target = event.target;
                var db = target.result;
                var objectStore = db.createObjectStore("cache");
                objectStore.createIndex("value", "value", { unique: false });
                var transaction = target.transaction;
                transaction.oncomplete = function (ev) {
                    resolve(db);
                };
            };
        });
        return [2 /*return*/];
    });
}); };
function getStore(mode) {
    if (mode === void 0) { mode = "readwrite"; }
    return __awaiter(this, void 0, void 0, function () {
        var db, transaction;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, storage];
                case 1:
                    db = _a.sent();
                    if (db == null) {
                        if (!window[fallbackCacheKey]) {
                            console.error("IndexedDB is not available. Falling back to in-memory storage.");
                            window[fallbackCacheKey] = {};
                        }
                        return [2 /*return*/, null];
                    }
                    transaction = db.transaction(tableName, mode);
                    return [2 /*return*/, transaction.objectStore(tableName)];
            }
        });
    });
}
export var indexDbStore = { storeItem: storeItem, getItem: getItem, removeItem: removeItem, init: init };
