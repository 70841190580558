var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { BusinessUnitEntityAttributes } from "../Constants/BusinessUnitEntityAttributes";
export var getCachedBusinessUnitFromUser = function () {
    var _a, _b, _c;
    var userId = (_c = (_b = (_a = Xrm === null || Xrm === void 0 ? void 0 : Xrm.Page) === null || _a === void 0 ? void 0 : _a.context) === null || _b === void 0 ? void 0 : _b.userSettings) === null || _c === void 0 ? void 0 : _c.userId;
    if (!userId) {
        return null;
    }
    var cacheKey = getBusinessUnitFromUserKey(userId);
    var cachedValue;
    try {
        cachedValue = JSON.parse(window.sessionStorage.getItem(cacheKey));
    }
    catch (_d) {
    }
    return [userId, cacheKey, isCacheEntryExpired(cachedValue === null || cachedValue === void 0 ? void 0 : cachedValue.timestamp) ? null : cachedValue.value];
};
export var resolveBusinessUnitFromUser = function () { return __awaiter(void 0, void 0, void 0, function () {
    var _a, userId, cacheKey, cachedValue, businessUnitValue;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = __read(getCachedBusinessUnitFromUser(), 3), userId = _a[0], cacheKey = _a[1], cachedValue = _a[2];
                if (cachedValue && !!cachedValue.id) {
                    return [2 /*return*/, cachedValue.id];
                }
                return [4 /*yield*/, retrieveBusinessUnitDataAndUpdateCache(userId, cacheKey)];
            case 1:
                businessUnitValue = _b.sent();
                return [2 /*return*/, businessUnitValue === null || businessUnitValue === void 0 ? void 0 : businessUnitValue.id];
        }
    });
}); };
export var resolveBusinessUnitLookupValueFromUser = function () { return __awaiter(void 0, void 0, void 0, function () {
    var _a, userId, cacheKey, cachedValue, businessUnitValue;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = __read(getCachedBusinessUnitFromUser(), 3), userId = _a[0], cacheKey = _a[1], cachedValue = _a[2];
                if (cachedValue && !!cachedValue.id) {
                    return [2 /*return*/, {
                            id: cachedValue.id,
                            name: cachedValue.name,
                            entityType: BusinessUnitEntityAttributes.EntityName
                        }];
                }
                return [4 /*yield*/, retrieveBusinessUnitDataAndUpdateCache(userId, cacheKey)];
            case 1:
                businessUnitValue = _b.sent();
                return [2 /*return*/, {
                        id: businessUnitValue === null || businessUnitValue === void 0 ? void 0 : businessUnitValue.id,
                        name: businessUnitValue === null || businessUnitValue === void 0 ? void 0 : businessUnitValue.name,
                        entityType: BusinessUnitEntityAttributes.EntityName
                    }];
        }
    });
}); };
var getBusinessUnitFromUserKey = function (userId) {
    var businessUnitsFromUserKey = "msdynmkt_businessunit_systemusers_";
    return businessUnitsFromUserKey + (userId !== null && userId !== void 0 ? userId : "");
};
var isCacheEntryExpired = function (timestamp) {
    if (timestamp == null) {
        return true;
    }
    var ttl = 15 * 60 * 1000;
    var dateDiff = Date.now() - (timestamp !== null && timestamp !== void 0 ? timestamp : 0);
    return (dateDiff >= ttl);
};
var retrieveBusinessUnitDataAndUpdateCache = function (userId, cacheKey) { return __awaiter(void 0, void 0, void 0, function () {
    var user, businessUnitValue;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, ((_b = (_a = window.Xrm) === null || _a === void 0 ? void 0 : _a.WebApi) === null || _b === void 0 ? void 0 : _b.retrieveRecord("systemuser", userId))];
            case 1:
                user = _c.sent();
                businessUnitValue = {
                    id: user === null || user === void 0 ? void 0 : user._businessunitid_value,
                    name: user ? user[BusinessUnitEntityAttributes.LogicalName] : null
                };
                if (!!businessUnitValue.id) {
                    window.sessionStorage.setItem(cacheKey, JSON.stringify({
                        timestamp: Date.now(),
                        value: businessUnitValue
                    }));
                }
                return [2 /*return*/, businessUnitValue];
        }
    });
}); };
