export var tableMetadataItems = "/api/data/v9.1/msdynmkt_GetDataverseTableMetadataItems";
export var cjoMetadataItems = "/api/data/v9.1/msdynmkt_GetAllCjoEventsMetadataItems";
export var legalMetadataItems = "/api/data/v9.1/msdynmkt_GetAllLegalMetadataItems";
export var metadataItemsFor = "/api/data/v9.1/msdynmkt_GetMetadataItemsFor";
export var eventsByName = "/api/data/v9.1/msdynmkt_GetCjoEventsMetadataItemsByNames";
export var events = "/api/data/v9.1/msdynmkt_eventmetadataset";
export var brandLogicalName = "msdynmkt_brandprofile";
export var datasetConfigurationPluginName = "msdyncrm_retrievechangetrackedentities";
export var unsupporteBrandAttributeLogicalNames = new Set([
    "createdby",
    "createdon",
    "createdonbehalfby",
    "importsequencenumber",
    "modifiedby",
    "modifiedon",
    "modifiedonbehalfby",
    "overriddencreatedon",
    "ownerid",
    "owningbusinessunit",
    "owningteam",
    "owninguser",
    "statecode",
    "statuscode",
    "timezoneruleversionnumber",
    "utcconversiontimezonecode",
    "versionnumber",
    "msdynmkt_brandprofileid",
    "msdynmkt_description"
]);
export var unsupporteBrandAttributeType = new Set([
    "lookup",
    "oneToManyRelationship",
    "manyToManyRelationship",
    "manyToOneRelationship"
]);
