import { FormFieldsFilterRegistry } from "./FormFieldsFilterRegistry";
var isDateAttribute = function (attribute) {
    return attribute.dataType === "datetime" && attribute.format === "DateOnly";
};
var isDateTimeAttribute = function (attribute) {
    return attribute.dataType === "datetime" && attribute.format === "DateAndTime";
};
var isEmailAttribute = function (attribute) {
    return attribute.dataType === "string" && attribute.format === "Email";
};
var isLookupAttribute = function (attribute) {
    return attribute.dataType === "lookup";
};
var isMultiOptionSetAttribute = function (attribute) {
    return attribute.dataType === "multiSelectPicklist";
};
var isNumericAttribute = function (attribute) {
    return (attribute.dataType === "money" || attribute.dataType === "double" || attribute.dataType === "decimal" || attribute.dataType === "integer");
};
var isOptionSetAttribute = function (attribute) {
    return attribute.dataType === "picklist";
};
var isPhoneAttribute = function (attribute) {
    return attribute.dataType === "string" && attribute.format === "Phone";
};
var isTextAreaAttribute = function (attribute) {
    return attribute.dataType === "memo" || (attribute.dataType === "string" && attribute.format === "TextArea");
};
var isTextAttribute = function (attribute) {
    var format = attribute.format;
    return attribute.dataType === "string" && (format === "Text" || format === "PhoneticGuide");
};
var isTwoOptionsAttribute = function (attribute) {
    return attribute.dataType === "boolean";
};
var isUrlAttribute = function (attribute) {
    return attribute.dataType === "string" && attribute.format === "Url";
};
var filtersRegistry = new FormFieldsFilterRegistry();
filtersRegistry.initFilters([
    isDateAttribute,
    isDateTimeAttribute,
    isEmailAttribute,
    isLookupAttribute,
    isMultiOptionSetAttribute,
    isNumericAttribute,
    isOptionSetAttribute,
    isPhoneAttribute,
    isTextAreaAttribute,
    isTextAttribute,
    isTwoOptionsAttribute,
    isUrlAttribute
]);
export var FormFieldsFilterUtils = {
    isDateAttribute: isDateAttribute,
    isDateTimeAttribute: isDateTimeAttribute,
    isEmailAttribute: isEmailAttribute,
    isLookupAttribute: isLookupAttribute,
    isMultiOptionSetAttribute: isMultiOptionSetAttribute,
    isNumericAttribute: isNumericAttribute,
    isOptionSetAttribute: isOptionSetAttribute,
    isPhoneAttribute: isPhoneAttribute,
    isTextAreaAttribute: isTextAreaAttribute,
    isTextAttribute: isTextAttribute,
    isTwoOptionsAttribute: isTwoOptionsAttribute,
    isUrlAttribute: isUrlAttribute,
    filtersRegistry: filtersRegistry
};
