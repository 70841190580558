var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Service } from "@cxp-shared/platform/Service";
import { CommonDataServiceName } from "@cxp-shared/services/CDS";
import { TelemetryServiceName } from "@cxp-shared/services/Telemetry";
import { CachedMetadataStoreServiceName } from "@cxp-shared/metadata";
import { TargetEntitiesExtractorServiceName, PlaceholderUtils, PlaceholderAttributeFilter, extractSourceType, extractBindingIconBasedOnEntity, extractIconBasedOnType } from "@cxp-shared/personalization-core";
import { extractDisplayName, loadMetadata } from "../../Utilities/MetadataUtils";
var PlaceholderVisualizationService = /** @class */ (function (_super) {
    __extends(PlaceholderVisualizationService, _super);
    function PlaceholderVisualizationService() {
        var _this = _super.call(this) || this;
        _this.loadBindingVisualization = function (_a) {
            var selectedBinding = _a.selectedBinding, placeholderCategory = _a.placeholderCategory, productSource = _a.productSource;
            return __awaiter(_this, void 0, void 0, function () {
                var constantValue, isStaticBinding_1, selectedItems, bindingsToLoad, metadata, result, path, firstLevel_1, _loop_1, _b, _c, _d, index, selectedItem, state_1, selectedRecord, recordId, rootEntity, record, entityMetadata, nameAttribute, exception_1, exception_2;
                var e_1, _e;
                var _f, _g, _h, _j, _k;
                return __generator(this, function (_l) {
                    switch (_l.label) {
                        case 0:
                            _l.trys.push([0, 7, , 8]);
                            if (!selectedBinding) {
                                return [2 /*return*/, {
                                        hasError: true,
                                        isAttributeMissing: true,
                                        selectedBindingItems: []
                                    }];
                            }
                            if (PlaceholderUtils.isBindingConstant(selectedBinding)) {
                                constantValue = PlaceholderUtils.getBindingConstantValue(selectedBinding);
                                return [2 /*return*/, {
                                        hasError: false,
                                        isAttributeMissing: false,
                                        selectedBindingItems: [{ key: constantValue, displayName: constantValue }]
                                    }];
                            }
                            isStaticBinding_1 = PlaceholderUtils.isStaticBinding(selectedBinding);
                            selectedItems = this.targetEntitiesExtractor.getBindingItems(selectedBinding) || [];
                            bindingsToLoad = [selectedBinding];
                            return [4 /*yield*/, loadMetadata(this.targetEntitiesExtractor, this.metadataStore, bindingsToLoad)];
                        case 1:
                            metadata = _l.sent();
                            result = [];
                            path = [];
                            firstLevel_1 = true;
                            _loop_1 = function (index, selectedItem) {
                                // ToDo: get rid of extractSourceType
                                var attribute = metadata.find(function (attribute) {
                                    return attribute.logicalName === selectedItem.output &&
                                        (extractSourceType(attribute, productSource, firstLevel_1, isStaticBinding_1) === selectedItem.source ||
                                            (placeholderCategory === "list" &&
                                                extractSourceType(attribute, productSource, false, isStaticBinding_1) === selectedItem.source)) &&
                                        selectedItem.sourceType === attribute.sourceLogicalName;
                                });
                                if (attribute != null && firstLevel_1) {
                                    firstLevel_1 = false;
                                    path.push(attribute.sourceLogicalName);
                                    result.push({
                                        displayName: attribute.sourceDisplayName,
                                        icon: extractBindingIconBasedOnEntity(attribute.sourceLogicalName, (_f = PlaceholderUtils.getRootBinding(selectedItem)) === null || _f === void 0 ? void 0 : _f.source, attribute.iconPath),
                                        key: "PersonalizationSelectedItem-".concat(path.join("-")),
                                        metadata: attribute,
                                        path: path,
                                        appendListIcon: false
                                    });
                                }
                                // Selected attribute no longer exists on the entity, stop processing and raise the error flag
                                if (attribute == null) {
                                    return { value: {
                                            hasError: true,
                                            isAttributeMissing: true,
                                            selectedBindingItems: result
                                        } };
                                }
                                var icon = extractIconBasedOnType(attribute.dataType);
                                path.push(attribute.logicalName);
                                result.push(__assign(__assign({}, extractDisplayName(attribute)), { icon: { iconType: "FontIcon", value: icon }, key: "PersonalizationSelectedItem-".concat(path.join("-")), metadata: attribute, path: path, appendListIcon: (placeholderCategory === "general" || placeholderCategory === "condition") && PlaceholderAttributeFilter.hasListType(attribute) }));
                            };
                            try {
                                for (_b = __values(selectedItems.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                                    _d = __read(_c.value, 2), index = _d[0], selectedItem = _d[1];
                                    state_1 = _loop_1(index, selectedItem);
                                    if (typeof state_1 === "object")
                                        return [2 /*return*/, state_1.value];
                                }
                            }
                            catch (e_1_1) { e_1 = { error: e_1_1 }; }
                            finally {
                                try {
                                    if (_c && !_c.done && (_e = _b.return)) _e.call(_b);
                                }
                                finally { if (e_1) throw e_1.error; }
                            }
                            selectedRecord = null;
                            recordId = (_j = (_h = (_g = PlaceholderUtils.getRootBinding(selectedBinding)) === null || _g === void 0 ? void 0 : _g.inputs) === null || _h === void 0 ? void 0 : _h.recordId) === null || _j === void 0 ? void 0 : _j.value;
                            rootEntity = (_k = result[0]) === null || _k === void 0 ? void 0 : _k.metadata;
                            if (!(rootEntity && recordId)) return [3 /*break*/, 6];
                            _l.label = 2;
                        case 2:
                            _l.trys.push([2, 5, , 6]);
                            return [4 /*yield*/, window.Xrm.WebApi.retrieveRecord(rootEntity.sourceLogicalName, recordId)];
                        case 3:
                            record = _l.sent();
                            return [4 /*yield*/, window.Xrm.Utility.getEntityMetadata(rootEntity.sourceLogicalName, [])];
                        case 4:
                            entityMetadata = _l.sent();
                            nameAttribute = entityMetadata["PrimaryNameAttribute"];
                            selectedRecord = {
                                id: recordId,
                                displayName: record[nameAttribute]
                            };
                            return [3 /*break*/, 6];
                        case 5:
                            exception_1 = _l.sent();
                            this.telemetryService.logError("BindingVisualization: Failed to fetch display name for record of entity ".concat(rootEntity.sourceLogicalName, " id ").concat(recordId), {
                                entityName: rootEntity.logicalName,
                                exception: exception_1,
                                errorMessage: exception_1 === null || exception_1 === void 0 ? void 0 : exception_1.message,
                                errorStack: exception_1 === null || exception_1 === void 0 ? void 0 : exception_1.stack
                            });
                            selectedRecord = {
                                id: recordId,
                                displayName: null
                            };
                            return [3 /*break*/, 6];
                        case 6: return [2 /*return*/, {
                                hasError: false,
                                isAttributeMissing: false,
                                selectedBindingItems: result,
                                selectedRecord: selectedRecord
                            }];
                        case 7:
                            exception_2 = _l.sent();
                            this.telemetryService.logError("BindingVisualization: Failed to load visualization for binding", {
                                exception: exception_2,
                                errorMessage: exception_2 === null || exception_2 === void 0 ? void 0 : exception_2.message,
                                errorStack: exception_2 === null || exception_2 === void 0 ? void 0 : exception_2.stack
                            });
                            return [2 /*return*/, {
                                    hasError: true,
                                    isAttributeMissing: false,
                                    selectedBindingItems: []
                                }];
                        case 8: return [2 /*return*/];
                    }
                });
            });
        };
        return _this;
    }
    PlaceholderVisualizationService.prototype.serviceStart = function (appContext) {
        _super.prototype.serviceStart.call(this, appContext);
        this.cds = this.appContext.getService(CommonDataServiceName);
        this.telemetryService = this.appContext.getService(TelemetryServiceName);
        this.targetEntitiesExtractor = this.appContext.getService(TargetEntitiesExtractorServiceName);
        this.metadataStore = this.appContext.getService(CachedMetadataStoreServiceName);
    };
    return PlaceholderVisualizationService;
}(Service));
export { PlaceholderVisualizationService };
