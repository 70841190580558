var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { createFormLoaderFactory } from "./CreateFormLoaderFactory";
import { FormUtility } from "./FormUtility";
import { HtmlAttributes as CommonHtmlAttributes } from "../../../Common/Constants/HtmlAttributes";
import { HtmlAttributes } from "./Constants/HtmlAttributes";
var FormPlaceholder = /** @class */ (function (_super) {
    __extends(FormPlaceholder, _super);
    function FormPlaceholder(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            formId: props.formId,
            formApiBaseUrl: props.formApiBaseUrl,
            formUrl: props.formUrl,
            readableEventId: props.readableEventId,
            feedbackPosition: props.feedbackPosition
        };
        return _this;
    }
    FormPlaceholder.prototype.componentDidMount = function () {
        var form = FormUtility.createFormDescriptorFromPlaceholderElement(this.formPlaceholder);
        var loader = createFormLoaderFactory()();
        loader.loadForm(form);
    };
    FormPlaceholder.prototype.render = function () {
        var _this = this;
        var props = {
            ref: function (ref) { return (_this.formPlaceholder = ref); }
        };
        props[CommonHtmlAttributes.unboundForm] = "true";
        props[HtmlAttributes.formId] = this.state.formId;
        props[HtmlAttributes.formApiBaseUrl] = this.state.formApiBaseUrl;
        props[HtmlAttributes.formCachedUrl] = this.state.formUrl;
        if (this.state.readableEventId && this.state.readableEventId.length && this.state.readableEventId.length > 0) {
            props[HtmlAttributes.dataReadableEventId] = this.state.readableEventId;
        }
        if (this.state.feedbackPosition && this.state.feedbackPosition.length && this.state.feedbackPosition.length > 0) {
            props[CommonHtmlAttributes.feedbackPosition] = this.state.feedbackPosition;
        }
        return React.createElement("div", props);
    };
    return FormPlaceholder;
}(React.Component));
export { FormPlaceholder };
