import { BinaryOperator, ConditionOperandType } from "@cxp-shared/contracts";
import { TemplateExpressionParser } from "./TemplateExpressionParser";
var parseExpressionWithBinding = function (operandType, expression) {
    var parsedExpression = TemplateExpressionParser.parseTemplateLanguageExpression(expression);
    switch (operandType) {
        case ConditionOperandType.number:
            var parsedNumber = parseNumber(parsedExpression);
            return parsedNumber;
        case ConditionOperandType.boolean:
            var parsedBoolean = parseBoolean(parsedExpression);
            return parsedBoolean;
        default:
            var parsedString = parseString(parsedExpression);
            return parsedString;
    }
};
var parseNumber = function (parsedExpression) {
    if (parsedExpression.functionName === "equals") {
        return {
            operator: BinaryOperator.equals,
            value: "".concat(parsedExpression.parameters[1])
        };
    }
    if (parsedExpression.functionName === "greater") {
        return {
            operator: BinaryOperator.greaterThan,
            value: "".concat(parsedExpression.parameters[1])
        };
    }
    if (parsedExpression.functionName === "less") {
        return {
            operator: BinaryOperator.lessThan,
            value: "".concat(parsedExpression.parameters[1])
        };
    }
    if (parsedExpression.functionName === "greaterOrEquals") {
        return {
            operator: BinaryOperator.greaterThanEqualsTo,
            value: "".concat(parsedExpression.parameters[1])
        };
    }
    if (parsedExpression.functionName === "lessOrEquals") {
        return {
            operator: BinaryOperator.lessThanEqualsTo,
            value: "".concat(parsedExpression.parameters[1])
        };
    }
    if (parsedExpression.functionName === "not") {
        var subFunction = parseFunction(parsedExpression.parameters[0]);
        if (subFunction.functionName === "equals") {
            return {
                operator: BinaryOperator.notEquals,
                value: "".concat(subFunction.parameters[1])
            };
        }
    }
    throw new Error("Invalid structure for number expression: ".concat(parsedExpression.expression));
};
var parseBoolean = function (parsedExpression) {
    if (parsedExpression.functionName === "equals") {
        return {
            operator: BinaryOperator.equals,
            value: "".concat(parsedExpression.parameters[1]).toLowerCase()
        };
    }
    if (parsedExpression.functionName === "not") {
        var subFunction = parseFunction(parsedExpression.parameters[0]);
        if (subFunction.functionName === "equals") {
            return {
                operator: BinaryOperator.notEquals,
                value: "".concat(subFunction.parameters[1]).toLowerCase()
            };
        }
    }
    throw new Error("Invalid structure for string expression: ".concat(parsedExpression.expression));
};
var parseString = function (parsedExpression) {
    if (parsedExpression.functionName === "equals") {
        return {
            operator: BinaryOperator.equals,
            value: "".concat(parsedExpression.parameters[1])
        };
    }
    if (parsedExpression.functionName === "not") {
        var subFunction = parseFunction(parsedExpression.parameters[0]);
        if (subFunction.functionName === "equals") {
            return {
                operator: BinaryOperator.notEquals,
                value: "".concat(subFunction.parameters[1])
            };
        }
    }
    throw new Error("Invalid structure for string expression: ".concat(parsedExpression.expression));
};
var parseFunction = function (parameter) {
    if (parameter["functionName"]) {
        return parameter;
    }
    throw new Error("Expected function, got parameter: ".concat(parameter));
};
export var ExpressionParser = {
    parseExpressionWithBinding: parseExpressionWithBinding
};
