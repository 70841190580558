var filterAllowedAttributes = function (attribute) {
    return attribute.dataType !== "manyToOneRelationship";
};
var filterAllowedEachAttributes = function (metadata, allowPicklist) {
    if (allowPicklist === void 0) { allowPicklist = false; }
    return metadata.filter(function (metadataItem) {
        return (metadataItem.dataType === "bigInt" ||
            metadataItem.dataType === "money" ||
            metadataItem.dataType === "dateTime" ||
            metadataItem.dataType === "decimal" ||
            metadataItem.dataType === "integer" ||
            metadataItem.dataType === "string" ||
            metadataItem.dataType === "double" ||
            metadataItem.dataType === "memo" ||
            metadataItem.dataType === "boolean" ||
            metadataItem.dataType === "uniqueidentifier" ||
            metadataItem.dataType === "entityValue" ||
            metadataItem.dataType === "entityCollection" ||
            (allowPicklist && hasPicklistType(metadataItem))) &&
            !metadataItem.isSecured;
    });
};
var filterAllowedListAttributes = function (attribute) {
    return attribute.dataType === "oneToManyRelationship" || attribute.dataType === "manyToManyRelationship" || attribute.dataType === "lookup";
};
var filterAllowedConditionAttributes = function (attribute, allowList) {
    return (attribute.dataType === "bigInt" ||
        attribute.dataType === "money" ||
        attribute.dataType === "dateTime" ||
        attribute.dataType === "decimal" ||
        attribute.dataType === "integer" ||
        attribute.dataType === "string" ||
        attribute.dataType === "double" ||
        attribute.dataType === "lookup" ||
        attribute.dataType === "multiSelectPicklist" ||
        attribute.dataType === "memo" ||
        attribute.dataType === "picklist" ||
        attribute.dataType === "status" ||
        attribute.dataType === "state" ||
        attribute.dataType === "boolean" ||
        attribute.dataType === "uniqueidentifier" ||
        attribute.dataType === "entityValue" ||
        attribute.dataType === "entityCollection" ||
        (allowList && hasListType(attribute)));
};
var filterAllowedBrandConditionAttributes = function (attribute, allowList) {
    return (attribute.dataType === "bigInt" ||
        attribute.dataType === "money" ||
        attribute.dataType === "dateTime" ||
        attribute.dataType === "decimal" ||
        attribute.dataType === "integer" ||
        attribute.dataType === "string" ||
        attribute.dataType === "double" ||
        attribute.dataType === "lookup" ||
        attribute.dataType === "memo" ||
        attribute.dataType === "boolean" ||
        attribute.dataType === "uniqueidentifier" ||
        attribute.dataType === "entityValue" ||
        attribute.dataType === "entityCollection" ||
        (allowList && hasListType(attribute)));
};
var filterAllowedTokenAttributes = function (attribute, allowList, allowPicklist) {
    if (allowPicklist === void 0) { allowPicklist = false; }
    return (attribute.dataType === "bigInt" ||
        attribute.dataType === "money" ||
        attribute.dataType === "dateTime" ||
        attribute.dataType === "decimal" ||
        attribute.dataType === "integer" ||
        attribute.dataType === "string" ||
        attribute.dataType === "double" ||
        attribute.dataType === "lookup" ||
        attribute.dataType === "memo" ||
        attribute.dataType === "boolean" ||
        attribute.dataType === "uniqueidentifier" ||
        attribute.dataType === "entityValue" ||
        attribute.dataType === "entityCollection" ||
        (allowPicklist && hasPicklistType(attribute)) ||
        (allowList && hasListType(attribute)));
};
var filterEventAttributes = function (metadata) {
    return metadata.filter(function (attribute) {
        return (attribute.logicalName !== "msdynmkt_entityid" &&
            attribute.logicalName !== "msdynmkt_contactpoint_email" &&
            attribute.logicalName !== "msdynmkt_contactpoint_phone" &&
            attribute.logicalName !== "msdynmkt_targetentity" &&
            attribute.logicalName !== "msdynmkt_bindingid" &&
            attribute.logicalName !== "msdynmkt_customerjourneyid_uuid" &&
            attribute.logicalName !== "msdynmkt_emailid_uuid" &&
            attribute.logicalName !== "msdynmkt_formsubmissionfields" &&
            attribute.logicalName !== "msdynmkt_formsubmissionid_uuid" &&
            attribute.logicalName !== "msdynmkt_marketingformid_uuid" &&
            attribute.logicalName !== "msdynmkt_marketinginteractionversion" &&
            attribute.logicalName !== "msdynmkt_internalmarketinginteractionid" &&
            attribute.logicalName !== "msdynmkt_profileid" &&
            attribute.logicalName !== "msdynmkt_marketingformid" &&
            attribute.logicalName !== "msdynmkt_browserid" &&
            attribute.logicalName !== "msdynmkt_browserid_uuid" &&
            attribute.logicalName !== "msdynmkt_devicetypeid" &&
            attribute.logicalName !== "msdynmkt_devicetypeid_uuid" &&
            attribute.logicalName !== "msdynmkt_operatingsystemid" &&
            attribute.logicalName !== "msdynmkt_operatingsystemid_uuid" &&
            attribute.logicalName !== "msdynmkt_emailclientid" &&
            attribute.logicalName !== "msdynmkt_emailclientid_uuid");
    });
};
var hasListType = function (attribute) {
    return (attribute === null || attribute === void 0 ? void 0 : attribute.dataType) === "oneToManyRelationship" || (attribute === null || attribute === void 0 ? void 0 : attribute.dataType) === "manyToManyRelationship";
};
var hasPicklistType = function (attribute) {
    return (attribute === null || attribute === void 0 ? void 0 : attribute.dataType) === "picklist" || (attribute === null || attribute === void 0 ? void 0 : attribute.dataType) === "multiSelectPicklist";
};
export var PlaceholderAttributeFilter = {
    filterAllowedEachAttributes: filterAllowedEachAttributes,
    filterAllowedListAttributes: filterAllowedListAttributes,
    filterAllowedConditionAttributes: filterAllowedConditionAttributes,
    filterAllowedBrandConditionAttributes: filterAllowedBrandConditionAttributes,
    filterAllowedTokenAttributes: filterAllowedTokenAttributes,
    filterEventAttributes: filterEventAttributes,
    filterAllowedAttributes: filterAllowedAttributes,
    hasListType: hasListType
};
