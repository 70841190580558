import { BusinessUnitEntityAttributes } from "../Constants/BusinessUnitEntityAttributes";
export var businessUnitToEntityReference = function (businessUnit) {
    if (!businessUnit || (Array.isArray(businessUnit) && businessUnit.length === 0)) {
        return null;
    }
    var owningbusinessunit = Array.isArray(businessUnit) ?
        { "owningbusinessunit@odata.bind": "/ businessunits(".concat(businessUnit[0].id, ")") } :
        { "owningbusinessunit@odata.bind": "/ businessunits(".concat(businessUnit.id, ")") };
    return owningbusinessunit;
};
export var businessUnitToLookup = function (businessUnit) {
    var businessUnitId = Array.isArray(businessUnit) ? businessUnit[0] : businessUnit;
    if (businessUnitId) {
        return [
            {
                id: businessUnitId,
                name: "",
                entityType: BusinessUnitEntityAttributes.EntityName
            }
        ];
    }
    return null;
};
