var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ConsoleTelemetryProvider, TelemetryService, TelemetryServiceName, XrmTelemetryProvider } from "@cxp-shared/services/Telemetry";
import { PerformanceService, PerformanceServiceName } from "@cxp-shared/services/Performance";
import { AppContext } from "@cxp-shared/platform/AppContext";
import { CommonDataService } from "./CommonDataService";
export var initUniqueInitialName = function (context, formAttributeName, entityName, entityPluralName, nameTemplate) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        initUniqueInitialNameWithCDS(context, formAttributeName, entityName, entityPluralName, nameTemplate, createCDS(context));
        return [2 /*return*/];
    });
}); };
var initUniqueInitialNameWithCDS = function (context, formAttributeName, entityName, entityPluralName, nameTemplate, cds) { return __awaiter(void 0, void 0, void 0, function () {
    var nameAttribute, allNames, composeName, attemptCount, attemptName;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                nameAttribute = context.getFormContext().getAttribute(formAttributeName);
                if (!!nameAttribute.getValue()) {
                    return [2 /*return*/];
                }
                return [4 /*yield*/, getAllEntityNamesLowerCased(context, entityName, entityPluralName, formAttributeName, cds)];
            case 1:
                allNames = _a.sent();
                composeName = function (count) { return formatString(nameTemplate, "".concat(count)); };
                attemptCount = 1;
                attemptName = composeName(attemptCount);
                // Cap attempts to 1000, avoiding any infinite loops. Fall back to t("untitledEmail") and show error otherwise
                while (allNames.includes(attemptName.toLocaleLowerCase()) && attemptCount < 1000) {
                    attemptName = composeName(attemptCount);
                    attemptCount++;
                }
                nameAttribute.setValue(attemptName);
                return [2 /*return*/];
        }
    });
}); };
var getAllEntityNamesLowerCased = function (context, entityName, entityPluralName, nameFieldName, cds) { return __awaiter(void 0, void 0, void 0, function () {
    var query, cdsEmails;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                query = cds.buildQueryString(entityName, [nameFieldName], nameFieldName, false, 1, 5000);
                return [4 /*yield*/, cds.getRecords(entityPluralName, query)];
            case 1:
                cdsEmails = _a.sent();
                if (cdsEmails) {
                    return [2 /*return*/, cdsEmails.filter(function (record) { return !!record[nameFieldName]; }).map(function (record) { return record[nameFieldName].toLocaleLowerCase(); }).sort()];
                }
                return [2 /*return*/, []];
        }
    });
}); };
function formatString(str) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    return str.replace(/{(\d+)}/g, function (match, idx) {
        return args[idx] !== undefined
            ? args[idx]
            : match;
    });
}
;
var createCDS = function (context) {
    var baseUrl = context.getContext().getClientUrl();
    var cds = new CommonDataService(baseUrl);
    var appContext = new AppContext();
    appContext.addService(PerformanceServiceName, PerformanceService);
    appContext.addService(TelemetryServiceName, TelemetryService, [
        new ConsoleTelemetryProvider("InitUniqueInitialName"),
        new XrmTelemetryProvider("InitUniqueInitialName")
    ]);
    cds.serviceStart(appContext);
    return cds;
};
