import React from "react";
import { useConst } from "@fluentui/react-hooks";
import { useFeatureFlagsIfInitialized } from "./FeatureFlagsHook";
import { useMktFeatureFlagsIfInitialized } from "./MktFeatureConfiguration/MktFeatureFlagsHook";
export var FeatureFlagsWaitForInitialization = function (props) {
    // Using 'useConst' here since changing the value would change the number of hooks called in this function.
    // This just ensures that the client cannot change this.
    var includeMarketing = useConst(props.includeMktFF);
    var mktFeatureFlags;
    if (includeMarketing) {
        mktFeatureFlags = useMktFeatureFlagsIfInitialized();
    }
    var featureFlags = useFeatureFlagsIfInitialized();
    if (!featureFlags || (includeMarketing && !mktFeatureFlags)) {
        return props.fallback ? React.createElement(React.Fragment, null, props.fallback) : React.createElement(React.Fragment, null);
    }
    return React.createElement(React.Fragment, null, props.children);
};
