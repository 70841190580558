var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ExpirationCache } from "./ExpirationCache";
export var PromiseCacheName = "cxp_personalization_async_promise_cache";
export var PromiseCacheTTL = 10 * 1000;
var AsyncDistributedExpirationCacheWithRefresh = /** @class */ (function () {
    function AsyncDistributedExpirationCacheWithRefresh(storage, now, expirationTimeout, refreshTimeout, lockTimeout, logError, logInfo) {
        if (expirationTimeout === void 0) { expirationTimeout = 15 * 60 * 1000; }
        if (refreshTimeout === void 0) { refreshTimeout = expirationTimeout / 2; }
        if (lockTimeout === void 0) { lockTimeout = 10 * 1000; }
        var _this = this;
        this.now = now;
        this.refreshTimeout = refreshTimeout;
        this.lockTimeout = lockTimeout;
        this.logError = logError;
        this.logInfo = logInfo;
        this.getMultiple = function (keyPrefix, itemKeys, action, allowStaleData) {
            if (allowStaleData === void 0) { allowStaleData = false; }
            return __awaiter(_this, void 0, void 0, function () {
                var internalResult_1, existingCacheItems, now_1, keysToLoad_1, hasAllData_1, hasStaleData, result_1, loadedResults, result, error_1;
                var _this = this;
                var _a, _b, _c, _d, _e;
                return __generator(this, function (_f) {
                    switch (_f.label) {
                        case 0:
                            _f.trys.push([0, 3, , 4]);
                            (_a = this.logInfo) === null || _a === void 0 ? void 0 : _a.call(this, "Requested multiple [".concat(itemKeys.join(","), "]"));
                            internalResult_1 = {};
                            return [4 /*yield*/, Promise.all(itemKeys.map(function (key) { return _this.expirationCache.getInternalValue("".concat(keyPrefix, "_").concat(key), allowStaleData); }))];
                        case 1:
                            existingCacheItems = _f.sent();
                            now_1 = this.now();
                            keysToLoad_1 = [];
                            hasAllData_1 = true;
                            existingCacheItems.forEach(function (cachedData, idx) {
                                if (cachedData == null || cachedData.createdOnTimestamp + _this.refreshTimeout < now_1) {
                                    keysToLoad_1.push(itemKeys[idx]);
                                }
                                if (cachedData == null) {
                                    hasAllData_1 = false;
                                }
                                internalResult_1[itemKeys[idx]] = cachedData === null || cachedData === void 0 ? void 0 : cachedData.value;
                            });
                            hasStaleData = keysToLoad_1.length > 0;
                            if (hasAllData_1 && hasStaleData) {
                                (_b = this.logInfo) === null || _b === void 0 ? void 0 : _b.call(this, "Reloading multiple stale data [".concat(keysToLoad_1.join(","), "]"));
                                this.refreshMultipleData(keyPrefix, keysToLoad_1, action, allowStaleData);
                            }
                            if (hasAllData_1) {
                                (_c = this.logInfo) === null || _c === void 0 ? void 0 : _c.call(this, "Retrieved multiple from cache [".concat(itemKeys.join(","), "]"));
                                result_1 = Object.values(internalResult_1);
                                return [2 /*return*/, result_1];
                            }
                            (_d = this.logInfo) === null || _d === void 0 ? void 0 : _d.call(this, "Loading new multiple data [".concat(keysToLoad_1.join(","), "]"));
                            return [4 /*yield*/, this.refreshMultipleData(keyPrefix, keysToLoad_1, action, allowStaleData)];
                        case 2:
                            loadedResults = _f.sent();
                            Object.entries(loadedResults).forEach(function (_a) {
                                var _b = __read(_a, 2), key = _b[0], item = _b[1];
                                return (internalResult_1[key] = item);
                            });
                            result = Object.values(internalResult_1);
                            return [2 /*return*/, result];
                        case 3:
                            error_1 = _f.sent();
                            (_e = this.logError) === null || _e === void 0 ? void 0 : _e.call(this, error_1);
                            throw error_1;
                        case 4: return [2 /*return*/];
                    }
                });
            });
        };
        this.get = function (key, action, allowStaleData) {
            if (allowStaleData === void 0) { allowStaleData = false; }
            return __awaiter(_this, void 0, void 0, function () {
                var lockCacheKey, cachedData, now, lock_1, error_2, lock, lockCacheItem, savedLock, promise, data, error_3, error_4;
                var _a, _b, _c, _d, _e, _f;
                return __generator(this, function (_g) {
                    switch (_g.label) {
                        case 0:
                            lockCacheKey = "LockCache-".concat(key);
                            return [4 /*yield*/, this.storageInitPromise];
                        case 1:
                            _g.sent();
                            _g.label = 2;
                        case 2:
                            _g.trys.push([2, 23, , 24]);
                            (_a = this.logInfo) === null || _a === void 0 ? void 0 : _a.call(this, "Requested ".concat(key));
                            _g.label = 3;
                        case 3:
                            _g.trys.push([3, 8, , 9]);
                            return [4 /*yield*/, this.expirationCache.getInternalValue(key, allowStaleData)];
                        case 4:
                            cachedData = _g.sent();
                            if (!cachedData) return [3 /*break*/, 7];
                            (_b = this.logInfo) === null || _b === void 0 ? void 0 : _b.call(this, "Item ".concat(key, " is in cache"));
                            now = this.now();
                            if (!(cachedData.createdOnTimestamp + this.refreshTimeout < now)) return [3 /*break*/, 6];
                            return [4 /*yield*/, this.lockCache.getValue(lockCacheKey)];
                        case 5:
                            lock_1 = _g.sent();
                            if (!lock_1) {
                                this.refreshData(key, lockCacheKey, action, allowStaleData);
                            }
                            _g.label = 6;
                        case 6: return [2 /*return*/, cachedData.value];
                        case 7: return [3 /*break*/, 9];
                        case 8:
                            error_2 = _g.sent();
                            (_c = this.logError) === null || _c === void 0 ? void 0 : _c.call(this, error_2);
                            return [3 /*break*/, 9];
                        case 9: return [4 /*yield*/, this.lockCache.getValue(lockCacheKey)];
                        case 10:
                            lock = _g.sent();
                            if (!lock) return [3 /*break*/, 12];
                            return [4 /*yield*/, this.tryRetrieveValue(key, this.expirationCache, lock, allowStaleData)];
                        case 11: return [2 /*return*/, _g.sent()];
                        case 12:
                            lockCacheItem = { requestedAt: this.now() };
                            return [4 /*yield*/, this.lockCache.storeValue(lockCacheKey, lockCacheItem)];
                        case 13:
                            _g.sent();
                            return [4 /*yield*/, this.lockCache.getValue(lockCacheKey)];
                        case 14:
                            savedLock = _g.sent();
                            if (!(savedLock != null && savedLock.requestedAt !== lockCacheItem.requestedAt)) return [3 /*break*/, 16];
                            return [4 /*yield*/, this.tryRetrieveValue(key, this.expirationCache, savedLock, allowStaleData)];
                        case 15: return [2 /*return*/, _g.sent()];
                        case 16:
                            (_d = this.logInfo) === null || _d === void 0 ? void 0 : _d.call(this, "Retrieving ".concat(key));
                            promise = action();
                            return [4 /*yield*/, promise];
                        case 17:
                            data = _g.sent();
                            _g.label = 18;
                        case 18:
                            _g.trys.push([18, 20, , 21]);
                            return [4 /*yield*/, this.expirationCache.storeValue(key, data)];
                        case 19:
                            _g.sent();
                            return [3 /*break*/, 21];
                        case 20:
                            error_3 = _g.sent();
                            (_e = this.logError) === null || _e === void 0 ? void 0 : _e.call(this, error_3);
                            return [3 /*break*/, 21];
                        case 21: return [4 /*yield*/, this.lockCache.removeValue(lockCacheKey)];
                        case 22:
                            _g.sent();
                            return [2 /*return*/, data];
                        case 23:
                            error_4 = _g.sent();
                            (_f = this.logError) === null || _f === void 0 ? void 0 : _f.call(this, error_4);
                            this.lockCache.removeValue(lockCacheKey);
                            throw error_4;
                        case 24: return [2 /*return*/];
                    }
                });
            });
        };
        this.getCachedItems = function (keyPrefix, itemKeys, allowStaleData) {
            if (allowStaleData === void 0) { allowStaleData = false; }
            return __awaiter(_this, void 0, void 0, function () {
                var internalResult_2, existingCacheItems, error_5;
                var _this = this;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            _d.trys.push([0, 2, , 3]);
                            (_a = this.logInfo) === null || _a === void 0 ? void 0 : _a.call(this, "Requested try get cached items: [".concat(itemKeys.join(","), "]"));
                            internalResult_2 = {};
                            return [4 /*yield*/, Promise.all(itemKeys.map(function (key) { return _this.expirationCache.getInternalValue("".concat(keyPrefix, "_").concat(key), allowStaleData); }))];
                        case 1:
                            existingCacheItems = _d.sent();
                            existingCacheItems.forEach(function (cachedData, idx) {
                                internalResult_2[itemKeys[idx]] = {
                                    data: cachedData === null || cachedData === void 0 ? void 0 : cachedData.value,
                                    found: !!cachedData,
                                    key: itemKeys[idx]
                                };
                            });
                            (_b = this.logInfo) === null || _b === void 0 ? void 0 : _b.call(this, "Retrieved multiple from cache [".concat(itemKeys.join(","), "]"));
                            return [2 /*return*/, internalResult_2];
                        case 2:
                            error_5 = _d.sent();
                            (_c = this.logError) === null || _c === void 0 ? void 0 : _c.call(this, error_5);
                            throw error_5;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        this.refreshMultipleData = function (keyPrefix, keysToLoad, action, allowStaleData) { return __awaiter(_this, void 0, void 0, function () {
            var internalResult_3, missingItemKeys, now_2, alreadyLoadingItems, itemsToLoadKeys, data, error_6;
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 6, , 8]);
                        if (keysToLoad.length === 0) {
                            return [2 /*return*/, {}];
                        }
                        return [4 /*yield*/, this.tryRetrieveMultiple(keyPrefix, keysToLoad, allowStaleData)];
                    case 1:
                        internalResult_3 = _b.sent();
                        missingItemKeys = Object.entries(internalResult_3)
                            .filter(function (_a) {
                            var _b = __read(_a, 2), key = _b[0], item = _b[1];
                            return !item;
                        })
                            .map(function (_a) {
                            var _b = __read(_a, 2), key = _b[0], item = _b[1];
                            return key;
                        });
                        now_2 = this.now();
                        return [4 /*yield*/, Promise.all(missingItemKeys.map(function (key) {
                                var lockCacheItem = { requestedAt: now_2 };
                                return _this.lockCache.storeValue("LockCache-".concat(keyPrefix, "_").concat(key), lockCacheItem);
                            }))];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, this.tryRetrieveMultiple(keyPrefix, missingItemKeys, allowStaleData, now_2)];
                    case 3:
                        alreadyLoadingItems = _b.sent();
                        Object.entries(alreadyLoadingItems)
                            .filter(function (_a) {
                            var _b = __read(_a, 2), key = _b[0], item = _b[1];
                            return item;
                        })
                            .forEach(function (_a) {
                            var _b = __read(_a, 2), key = _b[0], item = _b[1];
                            return (internalResult_3[key] = item);
                        });
                        itemsToLoadKeys = Object.entries(alreadyLoadingItems)
                            .filter(function (_a) {
                            var _b = __read(_a, 2), key = _b[0], item = _b[1];
                            return !item;
                        })
                            .map(function (_a) {
                            var _b = __read(_a, 2), key = _b[0], item = _b[1];
                            return key;
                        });
                        return [4 /*yield*/, this.loadMultiple(keyPrefix, itemsToLoadKeys, action)];
                    case 4:
                        data = _b.sent();
                        Object.entries(data).forEach(function (_a, idx) {
                            var _b = __read(_a, 2), key = _b[0], item = _b[1];
                            return (internalResult_3[key] = item);
                        });
                        // Clean up locks
                        return [4 /*yield*/, Promise.all(missingItemKeys.map(function (key) {
                                return _this.lockCache.removeValue("LockCache-".concat(keyPrefix, "_").concat(key));
                            }))];
                    case 5:
                        // Clean up locks
                        _b.sent();
                        return [2 /*return*/, internalResult_3];
                    case 6:
                        error_6 = _b.sent();
                        // Clean up all possible locks on failure
                        (_a = this.logError) === null || _a === void 0 ? void 0 : _a.call(this, error_6);
                        return [4 /*yield*/, Promise.all(keysToLoad.map(function (key) {
                                return _this.lockCache.removeValue("LockCache-".concat(keyPrefix, "_").concat(key));
                            }))];
                    case 7:
                        _b.sent();
                        throw error_6;
                    case 8: return [2 /*return*/];
                }
            });
        }); };
        this.loadMultiple = function (keyPrefix, keys, action) { return __awaiter(_this, void 0, void 0, function () {
            var sharedPromiseCache_1, keysBeingAlreadyLoaded_1, keysToLoad_2, loadPromise_1, localPromiseCache, data, i, key, _a, promiseKeys, promise, result, idx, value, error_7;
            var _this = this;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(keys === null || keys === void 0 ? void 0 : keys.length)) {
                            return [2 /*return*/, {}];
                        }
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 7, , 8]);
                        sharedPromiseCache_1 = this.getPromiseCache();
                        keysBeingAlreadyLoaded_1 = new Set(Object.keys(sharedPromiseCache_1));
                        keysToLoad_2 = keys.filter(function (k) { return !keysBeingAlreadyLoaded_1.has("".concat(keyPrefix, "_").concat(k)); });
                        loadPromise_1 = keysToLoad_2.length > 0 ? action(keysToLoad_2) : Promise.resolve([]);
                        keysToLoad_2.forEach(function (k) { return (sharedPromiseCache_1["".concat(keyPrefix, "_").concat(k)] = [keysToLoad_2, loadPromise_1]); });
                        localPromiseCache = {};
                        Object.assign(localPromiseCache, sharedPromiseCache_1);
                        data = {};
                        i = 0;
                        _c.label = 2;
                    case 2:
                        if (!(i < keys.length)) return [3 /*break*/, 5];
                        key = keys[i];
                        _a = __read(localPromiseCache["".concat(keyPrefix, "_").concat(key)], 2), promiseKeys = _a[0], promise = _a[1];
                        return [4 /*yield*/, promise];
                    case 3:
                        result = _c.sent();
                        idx = promiseKeys.indexOf(key);
                        value = result[idx];
                        data[key] = value;
                        _c.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5: 
                    // Store data in indexDB cache
                    return [4 /*yield*/, Promise.all(Object.entries(data).map(function (_a) {
                            var _b = __read(_a, 2), key = _b[0], item = _b[1];
                            return _this.expirationCache.storeValue("".concat(keyPrefix, "_").concat(key), item);
                        }))];
                    case 6:
                        // Store data in indexDB cache
                        _c.sent();
                        // Delete promises from cache after timeout
                        if (keysToLoad_2.length > 0) {
                            window.setTimeout(function () {
                                keysToLoad_2.forEach(function (k) { return delete sharedPromiseCache_1["".concat(keyPrefix, "_").concat(k)]; });
                            }, PromiseCacheTTL);
                        }
                        return [2 /*return*/, data];
                    case 7:
                        error_7 = _c.sent();
                        // wipe the cache, in case it was corrupted
                        window[PromiseCacheName] = {};
                        (_b = this.logError) === null || _b === void 0 ? void 0 : _b.call(this, error_7);
                        throw error_7;
                    case 8: return [2 /*return*/];
                }
            });
        }); };
        this.getPromiseCache = function () {
            if (!window[PromiseCacheName]) {
                window[PromiseCacheName] = {};
            }
            return window[PromiseCacheName];
        };
        this.storageInitPromise = storage.init();
        this.expirationCache = new ExpirationCache(storage, function () { return _this.now(); }, expirationTimeout, logError);
        this.lockCache = new ExpirationCache(storage, function () { return _this.now(); }, lockTimeout, logError);
    }
    AsyncDistributedExpirationCacheWithRefresh.prototype.tryRetrieveValue = function (key, cache, lock, allowStaleData) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var cachedData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(lock.requestedAt + this.lockTimeout > this.now())) return [3 /*break*/, 3];
                        return [4 /*yield*/, cache.getValue(key, allowStaleData)];
                    case 1:
                        cachedData = _a.sent();
                        if (cachedData != null) {
                            resolve(cachedData);
                        }
                        return [4 /*yield*/, sleep(500)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 0];
                    case 3:
                        reject();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    AsyncDistributedExpirationCacheWithRefresh.prototype.tryRetrieveMultiple = function (keyPrefix, keysToLoad, allowStaleData, now) {
        if (now === void 0) { now = null; }
        return __awaiter(this, void 0, void 0, function () {
            var locks, internalResult, alreadyLoadingItems, error_8;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all(keysToLoad.map(function (key) { return _this.lockCache.getValue("LockCache-".concat(keyPrefix, "_").concat(key)); }))];
                    case 1:
                        locks = _a.sent();
                        internalResult = {};
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, Promise.all(keysToLoad.map(function (key, idx) {
                                if (locks[idx] && locks[idx].requestedAt !== now) {
                                    return _this.tryRetrieveValue("".concat(keyPrefix, "_").concat(key), _this.expirationCache, locks[idx], allowStaleData);
                                }
                                return Promise.resolve(null);
                            }))];
                    case 3:
                        alreadyLoadingItems = _a.sent();
                        alreadyLoadingItems.forEach(function (cachedData, idx) { return (internalResult[keysToLoad[idx]] = cachedData); });
                        return [2 /*return*/, internalResult];
                    case 4:
                        error_8 = _a.sent();
                        // If we timeout, lets instead load everything we need (this should be prevented by use of preload)
                        // It happens only if we need to load too much stuff at once
                        keysToLoad.forEach(function (key) { return (internalResult[key] = null); });
                        return [2 /*return*/, internalResult];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AsyncDistributedExpirationCacheWithRefresh.prototype.refreshData = function (key, lockCacheKey, action, allowStaleData) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var lockCacheItem, savedLock, promise, data, error_9, error_10;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 9, , 11]);
                        (_a = this.logInfo) === null || _a === void 0 ? void 0 : _a.call(this, "Refreshing ".concat(key));
                        lockCacheItem = { requestedAt: this.now() };
                        return [4 /*yield*/, this.lockCache.storeValue(lockCacheKey, lockCacheItem)];
                    case 1:
                        _d.sent();
                        return [4 /*yield*/, this.lockCache.getValue(lockCacheKey)];
                    case 2:
                        savedLock = _d.sent();
                        if (savedLock != null && savedLock.requestedAt !== lockCacheItem.requestedAt) {
                            return [2 /*return*/];
                        }
                        promise = action();
                        return [4 /*yield*/, promise];
                    case 3:
                        data = _d.sent();
                        _d.label = 4;
                    case 4:
                        _d.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this.expirationCache.storeValue(key, data)];
                    case 5:
                        _d.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        error_9 = _d.sent();
                        (_b = this.logError) === null || _b === void 0 ? void 0 : _b.call(this, error_9);
                        return [3 /*break*/, 7];
                    case 7: return [4 /*yield*/, this.lockCache.removeValue(lockCacheKey)];
                    case 8:
                        _d.sent();
                        return [3 /*break*/, 11];
                    case 9:
                        error_10 = _d.sent();
                        (_c = this.logError) === null || _c === void 0 ? void 0 : _c.call(this, error_10);
                        return [4 /*yield*/, this.lockCache.removeValue(lockCacheKey)];
                    case 10:
                        _d.sent();
                        return [3 /*break*/, 11];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    return AsyncDistributedExpirationCacheWithRefresh;
}());
export { AsyncDistributedExpirationCacheWithRefresh };
export function sleep(ms) {
    return new Promise(function (resolve) { return setTimeout(resolve, ms); });
}
