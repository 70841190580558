/// Get the origin of the innermost parent iframe or window that is not an about:blank iframe.
function getTopmostWindow() {
    var w = window;
    while (w && w.parent && !w.location.origin.startsWith("http")) {
        w = w.parent;
    }
    return w;
}
export function getXrm() {
    var w = getTopmostWindow();
    return w.Xrm;
}
export function getCdsClientUrl() {
    var xrm = getXrm();
    // If xrm is defined then get the client url from it since it will include the org if necessary
    if (xrm && xrm.Utility) {
        var globalContext = xrm.Utility.getGlobalContext();
        if (globalContext) {
            return globalContext.getClientUrl();
        }
    }
    // Fall back to the base path
    return getTopmostWindow().location.origin;
}
export function getCurrentAppUrl() {
    var xrm = getXrm();
    // If xrm is defined then get the client url from it since it will include the org if necessary
    if (xrm && xrm.Utility) {
        var globalContext = xrm.Utility.getGlobalContext();
        if (globalContext) {
            return globalContext.getCurrentAppUrl();
        }
    }
}
