var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { LifecycleDialog, LocalizationKeys, ShowPageHeaderNotificationEvent } from "../Common/Constants";
import { getDependencyEntityFetchXml } from "../LifecycleActions";
export function getDependencyFormNotification(entityName, entityId, result, notificationText) {
    return new CustomEvent(ShowPageHeaderNotificationEvent, {
        detail: {
            text: notificationText,
            buttonLabel: LocalizationKeys.LiveJourneyNotificationButtonText,
            action: function () {
                return window.Xrm.Navigation.openDialog(LifecycleDialog.DialogName, { width: 800, height: 320 }, {
                    dependent_entitylist: JSON.stringify(__spreadArray(__spreadArray([], __read(result.liveJourneys), false), __read(result.nonLiveJourneys), false)),
                    dialog_mode: LifecycleDialog.ReadOnlyMode,
                    entity_count: result.liveJourneys.length + result.nonLiveJourneys.length,
                    entity_fetchxml: JSON.stringify(getDependencyEntityFetchXml(entityName, entityId))
                });
            },
            localizeText: true
        }
    });
}
