var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Service } from "@cxp-shared/platform/Service";
import { TelemetryServiceName } from "@cxp-shared/services/Telemetry";
import { getWebResourceUrl } from "@cxp-shared/resource-loader/WebResourceUtil";
var PersonalizationLocalizationService = /** @class */ (function (_super) {
    __extends(PersonalizationLocalizationService, _super);
    function PersonalizationLocalizationService(langCode) {
        var _this = _super.call(this) || this;
        _this.langCode = langCode;
        _this.fetch = function (webResourceNameTemplate, langCode) { return __awaiter(_this, void 0, void 0, function () {
            var language, webResourceName, cacheData, response, data, parser, xmlDoc, jsonData, dataArray, i, data_1, error_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        language = langCode > 0 ? "".concat(langCode) : "".concat(this.langCode);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 8]);
                        webResourceName = webResourceNameTemplate.replace("{{lcid}}", language);
                        cacheData = (_a = window === null || window === void 0 ? void 0 : window.cxpPersonalizationLocCache) === null || _a === void 0 ? void 0 : _a[webResourceName];
                        if (cacheData && typeof cacheData === "object" && Object.keys(cacheData).length > 0) {
                            return [2 /*return*/, cacheData];
                        }
                        return [4 /*yield*/, fetch(getWebResourceUrl(webResourceName), {
                                method: "GET",
                                headers: {
                                    "Content-Type": "application/xml; charset=utf-8"
                                }
                            })];
                    case 2:
                        response = _b.sent();
                        if (!response.ok) {
                            throw new Error("HTTP error! status: ".concat(response.status));
                        }
                        return [4 /*yield*/, response.text()];
                    case 3:
                        data = _b.sent();
                        parser = new DOMParser();
                        xmlDoc = parser.parseFromString(data, "text/xml");
                        jsonData = {};
                        dataArray = xmlDoc.getElementsByTagName("data");
                        for (i = 0; i < dataArray.length; i++) {
                            data_1 = dataArray[i];
                            jsonData[data_1.getAttribute("name")] = data_1.getElementsByTagName("value")[0].textContent;
                        }
                        if (!window.cxpPersonalizationLocCache) {
                            window.cxpPersonalizationLocCache = {};
                        }
                        window.cxpPersonalizationLocCache[webResourceName] = jsonData;
                        this.telemetryService.logInfo("PersonalizationLocalizationService: Fetched ".concat(Object.keys(jsonData).length, " loc entries from ").concat(webResourceName));
                        return [2 /*return*/, jsonData];
                    case 4:
                        error_1 = _b.sent();
                        if (!(language !== "1033")) return [3 /*break*/, 6];
                        // Default to 1033 if the language is not supported
                        this.telemetryService.logInfo("PersonalizationLocalizationService: Failed to fetch loc entries for ".concat(webResourceNameTemplate, " and lang ").concat(language, ". Defaulting to 1033"));
                        return [4 /*yield*/, this.fetch(webResourceNameTemplate, 1033)];
                    case 5: return [2 /*return*/, _b.sent()];
                    case 6:
                        this.telemetryService.logError("PersonalizationLocalizationService: Failed to fetch loc entries for ".concat(webResourceNameTemplate, " and lang ").concat(language));
                        return [2 /*return*/, {}];
                    case 7: return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        }); };
        _this.getFromCache = function (webResourceNameTemplate, langCode) {
            var _a;
            var language = langCode > 0 ? "".concat(langCode) : "".concat(_this.langCode);
            var webResourceName = webResourceNameTemplate.replace("{{lcid}}", language);
            var cacheData = (_a = window === null || window === void 0 ? void 0 : window.cxpPersonalizationLocCache) === null || _a === void 0 ? void 0 : _a[webResourceName];
            if (cacheData && typeof cacheData === "object") {
                return cacheData;
            }
            else {
                return {};
            }
        };
        _this.preload = function (webresources, langCode) { return __awaiter(_this, void 0, void 0, function () {
            var promises;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promises = webresources.map(function (webResourceNameTemplate) { return _this.fetch(webResourceNameTemplate, langCode); });
                        return [4 /*yield*/, Promise.all(promises)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    PersonalizationLocalizationService.prototype.serviceStart = function (appContext) {
        _super.prototype.serviceStart.call(this, appContext);
        this.telemetryService = this.appContext.getService(TelemetryServiceName);
    };
    return PersonalizationLocalizationService;
}(Service));
export { PersonalizationLocalizationService };
