var isDevMode = process.env.NODE_ENV === "development";
var ConsoleTelemetryProvider = /** @class */ (function () {
    function ConsoleTelemetryProvider(component, printLogMessages) {
        if (printLogMessages === void 0) { printLogMessages = isDevMode; }
        this.printLogMessages = printLogMessages;
        var hasBrackets = (component === null || component === void 0 ? void 0 : component.startsWith("[")) && (component === null || component === void 0 ? void 0 : component.endsWith("]"));
        this.component = hasBrackets ? component : "[".concat(component, "]");
    }
    ConsoleTelemetryProvider.prototype.cloneForComponent = function (component) {
        var newComponent = this.joinComponents(this.component, component);
        return new ConsoleTelemetryProvider(newComponent);
    };
    ConsoleTelemetryProvider.prototype.logInfo = function (logMessage, parameters) {
        if (this.printLogMessages) {
            console.log("".concat(this.component, ": ").concat(logMessage), parameters || "");
        }
    };
    ConsoleTelemetryProvider.prototype.logError = function (logMessage, parameters) {
        console.error("".concat(this.component, ": ").concat(logMessage), parameters || "");
    };
    ConsoleTelemetryProvider.prototype.logFunctionalEvent = function (eventName, eventData) {
        if (this.printLogMessages) {
            console.log("".concat(this.component, ": FunctionalEvent: ").concat(eventName), eventData || "");
        }
    };
    ConsoleTelemetryProvider.prototype.joinComponents = function (original, component) {
        if (original && component && original.toLocaleLowerCase().indexOf("[".concat(component.toLocaleLowerCase(), "]")) < 0) {
            return "".concat(original, " [").concat(component, "]");
        }
        return original || "[".concat(component, "]");
    };
    return ConsoleTelemetryProvider;
}());
export { ConsoleTelemetryProvider };
