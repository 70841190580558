var Logger = /** @class */ (function () {
    function Logger() {
    }
    Logger.prototype.log = function (message) {
        var _a;
        if ((_a = window === null || window === void 0 ? void 0 : window.console) === null || _a === void 0 ? void 0 : _a.log) {
            window.console.log(message);
        }
    };
    Logger.prototype.warn = function (message) {
        var _a;
        if ((_a = window === null || window === void 0 ? void 0 : window.console) === null || _a === void 0 ? void 0 : _a.warn) {
            window.console.warn(message);
        }
    };
    Logger.prototype.error = function (message) {
        var _a;
        if ((_a = window === null || window === void 0 ? void 0 : window.console) === null || _a === void 0 ? void 0 : _a.error) {
            window.console.error(message);
        }
    };
    return Logger;
}());
export { Logger };
