var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PlaceholderUtils } from "../../Utilities/PlaceholderUtils";
export var extractEventTargetAudience = function (eventBindings, metadataStoreService, isDoubleOptIn) { return __awaiter(void 0, void 0, void 0, function () {
    var eventMetadata, _loop_1, i, state_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, loadEvents(metadataStoreService, isDoubleOptIn)];
            case 1:
                eventMetadata = _a.sent();
                _loop_1 = function (i) {
                    var sourceLogicalName = PlaceholderUtils.extractSourceType(eventBindings[i]);
                    var attribute = eventMetadata === null || eventMetadata === void 0 ? void 0 : eventMetadata.find(function (m) { return m.sourceLogicalName === sourceLogicalName; });
                    if ((attribute === null || attribute === void 0 ? void 0 : attribute.targetAudience) != null) {
                        var eventTargets = new Set(attribute.targetAudience.map(function (x) { return x.logicalName; }));
                        return { value: Array.from(eventTargets) };
                    }
                    else {
                        return { value: [sourceLogicalName] };
                    }
                };
                for (i = 0; i < eventBindings.length; i++) {
                    state_1 = _loop_1(i);
                    if (typeof state_1 === "object")
                        return [2 /*return*/, state_1.value];
                }
                return [2 /*return*/, []];
        }
    });
}); };
export var extractTargetEventSourceLogicalName = function (eventBindings) {
    if (eventBindings.length === 0) {
        return null;
    }
    return PlaceholderUtils.extractSourceType(eventBindings[0]);
};
export var isEventBindingTargetAudience = function (sourceLogicalName, targetAudience, metadataStoreService, exactAudienceMatchOnly) { return __awaiter(void 0, void 0, void 0, function () {
    var eventMetadata, eventTargets_1;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0: return [4 /*yield*/, loadEventByName(sourceLogicalName, metadataStoreService)];
            case 1:
                eventMetadata = _d.sent();
                if ((eventMetadata === null || eventMetadata === void 0 ? void 0 : eventMetadata.length) > 0) {
                    eventTargets_1 = new Set(eventMetadata[0].targetAudience.map(function (x) { return x.logicalName; }));
                    if (exactAudienceMatchOnly || (targetAudience === null || targetAudience === void 0 ? void 0 : targetAudience.targetEvent)) {
                        if (exactAudienceMatchOnly && !((_a = targetAudience === null || targetAudience === void 0 ? void 0 : targetAudience.targetEntities) === null || _a === void 0 ? void 0 : _a.length)) {
                            return [2 /*return*/, (targetAudience === null || targetAudience === void 0 ? void 0 : targetAudience.targetEvent) === sourceLogicalName];
                        }
                        else {
                            return [2 /*return*/, (targetAudience === null || targetAudience === void 0 ? void 0 : targetAudience.targetEvent) === sourceLogicalName && ((_b = targetAudience === null || targetAudience === void 0 ? void 0 : targetAudience.targetEntities) === null || _b === void 0 ? void 0 : _b.some(function (te) { return eventTargets_1.has(te); }))];
                        }
                    }
                    else {
                        return [2 /*return*/, (_c = targetAudience === null || targetAudience === void 0 ? void 0 : targetAudience.targetEntities) === null || _c === void 0 ? void 0 : _c.some(function (te) { return eventTargets_1.has(te); })];
                    }
                }
                return [2 /*return*/, false];
        }
    });
}); };
var loadEvents = function (metadataStoreService, isDoubleOptIn) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!metadataStoreService) return [3 /*break*/, 2];
                return [4 /*yield*/, metadataStoreService.getAllCjoEventsNames(false, null, true, isDoubleOptIn)];
            case 1: return [2 /*return*/, (_a.sent()).value];
            case 2: return [2 /*return*/, []];
        }
    });
}); };
var loadEventByName = function (logicalName, metadataStoreService) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!metadataStoreService) return [3 /*break*/, 2];
                return [4 /*yield*/, metadataStoreService.getCjoEventsByName(logicalName, true)];
            case 1: return [2 /*return*/, (_a.sent()).value];
            case 2: return [2 /*return*/, []];
        }
    });
}); };
