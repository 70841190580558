var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Service } from "@cxp-shared/platform/Service";
import { CommonDataServiceName } from "@cxp-shared/services/CDS";
import { TelemetryServiceName } from "@cxp-shared/services/Telemetry";
import { normalizeGuid } from "@cxp-shared/string-utilities/normalize-guid";
import { TimezoneServiceName } from "../Timezone";
var UserSettingsEntityName = "usersettingscollection";
var mkt_DefaultFieldName = "msdyncrm_default";
var mkt_TimeZoneAttributeName = "msdyncrm_defaulttimezone";
var mkt_DefaultSettingsIdFieldName = "msdyncrm_defaultmarketingsettingid";
export var mkt_DefaultSettingsEntityName = "msdyncrm_defaultmarketingsetting";
export var mkt_DefaultSettingsEntityPluralName = "msdyncrm_defaultmarketingsettings";
var CJO_ONLY_APP_SETTING = "msdynmkt_CJOOnlyEnabled";
var GMT_TIMEZONECODE = 92;
var UserSettingsService = /** @class */ (function (_super) {
    __extends(UserSettingsService, _super);
    function UserSettingsService(userSettings) {
        var _this = _super.call(this) || this;
        _this.userSettings = userSettings;
        return _this;
    }
    UserSettingsService.prototype.serviceStart = function (appContext) {
        this.cds = appContext.getService(CommonDataServiceName);
    };
    UserSettingsService.prototype.getUserSettings = function () {
        return this.userSettings;
    };
    UserSettingsService.prototype.getUserTimezoneOffset = function (timestamp) {
        return this.userSettings.getTimeZoneOffsetMinutes(timestamp);
    };
    UserSettingsService.prototype.getUserSettingsEntity = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, e_1, telemetryService;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        if (!this.userSettingsEntityPromise) {
                            this.userSettingsEntityPromise = this.cds.getRecord(UserSettingsEntityName, this.getUserId());
                        }
                        _a = this;
                        return [4 /*yield*/, this.userSettingsEntityPromise];
                    case 1:
                        _a.userSettingsEntity = _b.sent();
                        return [2 /*return*/, this.userSettingsEntity];
                    case 2:
                        e_1 = _b.sent();
                        telemetryService = this.appContext.getService(TelemetryServiceName);
                        telemetryService.logError("UserSettings.Service: Failed to fetch user timezone: '".concat(e_1, "'"));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserSettingsService.prototype.getUserTimezoneName = function () {
        return __awaiter(this, void 0, void 0, function () {
            var timezoneCode, timeZoneService, timezoneDef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUserTimezoneCode()];
                    case 1:
                        timezoneCode = _a.sent();
                        timeZoneService = this.appContext.getService(TimezoneServiceName);
                        return [4 /*yield*/, timeZoneService.fetchTimezoneByCode(timezoneCode)];
                    case 2:
                        timezoneDef = _a.sent();
                        return [2 /*return*/, timezoneDef === null || timezoneDef === void 0 ? void 0 : timezoneDef.userinterfacename];
                }
            });
        });
    };
    UserSettingsService.prototype.getUserTimezoneCode = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userSettingsEntity;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUserSettingsEntity()];
                    case 1:
                        userSettingsEntity = _a.sent();
                        return [2 /*return*/, userSettingsEntity === null || userSettingsEntity === void 0 ? void 0 : userSettingsEntity.timezonecode];
                }
            });
        });
    };
    UserSettingsService.prototype.getUserId = function () {
        // Removes surrounding {} in userId
        return normalizeGuid(this.userSettings.userId);
    };
    UserSettingsService.prototype.isRtl = function () {
        return this.userSettings.isRTL;
    };
    UserSettingsService.prototype.getDefaultMarketingTimezoneInfo = function (useMarketingDefaults) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!this.marketingTimezoneInfoPromise) {
                    this.marketingTimezoneInfoPromise = this.getDefaultMarketingTimezoneInfoImpl(useMarketingDefaults);
                }
                return [2 /*return*/, this.marketingTimezoneInfoPromise];
            });
        });
    };
    UserSettingsService.prototype.getDefaultMarketingTimezoneInfoImpl = function (useMarketingDefaults) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var globalContext, journeySettings, timeZoneCode, defaultMktSettings;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!useMarketingDefaults) return [3 /*break*/, 7];
                        globalContext = (_b = (_a = window === null || window === void 0 ? void 0 : window.Xrm) === null || _a === void 0 ? void 0 : _a.Utility) === null || _b === void 0 ? void 0 : _b.getGlobalContext();
                        if (!(globalContext === null || globalContext === void 0 ? void 0 : globalContext.getCurrentAppSetting(CJO_ONLY_APP_SETTING))) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.getJourneySettings()];
                    case 1:
                        journeySettings = _d.sent();
                        timeZoneCode = void 0;
                        if (!((journeySettings === null || journeySettings === void 0 ? void 0 : journeySettings.msdynmkt_timezonetype) === 534120000 /* TimeZoneType.SpecificTimeZone */)) return [3 /*break*/, 2];
                        timeZoneCode = journeySettings === null || journeySettings === void 0 ? void 0 : journeySettings.msdynmkt_defaulttimezone;
                        return [3 /*break*/, 4];
                    case 2:
                        if (!((journeySettings === null || journeySettings === void 0 ? void 0 : journeySettings.msdynmkt_timezonetype) === 534120001 /* TimeZoneType.UserTimeZone */)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getUserTimezoneCode()];
                    case 3:
                        timeZoneCode = _d.sent();
                        _d.label = 4;
                    case 4: return [2 /*return*/, {
                            timeZoneCode: timeZoneCode !== null && timeZoneCode !== void 0 ? timeZoneCode : GMT_TIMEZONECODE,
                        }];
                    case 5: return [4 /*yield*/, this.getDefaultMarketingSettings()];
                    case 6:
                        defaultMktSettings = _d.sent();
                        if ((defaultMktSettings === null || defaultMktSettings === void 0 ? void 0 : defaultMktSettings.msdyncrm_defaulttimezone) != null) {
                            return [2 /*return*/, {
                                    timeZoneCode: defaultMktSettings.msdyncrm_defaulttimezone,
                                    defaultSettingsEntityId: defaultMktSettings.msdyncrm_defaultmarketingsettingid
                                }];
                        }
                        _d.label = 7;
                    case 7:
                        _c = {};
                        return [4 /*yield*/, this.getUserTimezoneCode()];
                    case 8: return [2 /*return*/, (_c.timeZoneCode = _d.sent(),
                            _c)];
                }
            });
        });
    };
    UserSettingsService.prototype.getDefaultMarketingSettings = function () {
        return __awaiter(this, void 0, void 0, function () {
            var defaultMktSettings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cds.getRecords(mkt_DefaultSettingsEntityPluralName, "$filter=".concat(mkt_DefaultFieldName, " eq ").concat(true, "&$top=1"))];
                    case 1:
                        defaultMktSettings = _a.sent();
                        return [2 /*return*/, defaultMktSettings === null || defaultMktSettings === void 0 ? void 0 : defaultMktSettings[0]];
                }
            });
        });
    };
    UserSettingsService.prototype.getJourneySettings = function () {
        return __awaiter(this, void 0, void 0, function () {
            var journeySettings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cds.getRecords("msdynmkt_journeysettings", "$orderby=createdon asc&$top=1")];
                    case 1:
                        journeySettings = _a.sent();
                        return [2 /*return*/, journeySettings === null || journeySettings === void 0 ? void 0 : journeySettings[0]];
                }
            });
        });
    };
    return UserSettingsService;
}(Service));
export { UserSettingsService };
