var requestPerfixLength = 200;
var singleQueryItemLength = 20; //&sourceLogicalNames=
var maxUrlLength = 8192;
export var batchDataverseSourceLogicalNames = function (sourceLogicalNames) {
    var retValue = [];
    var batchIndex = 0;
    var currentLength = requestPerfixLength;
    retValue[batchIndex] = [];
    sourceLogicalNames.forEach(function (name) {
        if (currentLength + singleQueryItemLength + name.length >= maxUrlLength) {
            currentLength = requestPerfixLength;
            retValue[++batchIndex] = [name];
        }
        else {
            retValue[batchIndex].push(name);
        }
        currentLength += singleQueryItemLength + name.length;
    });
    return retValue;
};
export var buildDisplayNameUrl = function (url, sourceLogicalNames) {
    return "".concat(url, "/api/data/v9.2/EntityDefinitions?$select=LogicalName,DisplayName,OwnershipType&$filter=Microsoft.Dynamics.CRM.In(PropertyName=%27LogicalName%27,PropertyValues=[").concat(sourceLogicalNames.map(function (x) { return "'".concat(x, "'"); }), "])");
};
export var buildUrlForAllEntityMetadata = function (url) {
    return "".concat(url, "/api/data/v9.2/EntityDefinitions?$select=LogicalName,DisplayName,OwnershipType&$filter=IsIntersect%20eq%20false%20and%20IsPrivate%20eq%20false%20and%20OwnershipType%20ne%20%27None%27");
};
export var extractDisplayName = function (logicalName, displayName, userLanguageId) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    userLanguageId = userLanguageId !== null && userLanguageId !== void 0 ? userLanguageId : (_f = (_e = (_d = (_c = (_b = (_a = global === null || global === void 0 ? void 0 : global.window) === null || _a === void 0 ? void 0 : _a.Xrm) === null || _b === void 0 ? void 0 : _b.Utility) === null || _c === void 0 ? void 0 : _c.getGlobalContext) === null || _d === void 0 ? void 0 : _d.call(_c)) === null || _e === void 0 ? void 0 : _e.userSettings) === null || _f === void 0 ? void 0 : _f.languageId;
    var localizedLabel = (_g = displayName === null || displayName === void 0 ? void 0 : displayName.LocalizedLabels) === null || _g === void 0 ? void 0 : _g.find(function (x) { return (x === null || x === void 0 ? void 0 : x.LanguageCode) === userLanguageId; });
    if (localizedLabel === null || localizedLabel === void 0 ? void 0 : localizedLabel.Label) {
        return localizedLabel.Label;
    }
    if (((_h = displayName === null || displayName === void 0 ? void 0 : displayName.UserLocalizedLabel) === null || _h === void 0 ? void 0 : _h.LanguageCode) === userLanguageId && ((_j = displayName === null || displayName === void 0 ? void 0 : displayName.UserLocalizedLabel) === null || _j === void 0 ? void 0 : _j.Label)) {
        return displayName.UserLocalizedLabel.Label;
    }
    return logicalName;
};
export var defaultDatasetConfiguration = [
    "account",
    "msevtmgt_event",
    "msevtmgt_eventadministration",
    "msevtmgt_eventregistration",
    "msevtmgt_attendeepass",
    "msevtmgt_checkin",
    "msevtmgt_session",
    "msevtmgt_sessionregistration",
    "msevtmgt_building",
    "msevtmgt_room"
];
