var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FetchClient } from "@cxp-shared/fetch-client";
import { retryHandler } from "./RetryUtils";
import { FormRenderingStatus, FormSubmitStatus } from "./Constants/FormStatus";
var registrationFormRetryCount = 1;
var FormServiceClient = /** @class */ (function () {
    function FormServiceClient(logger) {
        this.logger = logger;
        this.fetchClient = new FetchClient();
    }
    FormServiceClient.prototype.getFormByFormUrl = function (formUrl, formLoadResponseType) {
        if (formLoadResponseType === void 0) { formLoadResponseType = "text/plain"; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getResponse(formUrl, formLoadResponseType)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    FormServiceClient.prototype.sendFormVisit = function (formVisitUrl, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, retryHandler(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.fetchClient.fetchPost(formVisitUrl, JSON.stringify(payload))];
                                case 1: return [2 /*return*/, _a.sent()];
                            }
                        }); }); })];
                    case 1:
                        response = _a.sent();
                        if (response.status !== 200) {
                            this.logger.error("Response status code is not successful ".concat(response.status));
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    FormServiceClient.prototype.submitForm = function (formSubmitUrl, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var retryCount, method, response;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (formSubmitUrl.includes("/eventmanagement/form/") && formSubmitUrl.includes("/registration")) {
                            retryCount = registrationFormRetryCount;
                        }
                        method = function () { return __awaiter(_this, void 0, void 0, function () {
                            var response, parseResponse, e_1, formSubmitResponse;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.fetchClient.fetchPost(formSubmitUrl, payload)];
                                    case 1:
                                        response = _a.sent();
                                        parseResponse = function (response) { return __awaiter(_this, void 0, void 0, function () { var _a, _b; return __generator(this, function (_c) {
                                            switch (_c.label) {
                                                case 0:
                                                    _b = (_a = JSON).parse;
                                                    return [4 /*yield*/, response.text()];
                                                case 1: return [2 /*return*/, _b.apply(_a, [_c.sent()])];
                                            }
                                        }); }); };
                                        _a.label = 2;
                                    case 2:
                                        _a.trys.push([2, 3, , 6]);
                                        this.ensureSuccessfulResponse(response);
                                        return [3 /*break*/, 6];
                                    case 3:
                                        e_1 = _a.sent();
                                        if (!(response.status === 400)) return [3 /*break*/, 5];
                                        return [4 /*yield*/, parseResponse(response)];
                                    case 4:
                                        formSubmitResponse = _a.sent();
                                        if (formSubmitResponse.submissionStatus === FormSubmitStatus.ValidationError ||
                                            formSubmitResponse.submissionStatus === FormSubmitStatus.EventCapacityIsFull ||
                                            formSubmitResponse.submissionStatus === FormSubmitStatus.EventNotLive) {
                                            // do not retry for validation error
                                            return [2 /*return*/, formSubmitResponse];
                                        }
                                        _a.label = 5;
                                    case 5: throw e_1;
                                    case 6: return [2 /*return*/, parseResponse(response)];
                                }
                            });
                        }); };
                        return [4 /*yield*/, retryHandler(method, retryCount)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    FormServiceClient.prototype.getDomainConfigurationUrl = function (formApiBaseUrl, formId, domain) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        url = "".concat(formApiBaseUrl, "/forms/").concat(formId, "/domainconfiguration?domain=") + encodeURIComponent(domain);
                        return [4 /*yield*/, retryHandler(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.fetchClient.fetchGet(url)];
                                    case 1: return [2 /*return*/, _a.sent()];
                                }
                            }); }); })];
                    case 1:
                        response = _c.sent();
                        if (response.status !== 200) {
                            this.logger.error("Response status code is not successful ".concat(response.status));
                            return [2 /*return*/, null];
                        }
                        _b = (_a = JSON).parse;
                        return [4 /*yield*/, response.text()];
                    case 2: return [2 /*return*/, _b.apply(_a, [_c.sent()])];
                }
            });
        });
    };
    FormServiceClient.prototype.getResponse = function (url, formLoadResponseType) {
        if (formLoadResponseType === void 0) { formLoadResponseType = "text/plain"; }
        return __awaiter(this, void 0, void 0, function () {
            var headers, response;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        headers = { Accept: formLoadResponseType };
                        return [4 /*yield*/, retryHandler(function () { return __awaiter(_this, void 0, void 0, function () {
                                var response, res, _a;
                                var _b;
                                return __generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0: return [4 /*yield*/, fetch(url, {
                                                method: "GET",
                                                headers: headers
                                            })];
                                        case 1:
                                            response = _c.sent();
                                            this.ensureSuccessfulResponse(response);
                                            if (!(formLoadResponseType === "application/json")) return [3 /*break*/, 3];
                                            return [4 /*yield*/, response.json()];
                                        case 2:
                                            _a = (_c.sent());
                                            return [3 /*break*/, 5];
                                        case 3:
                                            _b = { formRenderingStatus: FormRenderingStatus.Success };
                                            return [4 /*yield*/, response.text()];
                                        case 4:
                                            _a = (_b.formHtml = _c.sent(), _b);
                                            _c.label = 5;
                                        case 5:
                                            res = _a;
                                            return [2 /*return*/, res];
                                    }
                                });
                            }); })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    FormServiceClient.prototype.ensureSuccessfulResponse = function (response) {
        if (response.status < 200 || response.status >= 300) {
            var msg = "Response status code is not successful ".concat(response.status);
            this.logger.error(msg);
            throw msg;
        }
    };
    return FormServiceClient;
}());
export { FormServiceClient };
