var RegexUtils = /** @class */ (function () {
    function RegexUtils() {
    }
    RegexUtils.regexEqual = function (x, y) {
        return (x instanceof RegExp &&
            y instanceof RegExp &&
            x.source === y.source &&
            x.global === y.global &&
            x.ignoreCase === y.ignoreCase &&
            x.multiline === y.multiline);
    };
    RegexUtils.isRegExp = function (s) {
        // Try parsing the string
        // In case of error string is not RegExp
        try {
            new RegExp(s);
            return true;
        }
        catch (error) {
            return false;
        }
    };
    return RegexUtils;
}());
export { RegexUtils };
