export var DateTimeBehaviorCRM;
(function (DateTimeBehaviorCRM) {
    DateTimeBehaviorCRM[DateTimeBehaviorCRM["TimeZoneIndependent"] = 534120000] = "TimeZoneIndependent";
    DateTimeBehaviorCRM[DateTimeBehaviorCRM["DateOnly"] = 534120001] = "DateOnly";
    DateTimeBehaviorCRM[DateTimeBehaviorCRM["UserLocal"] = 534120002] = "UserLocal";
})(DateTimeBehaviorCRM || (DateTimeBehaviorCRM = {}));
export var StringFormatTypeCRM;
(function (StringFormatTypeCRM) {
    StringFormatTypeCRM[StringFormatTypeCRM["Email"] = 534120000] = "Email";
    StringFormatTypeCRM[StringFormatTypeCRM["Text"] = 534120001] = "Text";
    StringFormatTypeCRM[StringFormatTypeCRM["TextArea"] = 534120002] = "TextArea";
    StringFormatTypeCRM[StringFormatTypeCRM["Url"] = 534120003] = "Url";
    StringFormatTypeCRM[StringFormatTypeCRM["TickerSymbol"] = 534120004] = "TickerSymbol";
    StringFormatTypeCRM[StringFormatTypeCRM["PhoneticGuide"] = 534120005] = "PhoneticGuide";
    StringFormatTypeCRM[StringFormatTypeCRM["VersionNumber"] = 534120006] = "VersionNumber";
    StringFormatTypeCRM[StringFormatTypeCRM["Phone"] = 534120007] = "Phone";
    StringFormatTypeCRM[StringFormatTypeCRM["Json"] = 534120008] = "Json";
    StringFormatTypeCRM[StringFormatTypeCRM["RichText"] = 534120009] = "RichText";
})(StringFormatTypeCRM || (StringFormatTypeCRM = {}));
export var DataTypeCRM;
(function (DataTypeCRM) {
    DataTypeCRM[DataTypeCRM["BigInt"] = 534120000] = "BigInt";
    DataTypeCRM[DataTypeCRM["Money"] = 534120001] = "Money";
    DataTypeCRM[DataTypeCRM["DateTime"] = 534120002] = "DateTime";
    DataTypeCRM[DataTypeCRM["Decimal"] = 534120003] = "Decimal";
    DataTypeCRM[DataTypeCRM["Integer"] = 534120004] = "Integer";
    DataTypeCRM[DataTypeCRM["String"] = 534120005] = "String";
    DataTypeCRM[DataTypeCRM["File"] = 534120006] = "File";
    DataTypeCRM[DataTypeCRM["Double"] = 534120007] = "Double";
    DataTypeCRM[DataTypeCRM["Image"] = 534120008] = "Image";
    DataTypeCRM[DataTypeCRM["Lookup"] = 534120009] = "Lookup";
    DataTypeCRM[DataTypeCRM["MultiSelectPicklist"] = 534120010] = "MultiSelectPicklist";
    DataTypeCRM[DataTypeCRM["Memo"] = 534120011] = "Memo";
    DataTypeCRM[DataTypeCRM["Picklist"] = 534120012] = "Picklist";
    DataTypeCRM[DataTypeCRM["Status"] = 534120013] = "Status";
    DataTypeCRM[DataTypeCRM["State"] = 534120014] = "State";
    DataTypeCRM[DataTypeCRM["Boolean"] = 534120015] = "Boolean";
    DataTypeCRM[DataTypeCRM["Uniqueidentifier"] = 534120016] = "Uniqueidentifier";
    DataTypeCRM[DataTypeCRM["OneToManyRelationship"] = 534120017] = "OneToManyRelationship";
    DataTypeCRM[DataTypeCRM["ManyToManyRelationship"] = 534120018] = "ManyToManyRelationship";
    DataTypeCRM[DataTypeCRM["EntityValue"] = 534120019] = "EntityValue";
    DataTypeCRM[DataTypeCRM["EntityCollection"] = 534120020] = "EntityCollection";
    DataTypeCRM[DataTypeCRM["Other"] = 534120021] = "Other";
    DataTypeCRM[DataTypeCRM["ManyToOneRelationship"] = 534120022] = "ManyToOneRelationship";
})(DataTypeCRM || (DataTypeCRM = {}));
export var SourceVirtualTableTypeCRM;
(function (SourceVirtualTableTypeCRM) {
    SourceVirtualTableTypeCRM[SourceVirtualTableTypeCRM["None"] = 534120000] = "None";
    SourceVirtualTableTypeCRM[SourceVirtualTableTypeCRM["Elastic"] = 534120001] = "Elastic";
    SourceVirtualTableTypeCRM[SourceVirtualTableTypeCRM["Other"] = 534120002] = "Other";
})(SourceVirtualTableTypeCRM || (SourceVirtualTableTypeCRM = {}));
export var EntityDefinitions = "EntityDefinitions";
