var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Service } from "@cxp-shared/platform/Service";
import { generateGuid } from "@cxp-shared/string-utilities/generate-guid";
import { normalizeGuid } from "@cxp-shared/string-utilities/normalize-guid";
import { PerformanceServiceName } from "../Performance";
var ODataPathPrefix = "/api/data/v9.0/";
var EntityDefinitions = "EntityDefinitions";
var RelationshipDefinitionsOneToMany = "RelationshipDefinitions/Microsoft.Dynamics.CRM.OneToManyRelationshipMetadata";
var ClientRequestIdHeader = "x-ms-client-request-id";
var ServiceRequestIdHeader = "x-ms-service-request-id";
var Max429RetryCount = 3;
var CommonDataService = /** @class */ (function (_super) {
    __extends(CommonDataService, _super);
    function CommonDataService(cdsBaseUrl) {
        var _this = _super.call(this) || this;
        _this.cdsBaseUrl = cdsBaseUrl;
        /**
         * Get the cds base url.
         */
        _this.getBaseUrl = function () { return _this.cdsBaseUrl; };
        return _this;
    }
    /**
     * Get a list of records from CDS.
     * @param entityPluralName The entity plural name (e.g. "msdynmkt_journeys").
     * @param query The query to execute (e.g. "?$select=msdynmkt_name").
     * @param options The options passed in the fetch request (e.g. {headers: {"prefer": `odata.include-annotations="*"`}}).
     */
    CommonDataService.prototype.getRecords = function (entityPluralName, query, options) {
        if (query === void 0) { query = ""; }
        return __awaiter(this, void 0, void 0, function () {
            var perfEvent, cdsDataResult, cdsData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        perfEvent = this.startScenario("CDS_API: Get Records - ".concat(entityPluralName), {
                            entityOrPlugin: entityPluralName
                        });
                        return [4 /*yield*/, this.fetch(perfEvent, this.buildUrlForEntityList(entityPluralName, query), options)];
                    case 1:
                        cdsDataResult = _a.sent();
                        return [4 /*yield*/, (cdsDataResult === null || cdsDataResult === void 0 ? void 0 : cdsDataResult.json())];
                    case 2:
                        cdsData = (_a.sent());
                        if (cdsData && cdsData.value) {
                            return [2 /*return*/, cdsData.value];
                        }
                        return [2 /*return*/, null];
                }
            });
        });
    };
    /**
     * Gets the count of records matching the specified filter
     * @param entityPluralName Name of the entity to get the count of
     * @param columnName Name of a column (required to run the query, preferably the primary key)
     * @param filter A filter to apply
     */
    CommonDataService.prototype.getRecordsCount = function (entityPluralName, columnName, filter) {
        if (filter === void 0) { filter = ""; }
        return __awaiter(this, void 0, void 0, function () {
            var perfEvent, cdsDataResult, cdsData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        perfEvent = this.startScenario("CDS_API: Get Records Count - ".concat(entityPluralName), {
                            entityOrPlugin: entityPluralName,
                            columnName: columnName
                        });
                        return [4 /*yield*/, this.fetch(perfEvent, this.buildUrlForEntityCount(entityPluralName, columnName, filter))];
                    case 1:
                        cdsDataResult = _a.sent();
                        return [4 /*yield*/, (cdsDataResult === null || cdsDataResult === void 0 ? void 0 : cdsDataResult.json())];
                    case 2:
                        cdsData = (_a.sent());
                        if (cdsData && cdsData["@odata.count"]) {
                            return [2 /*return*/, cdsData["@odata.count"]];
                        }
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    /**
     * Updates multiple entities in one transaction in CDS.
     * @param entities The entities info.
     */
    CommonDataService.prototype.updateRecordsWithBatchQuery = function (entities) {
        return __awaiter(this, void 0, void 0, function () {
            var batchName, changesetName, dataString, batchUrl, options, perfEvent;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        batchName = "batch_".concat(generateGuid());
                        changesetName = "changeset_".concat(generateGuid());
                        dataString = this.getChangesetBatchDataString(batchName, changesetName, entities, "PATCH");
                        batchUrl = this.buildBatchUrl();
                        options = {
                            method: "POST",
                            headers: this.getBatchHeaders(batchName),
                            body: dataString
                        };
                        perfEvent = this.startScenario("CDS_API: Update Records With Batch Query", {
                            entities: entities.map(function (entity) { return entity.pluralName; }).join(",")
                        });
                        return [4 /*yield*/, this.fetch(perfEvent, batchUrl, options)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Get a list of records from CDS using the 'batch' api to avoid
     * url length limitations
     * @param entityPluralName The entity plural name (e.g. "msdynmkt_journeys").
     * @param query The query to execute (e.g. "?$select=msdynmkt_name").
     */
    CommonDataService.prototype.getRecordsWithBatchQuery = function (entityPluralName, query) {
        if (query === void 0) { query = ""; }
        return __awaiter(this, void 0, void 0, function () {
            var perfEvent, batchName, fetchUrl, dataString, batchUrl, options, cdsDataResult, cdsData, startIndex, endIndex, jsonText, json, message;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        perfEvent = this.startScenario("CDS_API: Get Records With Batch Query - ".concat(entityPluralName), {
                            entityOrPlugin: entityPluralName
                        });
                        batchName = "batch_".concat(generateGuid());
                        fetchUrl = this.buildUrlForEntityList(entityPluralName, query);
                        dataString = "--".concat(batchName, "\nContent-Type: application/http\nContent-Transfer-Encoding: binary\n\nGET ").concat(fetchUrl, " HTTP/1.1\nContent-Type: application/json\nOData-Version: 4.0\nOData-MaxVersion: 4.0\n\n--").concat(batchName);
                        batchUrl = this.buildBatchUrl();
                        options = {
                            method: "POST",
                            headers: this.getBatchHeaders(batchName),
                            body: dataString
                        };
                        return [4 /*yield*/, this.fetch(perfEvent, batchUrl, options)];
                    case 1:
                        cdsDataResult = _a.sent();
                        return [4 /*yield*/, (cdsDataResult === null || cdsDataResult === void 0 ? void 0 : cdsDataResult.text())];
                    case 2:
                        cdsData = _a.sent();
                        startIndex = cdsData === null || cdsData === void 0 ? void 0 : cdsData.indexOf("{");
                        if (startIndex && startIndex >= 0) {
                            endIndex = cdsData === null || cdsData === void 0 ? void 0 : cdsData.lastIndexOf("}");
                            if (endIndex && endIndex >= 0) {
                                jsonText = cdsData.substring(startIndex, endIndex + 1);
                                try {
                                    json = JSON.parse(jsonText);
                                    return [2 /*return*/, json.value];
                                }
                                catch (e) {
                                    message = "CommonDataService: Error during ".concat(perfEvent.scenarioName);
                                    perfEvent.throw(message, "", {
                                        responseStatus: cdsDataResult === null || cdsDataResult === void 0 ? void 0 : cdsDataResult.status,
                                        errorMessage: (e === null || e === void 0 ? void 0 : e.message) || "Failed to parse json from response"
                                    });
                                    throw e;
                                }
                            }
                        }
                        return [2 /*return*/, null];
                }
            });
        });
    };
    /**
     * Get a single record from CDS.
     * @param entityPluralName The entity plural name (e.g. "msdynmkt_journeys").
     * @param recordId The entity record id.
     * @param attributes (optional) set of attributes to retrieve.  If not specified retrieves all attributes of the entity.
     */
    CommonDataService.prototype.getRecord = function (entityPluralName, recordId, attributes) {
        return __awaiter(this, void 0, void 0, function () {
            var perfEvent, cdsDataResult, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        perfEvent = this.startScenario("CDS_API: Get Record - ".concat(entityPluralName), {
                            entityOrPlugin: entityPluralName,
                            entityId: recordId
                        });
                        return [4 /*yield*/, this.fetch(perfEvent, this.buildUrlForEntity(entityPluralName, recordId, attributes), undefined, true)];
                    case 1:
                        cdsDataResult = _b.sent();
                        if (!(cdsDataResult === null || cdsDataResult === void 0 ? void 0 : cdsDataResult.ok)) return [3 /*break*/, 3];
                        return [4 /*yield*/, (cdsDataResult === null || cdsDataResult === void 0 ? void 0 : cdsDataResult.json())];
                    case 2:
                        _a = (_b.sent());
                        return [3 /*break*/, 4];
                    case 3:
                        _a = null;
                        _b.label = 4;
                    case 4: return [2 /*return*/, _a];
                }
            });
        });
    };
    /**
     * Returns the entity metadata for the specified entity.
     * @param entityName The logical name of the entity.
     * @param attributes The attributes to get metadata for.
     * @see https://docs.microsoft.com/en-us/powerapps/developer/model-driven-apps/clientapi/reference/xrm-utility/getentitymetadata
     */
    CommonDataService.prototype.getEntityMetadata = function (entityName, attributes) {
        return __awaiter(this, void 0, void 0, function () {
            var perfEvent, metadata, e_1, exceptionString, message;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        perfEvent = this.startScenario("CDS_API: Get Entity Metadata - ".concat(entityName), {
                            entityOrPlugin: entityName
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, window.Xrm.Utility.getEntityMetadata(entityName, attributes)];
                    case 2:
                        metadata = _a.sent();
                        perfEvent.complete();
                        return [2 /*return*/, metadata];
                    case 3:
                        e_1 = _a.sent();
                        exceptionString = "";
                        try {
                            if (e_1 === null || e_1 === void 0 ? void 0 : e_1.message) {
                                exceptionString = e_1.message;
                            }
                            else if (typeof e_1 == "object") {
                                exceptionString = JSON.stringify(e_1);
                            }
                            else {
                                exceptionString = "".concat(e_1);
                            }
                        }
                        catch (_b) {
                            // Do nothing.
                        }
                        message = "CommonDataService: Error during ".concat(perfEvent.scenarioName);
                        perfEvent.addProperties({ exceptionString: exceptionString, attributes: attributes === null || attributes === void 0 ? void 0 : attributes.join(",") });
                        perfEvent.throw(message, "");
                        throw new Error("".concat(message, ": ").concat(exceptionString));
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Get attributes from CDS.
     * @param entityLogicalName The entity name (e.g. "msdynci_customerprofile").
     * @param query The query to limit the set of attributes (e.g. "?$select=DisplayName,AttributeType").
     * @todo Re-implement in terms of getEntityMetadata?
     */
    CommonDataService.prototype.getAttributes = function (entityLogicalName, query) {
        if (query === void 0) { query = ""; }
        return __awaiter(this, void 0, void 0, function () {
            var perfEvent, cdsDataResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        perfEvent = this.startScenario("CDS_API: Get Attributes - ".concat(entityLogicalName), {
                            entityOrPlugin: entityLogicalName
                        });
                        return [4 /*yield*/, this.fetch(perfEvent, this.buildUrlForEntityAttributes(entityLogicalName, query), undefined, /* is404Ok: */ true)];
                    case 1:
                        cdsDataResult = _a.sent();
                        return [4 /*yield*/, (cdsDataResult === null || cdsDataResult === void 0 ? void 0 : cdsDataResult.json())];
                    case 2: return [2 /*return*/, (_a.sent())];
                }
            });
        });
    };
    /**
     * Create a record in CDS - returns the created record's id.
     * @param entityPluralName The entity plural name (e.g. "msdynmkt_journeys").
     * @param data The record to create.
     */
    CommonDataService.prototype.createRecord = function (entityPluralName, data, additionalTraceProperties, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var perfEvent, cdsDataResult, location;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        perfEvent = this.startScenario("CDS_API: Create Record - ".concat(entityPluralName), __assign(__assign({}, additionalTraceProperties), { entityOrPlugin: entityPluralName }));
                        return [4 /*yield*/, this.fetch(perfEvent, this.buildUrlForEntityList(entityPluralName), __assign({ method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(data) }, options))];
                    case 1:
                        cdsDataResult = _b.sent();
                        location = (_a = cdsDataResult === null || cdsDataResult === void 0 ? void 0 : cdsDataResult.headers) === null || _a === void 0 ? void 0 : _a.get("Location");
                        if (location) {
                            return [2 /*return*/, this.getRecordIdFromUrl(location)];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Update the specified record.
     * @param entityPluralName The entity plural name (e.g. "msdynmkt_journeys").
     * @param recordId The entity record id.
     * @param data The updated field values.
     */
    CommonDataService.prototype.patchRecord = function (entityPluralName, recordId, data, additionalTraceProperties, options) {
        return __awaiter(this, void 0, void 0, function () {
            var perfEvent, cdsDataResult, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        perfEvent = this.startScenario("CDS_API: Update Record - ".concat(entityPluralName), __assign(__assign({}, additionalTraceProperties), { entityOrPlugin: entityPluralName, entityId: recordId }));
                        return [4 /*yield*/, this.fetch(perfEvent, this.buildUrlForEntity(entityPluralName, recordId), __assign({ method: "PATCH", headers: { "Content-Type": "application/json" }, body: JSON.stringify(data) }, options))];
                    case 1:
                        cdsDataResult = _b.sent();
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, (cdsDataResult === null || cdsDataResult === void 0 ? void 0 : cdsDataResult.json())];
                    case 3: 
                    // Not all patch responses have a json body
                    return [2 /*return*/, (_b.sent())];
                    case 4:
                        _a = _b.sent();
                        return [2 /*return*/, null];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Delete the specified record.
     * @param entityPluralName The entity plural name (e.g. "msdynmkt_journeys").
     * @param recordId The entity record id.
     * @param is404Ok Set to true to suppress logging errors on 404
     */
    CommonDataService.prototype.deleteRecord = function (entityPluralName, recordId, is404Ok) {
        return __awaiter(this, void 0, void 0, function () {
            var perfEvent;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        perfEvent = this.startScenario("CDS_API: Delete Record - ".concat(entityPluralName), {
                            entityOrPlugin: entityPluralName,
                            entityId: recordId
                        });
                        return [4 /*yield*/, this.fetch(perfEvent, this.buildUrlForEntity(entityPluralName, recordId), { method: "DELETE" }, is404Ok)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Delete specified records.
     * @param entities List of entities to delete
     */
    CommonDataService.prototype.deleteRecordsWithBatchQuery = function (entities) {
        return __awaiter(this, void 0, void 0, function () {
            var batchName, changesetName, dataString, batchUrl, options, perfEvent;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        batchName = "batch_".concat(generateGuid());
                        changesetName = "changeset_".concat(generateGuid());
                        dataString = this.getChangesetBatchDataString(batchName, changesetName, entities, "DELETE");
                        batchUrl = this.buildBatchUrl();
                        options = {
                            method: "POST",
                            headers: this.getBatchHeaders(batchName),
                            body: dataString
                        };
                        perfEvent = this.startScenario("CDS_API: Delete Records With Batch Query", {
                            entities: entities.map(function (entity) { return entity.pluralName; }).join(",")
                        });
                        return [4 /*yield*/, this.fetch(perfEvent, batchUrl, options)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Execute a plugin endpoint
     * @param pluginName plugin name (ie: msdynmkt_PublishJourney)
     * @param data Data to post to the endpoint
     * @param additionalTraceProperties additional properties to add to tracing
     */
    CommonDataService.prototype.executePlugin = function (pluginName, data, additionalTraceProperties, options) {
        return __awaiter(this, void 0, void 0, function () {
            var perfEvent, cdsDataResult, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        perfEvent = this.startScenario("CDS_API: Execute Plugin - ".concat(pluginName), __assign(__assign({}, additionalTraceProperties), { entityOrPlugin: pluginName }));
                        return [4 /*yield*/, this.fetch(perfEvent, this.buildUrlForPlugin(pluginName), __assign({ method: "POST", headers: {
                                    "Content-Type": "application/json",
                                    accept: "application/json"
                                }, body: JSON.stringify(data) }, options))];
                    case 1:
                        cdsDataResult = _b.sent();
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, (cdsDataResult === null || cdsDataResult === void 0 ? void 0 : cdsDataResult.json())];
                    case 3: 
                    // Not all post responses have a json body
                    return [2 /*return*/, (_b.sent())];
                    case 4:
                        _a = _b.sent();
                        return [2 /*return*/, null];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Execute a bound plugin
     * @param pluginName plugin name (ie: msdynmkt_PublishJourney)
     * @param recordId Id of the entity
     * @param entityPluralName Plural name of the entity
     * @param data Data to post to the endpoint
     * @param additionalTraceProperties additional properties to add to tracing
     */
    CommonDataService.prototype.executeBoundPlugin = function (pluginName, recordId, entityPluralName, data, additionalTraceProperties, options) {
        return __awaiter(this, void 0, void 0, function () {
            var perfEvent, cdsDataResult, result, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        perfEvent = this.startScenario("CDS_API: Execute Plugin - ".concat(pluginName), __assign(__assign({}, additionalTraceProperties), { entityOrPlugin: pluginName, entityId: recordId }));
                        return [4 /*yield*/, this.fetch(perfEvent, this.buildUrlForBoundPlugin(pluginName, recordId, entityPluralName), __assign({ method: "POST", headers: {
                                    "Content-Type": "application/json",
                                    accept: "application/json"
                                }, body: JSON.stringify(data) }, options))];
                    case 1:
                        cdsDataResult = _b.sent();
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, (cdsDataResult === null || cdsDataResult === void 0 ? void 0 : cdsDataResult.json())];
                    case 3:
                        result = (_b.sent());
                        return [2 /*return*/, result];
                    case 4:
                        _a = _b.sent();
                        return [2 /*return*/, null];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Execute a bound function
     * @param functionName function name (e.g.: RetrieveUserPrivileges)
     * @param entityId Id of the entity
     * @param entityPluralName Plural name of the entity (e.g. systemusers)
     * @param query Additional query parameters
     */
    CommonDataService.prototype.executeBoundFunction = function (functionName, entityId, entityPluralName, query, additionalTraceProperties, options) {
        if (query === void 0) { query = ""; }
        return __awaiter(this, void 0, void 0, function () {
            var perfEvent, cdsDataResult, result, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        perfEvent = this.startScenario("CDS_API: Execute Plugin - ".concat(functionName), __assign(__assign({}, additionalTraceProperties), { entityOrPlugin: functionName, entityPluralName: entityPluralName, entityId: entityId }));
                        return [4 /*yield*/, this.fetch(perfEvent, "".concat(this.buildUrlForBoundPlugin(functionName, entityId, entityPluralName)).concat(query), __assign({ headers: { accept: "application/json" } }, options))];
                    case 1:
                        cdsDataResult = _b.sent();
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, (cdsDataResult === null || cdsDataResult === void 0 ? void 0 : cdsDataResult.json())];
                    case 3:
                        result = (_b.sent());
                        return [2 /*return*/, result];
                    case 4:
                        _a = _b.sent();
                        return [2 /*return*/, null];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Builds a query string for 'getRecords' to set the fields and sort order of the returned records
     * @param entityName Name of the entity in the query
     * @param fieldList List of fields to include.
     * @param sortField Sort field, if any
     * @param isDescending Whether the sort is ascending or decending
     * @param page Which page to retrieve
     * @param pageSize The size of the page to retrieve
     * @param filter optional, additional filters in fetchxml format
     */
    CommonDataService.prototype.buildQueryString = function (entityName, fieldList, sortField, isDescending, page, pageSize, filter, links) {
        if (pageSize === void 0) { pageSize = 50; }
        var query = "";
        if (fieldList) {
            fieldList.forEach(function (field) {
                query += "<attribute name=\"".concat(field, "\"/>");
            });
        }
        if (filter) {
            query += filter;
        }
        if (sortField) {
            query += "<order attribute=\"".concat(sortField, "\" descending=\"").concat(isDescending, "\"/>");
        }
        if (links) {
            query += links;
        }
        query = "fetchXml=<fetch version=\"1.0\" mapping=\"logical\" page=\"".concat(page, "\" count=\"").concat(pageSize, "\"><entity name=\"").concat(entityName, "\">").concat(query, "</entity></fetch>");
        return query;
    };
    CommonDataService.prototype.getAllEntities = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                // ex: `https://example.cds10.dynamics.com/api/data/v9.0/EntityDefinitions?$select=LogicalName,DisplayName`
                return [2 /*return*/, this.getRecords(EntityDefinitions, "$select=LogicalName,DisplayName")];
            });
        });
    };
    CommonDataService.prototype.getAllEntitiesDirectRelatedTo = function (entityLogicalNames) {
        return __awaiter(this, void 0, void 0, function () {
            var filters, url, perfEvent, entities, cdsData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!entityLogicalNames || entityLogicalNames.length == 0)
                            return [2 /*return*/, null];
                        filters = entityLogicalNames.map(function (name) { return "ReferencedEntity eq '".concat(name, "'"); }).join(" or ");
                        url = "".concat(this.cdsBaseUrl).concat(ODataPathPrefix).concat(RelationshipDefinitionsOneToMany, "/?$select=ReferencingEntity&$filter=(").concat(filters, ")");
                        perfEvent = this.startScenario("CDS_API: Get CDS_API: Get All entities with direct relationship");
                        return [4 /*yield*/, this.fetch(perfEvent, url, undefined, /* is404Ok: */ true)];
                    case 1:
                        entities = _a.sent();
                        return [4 /*yield*/, (entities === null || entities === void 0 ? void 0 : entities.json())];
                    case 2:
                        cdsData = (_a.sent());
                        if (cdsData && cdsData.value) {
                            return [2 /*return*/, cdsData.value];
                        }
                        return [2 /*return*/, null];
                }
            });
        });
    };
    CommonDataService.prototype.getLogicalNamesForCreateAndUpdateActions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var perfEvent, sdkMessageUrl, sdkMessagesIdsResult, sdkMessagesIds, sdkMessageFiltersUrl, i, sdkMessageFilterResult, sdkMessageObject, logicalNamesMap;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        perfEvent = this.startScenario("CDS_API: Get entities logical names for  sdkMessages: create and update");
                        sdkMessageUrl = "".concat(this.cdsBaseUrl).concat(ODataPathPrefix, "/sdkmessages?$select=sdkmessageid&$filter=isprivate eq false and (name eq 'Create' or name eq 'Update')&$orderby=name");
                        return [4 /*yield*/, this.fetch(perfEvent, sdkMessageUrl, undefined, /* is404Ok: */ true)];
                    case 1:
                        sdkMessagesIdsResult = _a.sent();
                        return [4 /*yield*/, (sdkMessagesIdsResult === null || sdkMessagesIdsResult === void 0 ? void 0 : sdkMessagesIdsResult.json())];
                    case 2:
                        sdkMessagesIds = (_a.sent());
                        if (!sdkMessagesIds || !sdkMessagesIds.value || sdkMessagesIds.value.length < 1) {
                            return [2 /*return*/, null];
                        }
                        sdkMessageFiltersUrl = "".concat(this.cdsBaseUrl).concat(ODataPathPrefix, "/sdkmessagefilters?$select=primaryobjecttypecode,_sdkmessageid_value&$filter=");
                        for (i in sdkMessagesIds.value) {
                            sdkMessageFiltersUrl +=
                                +i < sdkMessagesIds.value.length - 1
                                    ? "(_sdkmessageid_value eq ".concat(sdkMessagesIds.value[i].sdkmessageid, " or ")
                                    : "_sdkmessageid_value eq ".concat(sdkMessagesIds.value[i].sdkmessageid, ") and ");
                        }
                        sdkMessageFiltersUrl += "iscustomprocessingstepallowed eq true and isvisible eq true";
                        return [4 /*yield*/, this.fetch(perfEvent, sdkMessageFiltersUrl, undefined, /* is404Ok: */ true)];
                    case 3:
                        sdkMessageFilterResult = _a.sent();
                        return [4 /*yield*/, (sdkMessageFilterResult === null || sdkMessageFilterResult === void 0 ? void 0 : sdkMessageFilterResult.json())];
                    case 4:
                        sdkMessageObject = (_a.sent());
                        if (!sdkMessageObject || !sdkMessageObject.value || sdkMessageObject.value.length < 1) {
                            return [2 /*return*/, null];
                        }
                        logicalNamesMap = new Map();
                        if (sdkMessageObject && sdkMessageObject.value) {
                            sdkMessageObject.value.forEach(function (item) {
                                var _a, _b;
                                if (!logicalNamesMap.has(item.primaryobjecttypecode)) {
                                    var actionNames = [
                                        (_a = sdkMessagesIds.value.find(function (actionMessage) { return (item === null || item === void 0 ? void 0 : item._sdkmessageid_value) == (actionMessage === null || actionMessage === void 0 ? void 0 : actionMessage.sdkmessageid); })) === null || _a === void 0 ? void 0 : _a.name
                                    ];
                                    logicalNamesMap.set(item.primaryobjecttypecode, actionNames);
                                }
                                else {
                                    var value = logicalNamesMap.get(item.primaryobjecttypecode);
                                    var newAction = (_b = sdkMessagesIds.value.find(function (actionMessage) { return item._sdkmessageid_value == actionMessage.sdkmessageid; })) === null || _b === void 0 ? void 0 : _b.name;
                                    if (newAction) {
                                        value === null || value === void 0 ? void 0 : value.push(newAction);
                                    }
                                }
                            });
                            return [2 /*return*/, logicalNamesMap];
                        }
                        return [2 /*return*/, null];
                }
            });
        });
    };
    CommonDataService.prototype.getInstalledSolutionVersion = function (solution) {
        return __awaiter(this, void 0, void 0, function () {
            var filter, queryString, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filter = "<filter><condition attribute=\"uniquename\" operator=\"eq\" value=\"".concat(solution, "\"/></filter>");
                        queryString = this.buildQueryString("solution", ["version"], null, false, 1, 1, filter);
                        return [4 /*yield*/, this.getRecords("solutions", queryString)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res && res.length > 0 ? res[0].version : undefined];
                }
            });
        });
    };
    CommonDataService.prototype.getUserPrivileges = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.executeBoundFunction("RetrieveUserPrivileges", userId, "systemusers")];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result === null || result === void 0 ? void 0 : result.RolePrivileges];
                }
            });
        });
    };
    CommonDataService.prototype.getRecordPrivileges = function (userId, entityPluralName, entityId) {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.executeBoundFunction("RetrievePrincipalAccess", userId, "systemusers", "(Target=@t)?@t={'@odata.id':'".concat(entityPluralName, "(").concat(entityId, ")'}"))];
                    case 1:
                        result = _a.sent();
                        if (result == null) {
                            return [2 /*return*/, []];
                        }
                        return [2 /*return*/, result === null || result === void 0 ? void 0 : result.AccessRights.split(",").map(function (v) { return v.trim(); })];
                }
            });
        });
    };
    CommonDataService.prototype.getBatchHeaders = function (batchName) {
        return {
            "Content-Type": "multipart/mixed;boundary=".concat(batchName),
            Accept: "application/json",
            "OData-MaxVersion": "4.0",
            "OData-Version": "4.0"
        };
    };
    CommonDataService.prototype.getChangesetBatchDataString = function (batchName, changesetName, entities, operation) {
        var _this = this;
        var changesetString = entities
            .map(function (entity, idx) {
            var url = _this.buildUrlForEntity(entity.pluralName, entity.id);
            return "--".concat(changesetName, "\nContent-Type: application/http\nContent-Transfer-Encoding: binary\nContent-ID: ").concat(idx + 1, "\n\n").concat(operation, " ").concat(url, " HTTP/1.1\nContent-Type: application/json;type=entry\n\n").concat(entity.attributes ? JSON.stringify(entity.attributes) : "");
        })
            .join("\r\n");
        return "--".concat(batchName, "\nContent-Type: multipart/mixed;boundary=").concat(changesetName, "\n\n").concat(changesetString, "\n--").concat(changesetName, "--\n--").concat(batchName, "--");
    };
    CommonDataService.prototype.parseBatchResponse = function (responseHeaders, responseBody) {
        var e_2, _a;
        var _b;
        var contentType = responseHeaders.get("Content-Type");
        if (!(contentType === null || contentType === void 0 ? void 0 : contentType.trim().startsWith("multipart/mixed"))) {
            return null;
        }
        var boundary = (_b = contentType
            .split(";")
            .map(function (v) { return v.trim(); })
            .find(function (v) { return v.startsWith("boundary="); })) === null || _b === void 0 ? void 0 : _b.substring("boundary=".length);
        if (!boundary) {
            return null;
        }
        var boundaryLine = "--".concat(boundary, "\r\n");
        var boundaryEndLine = "--".concat(boundary, "--\r\n");
        if (!responseBody.startsWith(boundaryLine) || !responseBody.endsWith(boundaryEndLine)) {
            return null;
        }
        var results = [];
        var parts = responseBody.substring(boundaryLine.length, responseBody.length - boundaryEndLine.length).split("\r\n".concat(boundaryLine));
        try {
            for (var parts_1 = __values(parts), parts_1_1 = parts_1.next(); !parts_1_1.done; parts_1_1 = parts_1.next()) {
                var part = parts_1_1.value;
                if (!part) {
                    continue;
                }
                var endOfPartHeaders = part.indexOf("\r\n\r\n");
                var partHeadersString = part.substring(0, endOfPartHeaders);
                var partHttpResponse = part.substring(endOfPartHeaders + 4);
                var partHeaders = new Headers(partHeadersString.split("\r\n").map(function (line) {
                    var indexOfColon = line.indexOf(":");
                    return [line.substring(0, indexOfColon), line.substring(indexOfColon + 1)];
                }));
                var endOfHeaders = partHttpResponse.indexOf("\r\n\r\n");
                var statusLineAndHeaders = partHttpResponse.substring(0, endOfHeaders);
                var body = partHttpResponse.substring(endOfHeaders + 4);
                var _c = __read(statusLineAndHeaders.split("\r\n")), statusLine = _c[0], headerLines = _c.slice(1);
                var statusLineMatch = /^HTTP\/(?:\d|\.)+ (\d{3}) ?(.*)$/.exec(statusLine);
                if (statusLineMatch) {
                    var statusCode = +statusLineMatch[1];
                    var statusMessage = statusLineMatch[2];
                    var headers = new Headers(headerLines.map(function (line) {
                        var indexOfColon = line.indexOf(":");
                        return [line.substring(0, indexOfColon), line.substring(indexOfColon + 1)];
                    }));
                    results.push({ partHeaders: partHeaders, statusCode: statusCode, statusMessage: statusMessage, headers: headers, body: body });
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (parts_1_1 && !parts_1_1.done && (_a = parts_1.return)) _a.call(parts_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return results;
    };
    CommonDataService.prototype.startScenario = function (scenarioName, properties) {
        var perfService = this.appContext.getService(PerformanceServiceName);
        return perfService.startScenario(scenarioName, properties);
    };
    CommonDataService.prototype.fetch = function (perfEvent, url, options, is404Ok) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _loop_1, this_1, retryCount, state_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _loop_1 = function (retryCount) {
                            var clientRequestId, requestInit, queryStringIndex, baseUrl, response, e_3, exceptionString, message, responseBody, _c, responseMessage, correlationIdText, correlationIdIndex, correlationId, message, delay_1, retryAfterHeader, retryAfter, err, err;
                            var _d, _e, _f;
                            return __generator(this, function (_g) {
                                switch (_g.label) {
                                    case 0:
                                        clientRequestId = generateGuid();
                                        requestInit = __assign(__assign({}, options), { headers: __assign((_d = {}, _d[ClientRequestIdHeader] = clientRequestId, _d), options === null || options === void 0 ? void 0 : options.headers) });
                                        queryStringIndex = url.indexOf("?");
                                        baseUrl = queryStringIndex > 0 ? url.substring(0, queryStringIndex) : url;
                                        perfEvent.addProperties((_e = {},
                                            _e[ClientRequestIdHeader] = clientRequestId,
                                            _e.retryCount = retryCount,
                                            _e.baseUrl = baseUrl,
                                            _e));
                                        response = void 0;
                                        _g.label = 1;
                                    case 1:
                                        _g.trys.push([1, 3, , 4]);
                                        return [4 /*yield*/, fetch(url, requestInit)];
                                    case 2:
                                        response = _g.sent();
                                        return [3 /*break*/, 4];
                                    case 3:
                                        e_3 = _g.sent();
                                        exceptionString = "";
                                        try {
                                            exceptionString = "".concat(e_3);
                                        }
                                        catch (_h) {
                                            // Do nothing.
                                        }
                                        // Exceptions from 'fetch' are due to communication failures (couldn't contact the host) or
                                        // problems with the request itself (bad headers or other request parameters)
                                        // See https://developer.mozilla.org/en-US/docs/Web/API/fetch
                                        perfEvent.addProperties({
                                            fetchFailure: true,
                                            exceptionString: exceptionString,
                                            fullUrl: url
                                        });
                                        message = "CommonDataService: Error during ".concat(perfEvent.scenarioName);
                                        perfEvent.throw(message, "");
                                        throw new Error("".concat(message, ": : ").concat(exceptionString));
                                    case 4:
                                        perfEvent.addProperties((_f = {
                                                responseStatus: response.status
                                            },
                                            _f[ServiceRequestIdHeader] = response.headers.get(ServiceRequestIdHeader),
                                            _f));
                                        if (!(response.status >= 400 && !(is404Ok && response.status == 404))) return [3 /*break*/, 11];
                                        responseBody = "";
                                        _g.label = 5;
                                    case 5:
                                        _g.trys.push([5, 7, , 8]);
                                        return [4 /*yield*/, response.text()];
                                    case 6:
                                        responseBody = _g.sent();
                                        return [3 /*break*/, 8];
                                    case 7:
                                        _c = _g.sent();
                                        return [3 /*break*/, 8];
                                    case 8:
                                        responseMessage = "";
                                        if (responseBody) {
                                            responseMessage = this_1.extractFriendlyErrorMessageFromResponse(response.headers, responseBody) || "";
                                            correlationIdText = "CorrelationId: ";
                                            correlationIdIndex = responseBody.indexOf(correlationIdText);
                                            correlationId = correlationIdIndex >= 0 ? responseBody.substr(correlationIdIndex + correlationIdText.length, 36) : "";
                                            perfEvent.addProperties({
                                                responseMessage: responseMessage,
                                                responseBody: responseBody,
                                                correlationId: correlationId
                                            });
                                        }
                                        perfEvent.addProperties({
                                            fullUrl: url
                                        });
                                        message = "CommonDataService: Error during ".concat(perfEvent.scenarioName);
                                        perfEvent.throw(message, (_a = response.status) === null || _a === void 0 ? void 0 : _a.toString());
                                        if (!(response.status == 429 && retryCount < Max429RetryCount)) return [3 /*break*/, 10];
                                        delay_1 = Math.pow(2, retryCount + 1) + Math.random();
                                        retryAfterHeader = response.headers.get("Retry-After");
                                        retryAfter = retryAfterHeader ? Number.parseInt(retryAfterHeader) : 0;
                                        if (retryAfter && retryAfter > delay_1) {
                                            delay_1 = retryAfter;
                                        }
                                        return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, delay_1 * 1000); })];
                                    case 9:
                                        _g.sent();
                                        return [2 /*return*/, "continue"];
                                    case 10:
                                        // If considered user error, throw user-friendly message
                                        if (response.status < 500 && responseMessage) {
                                            err = new Error(responseMessage);
                                            err.errorCode = response.status;
                                            throw err;
                                        }
                                        else {
                                            err = new Error("".concat(message, ": ").concat(response.status, ": ").concat(responseBody));
                                            err.errorCode = response.status;
                                            throw err;
                                        }
                                        _g.label = 11;
                                    case 11:
                                        perfEvent.complete();
                                        return [2 /*return*/, { value: response }];
                                }
                            });
                        };
                        this_1 = this;
                        retryCount = 0;
                        _b.label = 1;
                    case 1:
                        if (!(retryCount <= Max429RetryCount)) return [3 /*break*/, 4];
                        return [5 /*yield**/, _loop_1(retryCount)];
                    case 2:
                        state_1 = _b.sent();
                        if (typeof state_1 === "object")
                            return [2 /*return*/, state_1.value];
                        _b.label = 3;
                    case 3:
                        retryCount++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CommonDataService.prototype.extractFriendlyErrorMessageFromResponse = function (responseHeaders, responseBody) {
        var e_4, _a;
        var _b;
        try {
            var batchResults = this.parseBatchResponse(responseHeaders, responseBody);
            if (batchResults === null || batchResults === void 0 ? void 0 : batchResults.length) {
                try {
                    for (var batchResults_1 = __values(batchResults), batchResults_1_1 = batchResults_1.next(); !batchResults_1_1.done; batchResults_1_1 = batchResults_1.next()) {
                        var batchResult = batchResults_1_1.value;
                        if (batchResult.statusCode >= 400) {
                            var errorMessage = this.extractFriendlyErrorMessageFromResponse(batchResult.headers, batchResult.body);
                            if (errorMessage) {
                                return errorMessage;
                            }
                        }
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (batchResults_1_1 && !batchResults_1_1.done && (_a = batchResults_1.return)) _a.call(batchResults_1);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
            }
        }
        catch (_c) {
            // Do nothing.
        }
        try {
            var responseJson = JSON.parse(responseBody);
            var errorMessage = (_b = responseJson === null || responseJson === void 0 ? void 0 : responseJson.error) === null || _b === void 0 ? void 0 : _b.message;
            if (errorMessage) {
                return errorMessage;
            }
        }
        catch (_d) {
            // Do nothing.
        }
        return null;
    };
    CommonDataService.prototype.getRecordIdFromUrl = function (url) {
        // ex: `https://example.cds10.dynamics.com/api/data/v9.0/msdynmkt_journeys(RECORDID)` -> RECORDID
        var result = /^.*\(([a-f0-9-]+)\)$/i.exec(url);
        if (result) {
            return result[1];
        }
    };
    CommonDataService.prototype.buildUrlForEntityList = function (entityPluralName, query) {
        if (query === void 0) { query = ""; }
        // ex: `https://example.cds10.dynamics.com/api/data/v9.0/msdynmkt_journeys?$select=msdynmkt_name`
        var url = "".concat(this.cdsBaseUrl).concat(ODataPathPrefix).concat(entityPluralName);
        if (query) {
            url += "?".concat(query);
        }
        return url;
    };
    CommonDataService.prototype.buildUrlForEntityCount = function (entityPluralName, entityColumn, filter) {
        if (filter === void 0) { filter = ""; }
        // ex: `https://example.cds10.dynamics.com/api/data/v9.0/msdynmkt_journeys?$select=msdynmkt_name&$filter=msdynmkt_field eq 1`
        var url = "".concat(this.cdsBaseUrl).concat(ODataPathPrefix).concat(entityPluralName, "?$top=1&$select=").concat(entityColumn, "&$count=true");
        if (filter) {
            url += "&$filter=".concat(encodeURIComponent(filter));
        }
        return url;
    };
    CommonDataService.prototype.buildUrlForEntity = function (entityPluralName, recordId, attributes) {
        // ex: `https://example.cds10.dynamics.com/api/data/v9.0/msdynmkt_journeys(RECORDID)`
        var select = attributes && attributes.length ? "?$select=".concat(attributes.join(",")) : "";
        return "".concat(this.cdsBaseUrl).concat(ODataPathPrefix).concat(entityPluralName, "(").concat(normalizeGuid(recordId), ")").concat(select);
    };
    CommonDataService.prototype.buildUrlForEntityAttributes = function (entityLogicalName, query) {
        if (query === void 0) { query = ""; }
        // ex: `https://example.cds10.dynamics.com/api/data/v9.0/EntityDefinitions(LogicalName='msdynmkt_virtualcustomer')/Attributes?$select=DisplayName,AttributeType`
        var url = "".concat(this.cdsBaseUrl).concat(ODataPathPrefix).concat(EntityDefinitions);
        url += "(LogicalName='".concat(entityLogicalName, "')/Attributes");
        if (query) {
            url += "?".concat(query);
        }
        return url;
    };
    CommonDataService.prototype.buildUrlForPlugin = function (pluginName) {
        // ex: `https://example.cds10.dynamics.com/api/data/v9.0/msdynmkt_PublishJourney`
        return "".concat(this.cdsBaseUrl).concat(ODataPathPrefix).concat(pluginName);
    };
    CommonDataService.prototype.buildUrlForBoundPlugin = function (pluginName, recordId, entityPluralName) {
        // ex: `https://example.cds10.dynamics.com/api/data/v9.0/msdynmkt_journeys(RECORDID)/Microsoft.Dynamics.CRM.msdynmkt_CreateDraftJourneyVersion`
        return "".concat(this.buildUrlForEntity(entityPluralName, recordId), "/Microsoft.Dynamics.CRM.").concat(pluginName);
    };
    CommonDataService.prototype.buildBatchUrl = function () {
        return "".concat(this.cdsBaseUrl).concat(ODataPathPrefix, "$batch");
    };
    return CommonDataService;
}(Service));
export { CommonDataService };
