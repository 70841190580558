var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Service } from "@cxp-shared/platform/Service";
import { CommonDataServiceName } from "@cxp-shared/services/CDS";
import { TelemetryServiceName } from "@cxp-shared/services/Telemetry";
/**
 * This service is responsible for calling the AI APIs needed for personalization.
 * This is the default implementation of the service for production use and is based on plugins.
 */
var PersonalizationAIPluginService = /** @class */ (function (_super) {
    __extends(PersonalizationAIPluginService, _super);
    function PersonalizationAIPluginService() {
        var _this = _super.call(this) || this;
        _this.suggestDynamicTextPlaceholders = function (request) { return __awaiter(_this, void 0, void 0, function () {
            var pluginResult, response, suggestedPlaceholders, error_1;
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.cds.executePlugin("msdynmkt_PersonalizationDynamicTextAi", {
                                msdynmkt_PersonalizationDynamicTextAiRequest: JSON.stringify(request)
                            })];
                    case 1:
                        pluginResult = _e.sent();
                        response = JSON.parse(pluginResult["msdynmkt_PersonalizationDynamicTextAiResponse"]);
                        if ((response === null || response === void 0 ? void 0 : response.status) !== 200 || ((_a = response === null || response === void 0 ? void 0 : response.placeholders) === null || _a === void 0 ? void 0 : _a.status) !== 200) {
                            this.telemetryService.logError("Failed to retrieve suggested placeholders from msdynmkt_PersonalizationDynamicTextAi");
                            return [2 /*return*/, {
                                    status: 500,
                                    message: "Fail",
                                    placeholders: {
                                        message: "Fail",
                                        status: 500,
                                        suggestedPlaceholders: []
                                    }
                                }];
                        }
                        if (!((_c = (_b = response.placeholders) === null || _b === void 0 ? void 0 : _b.suggestedPlaceholders) === null || _c === void 0 ? void 0 : _c.length)) {
                            return [2 /*return*/, {
                                    status: 404,
                                    message: (_d = response.placeholders) === null || _d === void 0 ? void 0 : _d.message,
                                    placeholders: {
                                        message: "Not found",
                                        status: 404,
                                        suggestedPlaceholders: []
                                    }
                                }];
                        }
                        suggestedPlaceholders = response.placeholders.suggestedPlaceholders
                            .map(function (sp) {
                            var _a;
                            return ({
                                name: sp.name,
                                placeholder: (_a = request.suggestFromPlaceholders.placeholders.find(function (p) { return p.description === sp.description; })) === null || _a === void 0 ? void 0 : _a.placeholder,
                                reason: sp.reason,
                                description: sp.description,
                                confidence: sp.confidence
                            });
                        })
                            .filter(function (sp) { return sp.placeholder; });
                        suggestedPlaceholders.forEach(function (sp) {
                            sp.placeholder = JSON.parse(JSON.stringify(sp.placeholder));
                            sp.placeholder.title = sp.name;
                            delete sp.placeholder.defaultValue;
                        });
                        return [2 /*return*/, {
                                status: 200,
                                message: "Success",
                                placeholders: {
                                    message: "Success",
                                    status: 200,
                                    suggestedPlaceholders: suggestedPlaceholders
                                }
                            }];
                    case 2:
                        error_1 = _e.sent();
                        this.telemetryService.logError("Failed to retrieve suggested placeholders from msdynmkt_PersonalizationDynamicTextAi", error_1);
                        return [2 /*return*/, {
                                status: 500,
                                message: "Fail",
                                placeholders: {
                                    message: "Fail",
                                    status: 500,
                                    suggestedPlaceholders: []
                                }
                            }];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        _this.suggestDynamicTextRelationships = function (request) { return __awaiter(_this, void 0, void 0, function () {
            var pluginResult, response, suggestedRelationships, suggestedType, error_2;
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.cds.executePlugin("msdynmkt_PersonalizationDynamicTextAi", {
                                msdynmkt_PersonalizationDynamicTextAiRequest: JSON.stringify(request)
                            })];
                    case 1:
                        pluginResult = _e.sent();
                        response = JSON.parse(pluginResult["msdynmkt_PersonalizationDynamicTextAiResponse"]);
                        if ((response === null || response === void 0 ? void 0 : response.status) !== 200 || ((_a = response === null || response === void 0 ? void 0 : response.relationships) === null || _a === void 0 ? void 0 : _a.status) !== 200) {
                            this.telemetryService.logError("Failed to retrieve suggested relationships from msdynmkt_PersonalizationDynamicTextAi");
                            return [2 /*return*/, {
                                    status: 500,
                                    message: "Fail",
                                    relationships: {
                                        message: "Fail",
                                        status: 500,
                                        suggestedRelationships: [],
                                        suggestedType: "string"
                                    }
                                }];
                        }
                        if (!((_c = (_b = response.relationships) === null || _b === void 0 ? void 0 : _b.suggestedRelationships) === null || _c === void 0 ? void 0 : _c.length)) {
                            return [2 /*return*/, {
                                    status: 404,
                                    message: (_d = response.relationships) === null || _d === void 0 ? void 0 : _d.message,
                                    relationships: {
                                        message: "Not found",
                                        status: 404,
                                        suggestedRelationships: [],
                                        suggestedType: "string"
                                    }
                                }];
                        }
                        suggestedRelationships = response.relationships.suggestedRelationships
                            .map(function (sp) { return ({
                            reason: sp.reason,
                            description: sp.description,
                            confidence: sp.confidence
                        }); })
                            .filter(function (sp) { return sp.description; });
                        suggestedType = response.relationships.suggestedType;
                        return [2 /*return*/, {
                                status: 200,
                                message: "Success",
                                relationships: {
                                    message: "Success",
                                    status: 200,
                                    suggestedRelationships: suggestedRelationships,
                                    suggestedType: suggestedType
                                }
                            }];
                    case 2:
                        error_2 = _e.sent();
                        this.telemetryService.logError("Failed to retrieve suggested relationships from msdynmkt_PersonalizationDynamicTextAi", error_2);
                        return [2 /*return*/, {
                                status: 500,
                                message: "Fail",
                                relationships: {
                                    message: "Fail",
                                    status: 500,
                                    suggestedRelationships: [],
                                    suggestedType: "string"
                                }
                            }];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    PersonalizationAIPluginService.prototype.serviceStart = function (appContext) {
        _super.prototype.serviceStart.call(this, appContext);
        this.cds = this.appContext.getService(CommonDataServiceName);
        this.telemetryService = this.appContext.getService(TelemetryServiceName);
    };
    return PersonalizationAIPluginService;
}(Service));
export { PersonalizationAIPluginService };
