var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getLiveDependentJourneysDataDemand } from "@cxp-shared/channel-lifecycle";
import { normalizeGuid } from "@cxp-shared/string-utilities/normalize-guid";
import { PersonalizationFeatureFlags } from "../../Constants/FeatureFlags";
export var EmailLiveEditabilityCacheKey = "__EmailLiveEditabilityCacheKey__";
export var ChannelResolvesDataDemandEnabledFlag = "ChannelResolvesDataDemandEnabled";
export var ChannelLinkHandlingV2Flag = "ChannelLinksHandlingV2";
export var isPersoLiveEditOnEmailFlagEnabled = function (mktFeatureServiceClient) {
    return mktFeatureServiceClient.isFCSEnabled(PersonalizationFeatureFlags.FCSNameSpace, PersonalizationFeatureFlags.FCSLiveEditOnEmail);
};
export var isEmailPersonalizationLiveEditable = function (emailEntityId, forceRefresh) {
    if (forceRefresh === void 0) { forceRefresh = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var cache, normalizedId, data_1, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!window[EmailLiveEditabilityCacheKey]) {
                        window[EmailLiveEditabilityCacheKey] = {};
                    }
                    cache = window[EmailLiveEditabilityCacheKey];
                    normalizedId = normalizeGuid(emailEntityId);
                    if (!forceRefresh && cache[normalizedId]) {
                        data_1 = cache[normalizedId];
                        if (!isCacheEntryExpired(data_1 === null || data_1 === void 0 ? void 0 : data_1.timestamp)) {
                            return [2 /*return*/, data_1.isPersoLiveEditable];
                        }
                    }
                    return [4 /*yield*/, fetchJourneyDependencies(normalizedId)];
                case 1:
                    data = _a.sent();
                    cache[normalizedId] = __assign(__assign({}, (cache[normalizedId] || {})), data);
                    return [2 /*return*/, data.isPersoLiveEditable];
            }
        });
    });
};
export var getEmailLiveEditabilityFromCache = function (emailEntityId) {
    if (!window[EmailLiveEditabilityCacheKey]) {
        window[EmailLiveEditabilityCacheKey] = {};
    }
    var cache = window[EmailLiveEditabilityCacheKey];
    var value = cache[normalizeGuid(emailEntityId)];
    if (isCacheEntryExpired(value === null || value === void 0 ? void 0 : value.timestamp)) {
        return null;
    }
    else {
        return value;
    }
};
export var setEmailLiveEditabilityToCache = function (emailEntityId, data) {
    if (!window[EmailLiveEditabilityCacheKey]) {
        window[EmailLiveEditabilityCacheKey] = {};
    }
    var cache = window[EmailLiveEditabilityCacheKey];
    cache[normalizeGuid(emailEntityId)] = data;
};
export var clearEmailLiveEditabilityCache = function () {
    window[EmailLiveEditabilityCacheKey] = {};
};
export var fetchJourneyDependencies = function (emailEntityId, includeFormerlyLiveJourneys) { return __awaiter(void 0, void 0, void 0, function () {
    var liveJourneysDataDemands, isLiveEditable, areLinksEditable, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, getLiveDependentJourneysDataDemand("msdynmkt_email", emailEntityId, includeFormerlyLiveJourneys)];
            case 1:
                liveJourneysDataDemands = _a.sent();
                isLiveEditable = liveJourneysDataDemands.every(function (journeyData) { return hasChannelResolvesDataDemandEnabled(journeyData.flags, ChannelResolvesDataDemandEnabledFlag); });
                areLinksEditable = liveJourneysDataDemands.every(function (journeyData) { return hasChannelResolvesDataDemandEnabled(journeyData.flags, ChannelLinkHandlingV2Flag); });
                return [2 /*return*/, {
                        isPersoLiveEditable: isLiveEditable,
                        areLinksEditable: areLinksEditable,
                        timestamp: Date.now()
                    }];
            case 2:
                e_1 = _a.sent();
                console.error("Error fetching flags for live journeys:", e_1);
                return [2 /*return*/, {
                        isPersoLiveEditable: false,
                        areLinksEditable: false,
                        timestamp: Date.now()
                    }];
            case 3: return [2 /*return*/];
        }
    });
}); };
var hasChannelResolvesDataDemandEnabled = function (flagsValue, requiredFlag) {
    var flags = (flagsValue === null || flagsValue === void 0 ? void 0 : flagsValue.length) ? JSON.parse(flagsValue) : [];
    return flags === null || flags === void 0 ? void 0 : flags.some(function (flag) { return flag === requiredFlag; });
};
var isCacheEntryExpired = function (timestamp) {
    if (timestamp == null) {
        return true;
    }
    var ttl = 15 * 60 * 1000;
    var dateDiff = Date.now() - (timestamp !== null && timestamp !== void 0 ? timestamp : 0);
    return dateDiff >= ttl;
};
