import { FormLoader } from "./FormLoader";
import { FormServiceClient } from "./FormServiceClient";
import { T } from "./Localization";
import { Logger } from "./Logger";
export function createFormLoaderFactory(logger) {
    return function () {
        logger = logger !== null && logger !== void 0 ? logger : new Logger();
        return new FormLoader(logger, new FormServiceClient(logger), T);
    };
}
