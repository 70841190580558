export function recordToPredefinedPlaceholder(record) {
    var result = {
        builtIn: record.msdynmkt_isbuiltin,
        category: placeholderTypeToCategory(record.msdynmkt_placeholdertype),
        id: record.msdynmkt_predefinedplaceholderid,
        label: record.msdynmkt_placeholderlabel,
        namespace: record.msdynmkt_namespace,
        placeholderdescriptor: JSON.parse(record.msdynmkt_placeholderdescriptors),
        priority: record.msdynmkt_usecount,
        useCount: record.msdynmkt_usecount,
        usedOn: record.msdynmkt_usedon,
        description: record.msdynmkt_description,
        businessUnitId: record["_owningbusinessunit_value"]
    };
    return result;
}
export function predefinedPlaceholderToRecord(predefinedPlaceholder) {
    var _a;
    var result = {
        msdynmkt_description: (_a = predefinedPlaceholder.placeholderdescriptor.alternateBindings) === null || _a === void 0 ? void 0 : _a[0].description,
        msdynmkt_isbuiltin: predefinedPlaceholder.builtIn,
        msdynmkt_namespace: predefinedPlaceholder.namespace,
        msdynmkt_placeholderdescriptors: JSON.stringify(predefinedPlaceholder.placeholderdescriptor),
        msdynmkt_placeholderlabel: predefinedPlaceholder.label,
        msdynmkt_placeholdertype: categoryToPlaceholderType(predefinedPlaceholder.category),
        msdynmkt_predefinedplaceholderid: predefinedPlaceholder.id,
        msdynmkt_usecount: predefinedPlaceholder.priority,
        msdynmkt_usedon: predefinedPlaceholder.usedOn,
    };
    if (predefinedPlaceholder.businessUnitId) {
        result["owningbusinessunit@odata.bind"] = "businessunits(".concat(predefinedPlaceholder.businessUnitId, ")");
    }
    return result;
}
var placeholderTypeMap = {
    "general": 1,
    "condition": 2,
    "list": 3
};
export function placeholderTypeToCategory(placeholderType) {
    for (var property in placeholderTypeMap) {
        if (placeholderTypeMap[property] === placeholderType) {
            return property;
        }
    }
    throw Error("Unsupported placeholder type: ".concat(placeholderType));
}
export function categoryToPlaceholderType(category) {
    var result = placeholderTypeMap[category];
    if (!result) {
        throw Error("Unsupported placeholder category: ".concat(category));
    }
    return result;
}
export function getPredefinedPlaceholderDescription(predefinedPlaceholder) {
    var _a, _b, _c;
    return (_c = (_a = predefinedPlaceholder === null || predefinedPlaceholder === void 0 ? void 0 : predefinedPlaceholder.description) !== null && _a !== void 0 ? _a : (_b = predefinedPlaceholder === null || predefinedPlaceholder === void 0 ? void 0 : predefinedPlaceholder.placeholderdescriptor) === null || _b === void 0 ? void 0 : _b.title) !== null && _c !== void 0 ? _c : predefinedPlaceholder === null || predefinedPlaceholder === void 0 ? void 0 : predefinedPlaceholder.label;
}
