var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AzureKeyCredential, OpenAIClient } from "@azure/openai";
import { Service } from "@cxp-shared/platform/Service";
import { placeholderPrompts } from "./PersonalizationAIPlaceholderTestPrompts";
import { relationshipPrompts } from "./PersonalizationAIRelationshipTestPrompts";
var ChatGptApiEndpoint = "PersoAI-ChatGptApiEndpoint";
var ChatGptApiKey = "PersoAI-ChatGptApiKey";
var OpenAiModelName = "gpt-4";
var OpenAiModelTokenLimit = 8097; // https://platform.openai.com/docs/models/gpt-4
var MaxResponseTokenLimit = 1000;
var MaxRequestTokenLimit = OpenAiModelTokenLimit - MaxResponseTokenLimit;
var OpenAiTemperature = 0.4;
/**
 * This service is responsible for calling the AI APIs needed for personalization.
 * This is the testing implementation of the service and is directly connecting to Azure OpenAI API.
 */
var PersonalizationAIAzureOAIService = /** @class */ (function (_super) {
    __extends(PersonalizationAIAzureOAIService, _super);
    function PersonalizationAIAzureOAIService() {
        var _this = _super.call(this) || this;
        _this.suggestDynamicTextPlaceholders = function (request) { return __awaiter(_this, void 0, void 0, function () {
            var suggestFromPlaceholdersResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.suggestFromPlaceholders(request.suggestFromPlaceholders)];
                    case 1:
                        suggestFromPlaceholdersResponse = _a.sent();
                        return [2 /*return*/, {
                                status: suggestFromPlaceholdersResponse.status,
                                message: suggestFromPlaceholdersResponse.message,
                                placeholders: suggestFromPlaceholdersResponse
                            }];
                }
            });
        }); };
        _this.suggestDynamicTextRelationships = function (request) { return __awaiter(_this, void 0, void 0, function () {
            var suggestFromRelationshipsResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.suggestFromRelationships(request.suggestFromRelationships)];
                    case 1:
                        suggestFromRelationshipsResponse = _a.sent();
                        return [2 /*return*/, {
                                status: suggestFromRelationshipsResponse.status,
                                message: suggestFromRelationshipsResponse.message,
                                relationships: suggestFromRelationshipsResponse
                            }];
                }
            });
        }); };
        _this.suggestFromPlaceholders = function (request) { return __awaiter(_this, void 0, void 0, function () {
            var content, client, completion, responseText, resultData, result, message, suggestedPlaceholders, error_1;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 2, , 3]);
                        content = PersonalizationAIAzureOAIService.createPromptWithPlaceholders(request);
                        client = this.getClient();
                        return [4 /*yield*/, client.getChatCompletions(OpenAiModelName, [
                                { role: "system", content: placeholderPrompts.promptBase },
                                { role: "user", content: placeholderPrompts.example1input },
                                { role: "assistant", content: placeholderPrompts.example1output },
                                { role: "user", content: placeholderPrompts.example2input },
                                { role: "assistant", content: placeholderPrompts.example2output },
                                { role: "user", content: placeholderPrompts.example3input },
                                { role: "assistant", content: placeholderPrompts.example3output },
                                { role: "user", content: placeholderPrompts.example4input },
                                { role: "assistant", content: placeholderPrompts.example4output },
                                {
                                    role: "user",
                                    content: content
                                }
                            ], {
                                temperature: OpenAiTemperature,
                                maxTokens: MaxResponseTokenLimit
                            })];
                    case 1:
                        completion = _d.sent();
                        responseText = (_c = (_b = (_a = completion === null || completion === void 0 ? void 0 : completion.choices) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message) === null || _c === void 0 ? void 0 : _c.content;
                        resultData = responseText.split("+++");
                        if (!(resultData === null || resultData === void 0 ? void 0 : resultData.length) || resultData.length < 3) {
                            return [2 /*return*/, {
                                    suggestedPlaceholders: [],
                                    message: responseText,
                                    status: 404
                                }];
                        }
                        result = JSON.parse(resultData[1]);
                        message = resultData[2];
                        suggestedPlaceholders = result
                            .map(function (sp) {
                            var _a;
                            return ({
                                name: sp.name,
                                placeholder: (_a = request.placeholders.find(function (p) { return p.description === sp.description; })) === null || _a === void 0 ? void 0 : _a.placeholder,
                                reason: sp.reason,
                                description: sp.description,
                                confidence: sp.confidence
                            });
                        })
                            .filter(function (sp) { return sp.placeholder; });
                        suggestedPlaceholders.forEach(function (sp) {
                            sp.placeholder = JSON.parse(JSON.stringify(sp.placeholder));
                            sp.placeholder.title = sp.name;
                            delete sp.placeholder.defaultValue;
                        });
                        return [2 /*return*/, {
                                suggestedPlaceholders: suggestedPlaceholders,
                                message: message,
                                status: suggestedPlaceholders.length > 0 ? 200 : 404
                            }];
                    case 2:
                        error_1 = _d.sent();
                        return [2 /*return*/, {
                                suggestedPlaceholders: [],
                                message: "Error: ".concat(JSON.stringify(error_1)),
                                status: 500
                            }];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        _this.suggestFromRelationships = function (request) { return __awaiter(_this, void 0, void 0, function () {
            var content, client, completion, responseText, resultData, suggestedType, result, message, suggestedRelationships, error_2;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 2, , 3]);
                        content = PersonalizationAIAzureOAIService.createPromptWithRelationships(request);
                        client = this.getClient();
                        return [4 /*yield*/, client.getChatCompletions(OpenAiModelName, [
                                { role: "system", content: relationshipPrompts.promptBase },
                                { role: "user", content: relationshipPrompts.example1input },
                                { role: "assistant", content: relationshipPrompts.example1output },
                                { role: "user", content: relationshipPrompts.example2input },
                                { role: "assistant", content: relationshipPrompts.example2output },
                                { role: "user", content: relationshipPrompts.example3input },
                                { role: "assistant", content: relationshipPrompts.example3output },
                                { role: "user", content: relationshipPrompts.example4input },
                                { role: "assistant", content: relationshipPrompts.example4output },
                                { role: "user", content: relationshipPrompts.example5input },
                                { role: "assistant", content: relationshipPrompts.example5output },
                                { role: "user", content: relationshipPrompts.example6input },
                                { role: "assistant", content: relationshipPrompts.example6output },
                                {
                                    role: "user",
                                    content: content
                                }
                            ], {
                                temperature: OpenAiTemperature,
                                maxTokens: MaxResponseTokenLimit
                            })];
                    case 1:
                        completion = _d.sent();
                        responseText = (_c = (_b = (_a = completion === null || completion === void 0 ? void 0 : completion.choices) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message) === null || _c === void 0 ? void 0 : _c.content;
                        resultData = responseText.split("+++");
                        if (!(resultData === null || resultData === void 0 ? void 0 : resultData.length) || resultData.length < 3) {
                            return [2 /*return*/, {
                                    suggestedRelationships: [],
                                    suggestedType: "string",
                                    message: null,
                                    status: 404
                                }];
                        }
                        suggestedType = resultData[1].split(":")[1].trim();
                        result = JSON.parse(resultData[2]);
                        message = resultData[3];
                        suggestedRelationships = result
                            .map(function (sp) { return ({
                            reason: sp.reason,
                            description: sp.description,
                            confidence: sp.confidence
                        }); })
                            .filter(function (sp) { return sp.description; });
                        return [2 /*return*/, {
                                suggestedRelationships: suggestedRelationships,
                                suggestedType: suggestedType,
                                message: message,
                                status: suggestedRelationships.length > 0 ? 200 : 404
                            }];
                    case 2:
                        error_2 = _d.sent();
                        return [2 /*return*/, {
                                suggestedRelationships: [],
                                suggestedType: "string",
                                message: "Error: ".concat(JSON.stringify(error_2)),
                                status: 500
                            }];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        _this.getClient = function () {
            return new OpenAIClient(localStorage.getItem(ChatGptApiEndpoint), new AzureKeyCredential(localStorage.getItem(ChatGptApiKey)));
        };
        return _this;
    }
    PersonalizationAIAzureOAIService.prototype.serviceStart = function (appContext) {
        _super.prototype.serviceStart.call(this, appContext);
    };
    PersonalizationAIAzureOAIService.getTokensCount = function (textLength) {
        // https://help.openai.com/en/articles/4936856-what-are-tokens-and-how-to-count-them
        return Math.ceil(textLength / 4);
    };
    PersonalizationAIAzureOAIService.createPromptWithPlaceholders = function (_a) {
        var _b;
        var placeholders = _a.placeholders, count = _a.count, prompt = _a.prompt;
        var contentTemplate = "Here is the list of placeholders to choose from:\n{PLACEHOLDERS}\nPlease select up to ".concat(count, " placeholders that you think are relevant to this prompt:\n").concat(prompt, "\n\nOnly return suggestions as a full original row. If you are not sure, answer \"No suggestions found.\".");
        var tokensCount = PersonalizationAIAzureOAIService.getTokensCount(placeholderPrompts.promptBaseLength + contentTemplate.length);
        var placeholderDescriptions = "";
        for (var i = 0; i < placeholders.length && tokensCount < MaxRequestTokenLimit; i++) {
            var placeholder = placeholders[i];
            if (((_b = placeholder === null || placeholder === void 0 ? void 0 : placeholder.description) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                tokensCount += PersonalizationAIAzureOAIService.getTokensCount(placeholder.description.length);
                if (tokensCount < MaxRequestTokenLimit) {
                    placeholderDescriptions += "".concat(placeholder.description, "\n");
                }
            }
        }
        return contentTemplate.replace("{PLACEHOLDERS}", placeholderDescriptions);
    };
    PersonalizationAIAzureOAIService.createPromptWithRelationships = function (_a) {
        var _b, _c;
        var relationships = _a.relationships, events = _a.events, count = _a.count, prompt = _a.prompt;
        var contentTemplate = "Here is the list of entity relationships to choose from, you should only provide suggestions from this list:\n{RELATIONSHIPS}\n\n".concat(events.length ? "Trigger relationships:\n{EVENTS}\n\n" : "", "Please select up to ").concat(count, " relationships that you think are relevant to this prompt:\n").concat(prompt);
        var tokensCount = PersonalizationAIAzureOAIService.getTokensCount(relationshipPrompts.promptBaseLength + contentTemplate.length);
        var relationshipDescriptions = "";
        for (var i = 0; i < relationships.length && tokensCount < MaxRequestTokenLimit; i++) {
            var relationship = relationships[i];
            if (((_b = relationship === null || relationship === void 0 ? void 0 : relationship.description) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                tokensCount += PersonalizationAIAzureOAIService.getTokensCount(relationship.description.length);
                if (tokensCount < MaxRequestTokenLimit) {
                    relationshipDescriptions += "".concat(relationship.description, "\n");
                }
            }
        }
        var eventDescriptions = "";
        for (var i = 0; i < events.length && tokensCount < MaxRequestTokenLimit; i++) {
            var event_1 = events[i];
            if (((_c = event_1 === null || event_1 === void 0 ? void 0 : event_1.description) === null || _c === void 0 ? void 0 : _c.length) > 0) {
                tokensCount += PersonalizationAIAzureOAIService.getTokensCount(event_1.description.length);
                if (tokensCount < MaxRequestTokenLimit) {
                    eventDescriptions += "".concat(event_1.description, "\n");
                }
            }
        }
        return contentTemplate.replace("{RELATIONSHIPS}", relationshipDescriptions).replace("{EVENTS}", eventDescriptions);
    };
    return PersonalizationAIAzureOAIService;
}(Service));
export { PersonalizationAIAzureOAIService };
