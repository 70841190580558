var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Service } from "@cxp-shared/platform/Service";
import { CommonDataServiceName } from "@cxp-shared/services/CDS";
import { UserSettingsServiceName } from "../UserSettings";
var EntityPrivilegeCacheSoftTTL = 5 * 60 * 1000; // 5 min
var EntityPrivilegeCacheHardTTL = 24 * 3600 * 1000; // Day
var EntityPrivilegeCacheKey = "CxpPersonalization-EntityPrivilegeCache";
var EntityPrivilegeFilterService = /** @class */ (function (_super) {
    __extends(EntityPrivilegeFilterService, _super);
    function EntityPrivilegeFilterService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getEntityPrivilegeFilter = function () { return __awaiter(_this, void 0, void 0, function () {
            var loadData, allowedEntities_1, error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        loadData = function () { return __awaiter(_this, void 0, void 0, function () {
                            var userPrivilegesPromise, allEntitiesPromise, _a, userPrivileges, entities, allEntities, privilegeIds, allowedEntities;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        userPrivilegesPromise = this.cds.getUserPrivileges(this.userSettings.getUserId());
                                        allEntitiesPromise = this.cds.getRecords("EntityDefinitions", "$select=LogicalName,DisplayName,Privileges");
                                        return [4 /*yield*/, Promise.all([userPrivilegesPromise, allEntitiesPromise])];
                                    case 1:
                                        _a = __read.apply(void 0, [_b.sent(), 2]), userPrivileges = _a[0], entities = _a[1];
                                        allEntities = entities.filter(function (entity) { var _a, _b; return (_b = (_a = entity.DisplayName) === null || _a === void 0 ? void 0 : _a.UserLocalizedLabel) === null || _b === void 0 ? void 0 : _b.Label; });
                                        privilegeIds = new Set(userPrivileges.map(function (p) { return p.PrivilegeId; }));
                                        allowedEntities = new Set(allEntities
                                            .filter(function (entity) { var _a; return (_a = entity.Privileges) === null || _a === void 0 ? void 0 : _a.find(function (p) { return p.PrivilegeType === "Read" && privilegeIds.has(p.PrivilegeId); }); })
                                            .map(function (entity) { return entity.LogicalName; }));
                                        this.saveToCache(Array.from(allowedEntities));
                                        return [2 /*return*/, allowedEntities];
                                }
                            });
                        }); };
                        return [4 /*yield*/, this.getFromCacheOrLoad(loadData)];
                    case 1:
                        allowedEntities_1 = _a.sent();
                        return [2 /*return*/, function (entityLogicalName) { return allowedEntities_1.has(entityLogicalName); }];
                    case 2:
                        error_1 = _a.sent();
                        // Do not crash here, show everything
                        return [2 /*return*/, function () { return true; }];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        // ToDo: migrate this to marketing indexDB cache
        _this.getFromCacheOrLoad = function (loadData) { return __awaiter(_this, void 0, void 0, function () {
            var cacheEntry, cacheItem, now, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 6]);
                        cacheEntry = window.localStorage.getItem(EntityPrivilegeCacheKey);
                        if (!!cacheEntry) return [3 /*break*/, 2];
                        return [4 /*yield*/, loadData()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        cacheItem = JSON.parse(cacheEntry);
                        now = Date.now();
                        if (cacheItem.timestamp + EntityPrivilegeCacheSoftTTL > now) {
                            return [2 /*return*/, new Set(cacheItem.allowedEntities)];
                        }
                        // Old, refresh it in backround and return from cache
                        if (cacheItem.timestamp + EntityPrivilegeCacheHardTTL > now) {
                            loadData();
                            return [2 /*return*/, new Set(cacheItem.allowedEntities)];
                        }
                        return [4 /*yield*/, loadData()];
                    case 3: 
                    // Too old, await loading
                    return [2 /*return*/, _a.sent()];
                    case 4:
                        error_2 = _a.sent();
                        return [4 /*yield*/, loadData()];
                    case 5: 
                    // Something went wrong try to load data
                    return [2 /*return*/, _a.sent()];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        _this.saveToCache = function (allowedEntities) {
            try {
                window.localStorage.setItem(EntityPrivilegeCacheKey, JSON.stringify({
                    allowedEntities: allowedEntities,
                    timestamp: Date.now()
                }));
            }
            catch (error) {
                // nothing to do
            }
        };
        return _this;
    }
    EntityPrivilegeFilterService.prototype.serviceStart = function (appContext) {
        try {
            this.cds = appContext.getService(CommonDataServiceName);
            this.userSettings = appContext.getService(UserSettingsServiceName);
        }
        catch (error) {
            // Nothing to do, for the LSAC thing I need to make sure if we forgot to propagate this somewhere it does not crash
        }
    };
    return EntityPrivilegeFilterService;
}(Service));
export { EntityPrivilegeFilterService };
