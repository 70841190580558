import { HtmlAttributes as CommonHtmlAttributes } from "../../../Common/Constants/HtmlAttributes";
import { HtmlAttributes } from "./Constants/HtmlAttributes";
import { createFormLoaderFactory } from "./CreateFormLoaderFactory";
import { FormUtility } from "./FormUtility";
export function createForm(formId, formApiBaseUrl, formUrl, extensions, formLoaderFactory) {
    var placeholder = document.createElement("div");
    if (extensions) {
        for (var key in extensions) {
            var value = extensions[key];
            placeholder.setAttribute(key, value);
        }
    }
    placeholder.setAttribute(CommonHtmlAttributes.unboundForm, "true");
    placeholder.setAttribute(HtmlAttributes.formId, formId);
    placeholder.setAttribute(HtmlAttributes.formApiBaseUrl, formApiBaseUrl);
    placeholder.setAttribute(HtmlAttributes.formCachedUrl, formUrl);
    formLoaderFactory = formLoaderFactory !== null && formLoaderFactory !== void 0 ? formLoaderFactory : createFormLoaderFactory();
    var form = FormUtility.createFormDescriptorFromPlaceholderElement(placeholder);
    var loader = formLoaderFactory();
    loader.loadForm(form);
    return placeholder;
}
