var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CommonUtils } from "@contenteditor-shared/content-editor-common/src/Utils/CommonUtils";
import { clearEmailLiveEditabilityCache } from "@cxp-shared/personalization-core";
import { RecordPage } from "@cxp-shared/ribbon-common";
import { normalizeGuid } from "@cxp-shared/string-utilities/normalize-guid";
import { CommonAttributes, LifecycleDialog, LocalizationKeys } from "./Common/Constants";
import { dismissDependencyPageNotification, getDependencyEntityFetchXml, getJourneyDependencies } from "./LifecycleActions";
import { getDependencyFormNotification } from "./Utils/DependentEntityUtils";
var LifecycleRibbon = /** @class */ (function () {
    function LifecycleRibbon(ribbonLabels, entityDescriptor) {
        this.ribbonLabels = ribbonLabels;
        this.entityDescriptor = entityDescriptor;
    }
    LifecycleRibbon.prototype.editRecord = function (entity) {
        var _this = this;
        var status = entity.attributes.get(CommonAttributes.status);
        var xrm = window.Xrm;
        var entityName = entity.getEntityName();
        var entityId = normalizeGuid(entity.getId());
        if (status == null) {
            throw Error("Status Reason not available");
        }
        xrm.Utility.showProgressIndicator("");
        getJourneyDependencies(entityName, entityId).then(function (result) {
            var _a;
            Xrm.Utility.closeProgressIndicator();
            if (((_a = result === null || result === void 0 ? void 0 : result.liveJourneys) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                xrm.Navigation.openDialog(LifecycleDialog.DialogName, { width: 500, height: 500 }, {
                    dependent_entitylist: JSON.stringify(__spreadArray(__spreadArray([], __read(result.liveJourneys), false), __read(result.nonLiveJourneys), false)),
                    dialog_mode: LifecycleDialog.SelectMode,
                    entity_count: result.liveJourneys.length + result.nonLiveJourneys.length,
                    entity_fetchxml: JSON.stringify(getDependencyEntityFetchXml(entityName, entityId))
                }).then(function (dialogResult) {
                    switch (dialogResult.parameters[LifecycleDialog.DialogFormAttributes.dialog_result]) {
                        case LifecycleDialog.DialogResults.continue:
                            status.setValue(_this.entityDescriptor.liveEditingStatusCode);
                            clearEmailLiveEditabilityCache();
                            CommonUtils.getTopWindow().dispatchEvent(getDependencyFormNotification(entityName, entityId, result, LocalizationKeys.LiveJourneyNotificationTextWarning));
                            status.fireOnChange();
                            break;
                        case LifecycleDialog.DialogResults.copy:
                            xrm.Navigation.openForm({ entityName: entityName }, RecordPage.getSaveAsParameters(entityName, xrm.Page));
                            break;
                    }
                });
            }
            else {
                status.setValue(_this.entityDescriptor.draftStatusCode);
                status.fireOnChange();
                entity.save();
            }
        });
    };
    LifecycleRibbon.prototype.finishEditingRecord = function (entity) {
        var status = entity.attributes.get(CommonAttributes.status);
        var xrm = window.Xrm;
        if (status == null) {
            throw Error("Status Reason not available");
        }
        if (status.getValue() === this.entityDescriptor.liveEditingStatusCode) {
            xrm.Page.data.save();
        }
    };
    LifecycleRibbon.prototype.discardEditChanges = function () {
        var xrm = window.Xrm;
        xrm.Page.data.refresh(false).then(function () {
            var entity = xrm.Page.data.entity;
            if (!entity.getIsDirty()) {
                var entityFormOptions = {
                    entityName: entity.getEntityName(),
                    entityId: entity.getId()
                };
                xrm.Navigation.openForm(entityFormOptions);
            }
            dismissDependencyPageNotification();
        });
    };
    LifecycleRibbon.prototype.canEdit = function (entity) {
        var status = entity.attributes.get(CommonAttributes.status);
        return status != null && status.getValue() === this.entityDescriptor.readyToSendStatusCode;
    };
    LifecycleRibbon.prototype.canFinishEditing = function (entity) {
        var status = entity.attributes.get(CommonAttributes.status);
        return status != null && status.getValue() === this.entityDescriptor.liveEditingStatusCode;
    };
    LifecycleRibbon.prototype.canDiscardChanges = function (entity) {
        var status = entity.attributes.get(CommonAttributes.status);
        return status != null && status.getValue() === this.entityDescriptor.liveEditingStatusCode;
    };
    return LifecycleRibbon;
}());
export { LifecycleRibbon };
