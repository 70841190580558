import { looksLikeGuid } from "@cxp-shared/string-utilities/normalize-guid";
import { DataSources } from "../../Constants/DataSources";
import { PlaceholderUtils } from "../../Utilities/PlaceholderUtils";
export var isStaticBinding = function (binding) {
    if (!binding) {
        return false;
    }
    var rootBinding = PlaceholderUtils.getRootBinding(binding);
    if (rootBinding.source !== DataSources.DataverseDataSource) {
        return false;
    }
    return true;
};
export var isStaticBindingValid = function (binding) {
    var _a;
    var rootBinding = PlaceholderUtils.getRootBinding(binding);
    return !!(rootBinding === null || rootBinding === void 0 ? void 0 : rootBinding.inputs) && !!((_a = rootBinding.inputs["recordId"]) === null || _a === void 0 ? void 0 : _a.value) && looksLikeGuid(rootBinding.inputs["recordId"].value);
};
