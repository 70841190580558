var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BusinessMetricsEventStatus, TelemetryEvents } from "./IBusinessTelemetryService";
var BusinessTelemetryLogger = /** @class */ (function () {
    function BusinessTelemetryLogger(app, subApp, telemetryService, area) {
        this.telemetryService = telemetryService;
        this.version = "1";
        this.app = app;
        this.subApp = subApp;
        this.area = area;
    }
    BusinessTelemetryLogger.prototype.emitFunnelEvent = function (props) {
        var payload = __assign({ app: this.app, eventStatus: props.eventStatus, eventType: props.eventType, eventTypeDescription: props.eventTypeDescription, eventValue: props.eventValue, featureName: props.featureName, subApp: this.subApp, userId: props.userId, version: this.version, area: this.area }, props.additionalInfo);
        this.telemetryService.logFunctionalEvent(TelemetryEvents.FEATURE_USAGE_EVENTS, __assign({}, payload));
    };
    BusinessTelemetryLogger.prototype.emitFunnelDiscover = function (featureName, userId, eventStatus, additionalInfo) {
        if (eventStatus === void 0) { eventStatus = BusinessMetricsEventStatus.Success; }
        if (additionalInfo === void 0) { additionalInfo = {}; }
        this.emitFunnelEvent({
            featureName: featureName,
            userId: userId,
            eventStatus: eventStatus,
            eventValue: 1,
            eventType: "Funnel",
            eventTypeDescription: "Discover",
            additionalInfo: additionalInfo
        });
    };
    BusinessTelemetryLogger.prototype.emitFunnelInitiate = function (featureName, userId, eventStatus, additionalInfo) {
        if (eventStatus === void 0) { eventStatus = BusinessMetricsEventStatus.Success; }
        if (additionalInfo === void 0) { additionalInfo = {}; }
        this.emitFunnelEvent({
            featureName: featureName,
            userId: userId,
            eventStatus: eventStatus,
            eventValue: 2,
            eventType: "Funnel",
            eventTypeDescription: "Initiate",
            additionalInfo: additionalInfo
        });
    };
    BusinessTelemetryLogger.prototype.emitFunnelEngaged = function (featureName, userId, eventStatus, additionalInfo) {
        if (eventStatus === void 0) { eventStatus = BusinessMetricsEventStatus.Success; }
        if (additionalInfo === void 0) { additionalInfo = {}; }
        this.emitFunnelEvent({
            featureName: featureName,
            userId: userId,
            eventStatus: eventStatus,
            eventValue: 3,
            eventType: "Funnel",
            eventTypeDescription: "Engaged",
            additionalInfo: additionalInfo
        });
    };
    BusinessTelemetryLogger.prototype.emitFunnelViewResults = function (featureName, userId, eventStatus, additionalInfo) {
        if (eventStatus === void 0) { eventStatus = BusinessMetricsEventStatus.Success; }
        if (additionalInfo === void 0) { additionalInfo = {}; }
        this.emitFunnelEvent({
            featureName: featureName,
            userId: userId,
            eventStatus: eventStatus,
            eventValue: 4,
            eventType: "Funnel",
            eventTypeDescription: "View Results",
            additionalInfo: additionalInfo
        });
    };
    BusinessTelemetryLogger.prototype.emitFunnelAction = function (featureName, userId, eventStatus, additionalInfo) {
        if (eventStatus === void 0) { eventStatus = BusinessMetricsEventStatus.Success; }
        if (additionalInfo === void 0) { additionalInfo = {}; }
        this.emitFunnelEvent({
            featureName: featureName,
            userId: userId,
            eventStatus: eventStatus,
            eventValue: 5,
            eventType: "Funnel",
            eventTypeDescription: "Act",
            additionalInfo: additionalInfo
        });
    };
    BusinessTelemetryLogger.prototype.emitFunnelActivate = function (featureName, userId, eventStatus, additionalInfo) {
        if (eventStatus === void 0) { eventStatus = BusinessMetricsEventStatus.Success; }
        if (additionalInfo === void 0) { additionalInfo = {}; }
        this.emitFunnelEvent({
            featureName: featureName,
            userId: userId,
            eventStatus: eventStatus,
            eventValue: 6,
            eventType: "Funnel",
            eventTypeDescription: "Activate",
            additionalInfo: additionalInfo
        });
    };
    BusinessTelemetryLogger.prototype.emitSAT = function (featureName, userId, eventValue, additionalInfo) {
        if (additionalInfo === void 0) { additionalInfo = {}; }
        this.emitFunnelEvent({
            featureName: featureName,
            userId: userId,
            eventStatus: BusinessMetricsEventStatus.Success,
            eventValue: eventValue,
            eventType: "SAT",
            eventTypeDescription: "SAT",
            additionalInfo: additionalInfo
        });
    };
    return BusinessTelemetryLogger;
}());
export { BusinessTelemetryLogger };
