var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Service } from "@cxp-shared/platform/Service";
import { TelemetryServiceName } from "../Telemetry";
import { PerformanceScenario } from "./PerformanceScenario";
var PerformanceService = /** @class */ (function (_super) {
    __extends(PerformanceService, _super);
    function PerformanceService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.activeScenarios = {};
        _this.handleScenarioComplete = function (scenario, includeInFunctionalLogs) {
            if (scenario === _this.activeScenarios[scenario.scenarioName]) {
                delete _this.activeScenarios[scenario.scenarioName];
            }
            var telemetryService = _this.appContext.getService(TelemetryServiceName);
            var telemetryData = __assign({ backgroundTab: document.hidden ? 1 : 0, scenario: scenario.scenarioName, duration: scenario.duration, startTime: scenario.startTime, endTime: scenario.endTime, splitTimings: JSON.stringify(scenario.splitTimings) }, scenario.properties);
            var isError = !!telemetryData["errorMessage"];
            if (includeInFunctionalLogs) {
                telemetryService.logFunctionalEvent("Performance - ".concat(scenario.scenarioName), __assign(__assign({}, telemetryData), { isError: isError }));
            }
            else if (isError) {
                telemetryService.logError("Performance", __assign(__assign({}, telemetryData), { isError: true }));
            }
            else {
                telemetryService.logInfo("Performance", __assign(__assign({}, telemetryData), { isError: false }));
            }
        };
        return _this;
    }
    PerformanceService.prototype.startScenario = function (scenarioName, properties, includeInFunctionalLogs) {
        var _this = this;
        if (!this.activeScenarios[scenarioName]) {
            this.activeScenarios[scenarioName] = new PerformanceScenario(scenarioName, properties, function (scenario) { return _this.handleScenarioComplete(scenario, includeInFunctionalLogs); });
        }
        return this.activeScenarios[scenarioName];
    };
    PerformanceService.prototype.endScenario = function (scenarioName, properties) {
        var scenario = this.activeScenarios[scenarioName];
        if (scenario) {
            scenario.complete(properties);
        }
        return scenario;
    };
    PerformanceService.prototype.abortScenario = function (scenarioName) {
        delete this.activeScenarios[scenarioName];
    };
    PerformanceService.prototype.getActiveScenario = function (scenarioName) {
        return this.activeScenarios[scenarioName];
    };
    return PerformanceService;
}(Service));
export { PerformanceService };
