export var EnvironmentConfigurationServiceName = "EnvironmentConfigurationService";
export var CachedEnvironmentConfigurationServiceName = "CachedEnvironmentConfigurationService";
export var CIStatus;
(function (CIStatus) {
    CIStatus[CIStatus["NotConfigured"] = 534121000] = "NotConfigured";
    CIStatus[CIStatus["Configured"] = 534121001] = "Configured";
    CIStatus[CIStatus["FailedToConfigure"] = 534121002] = "FailedToConfigure";
    CIStatus[CIStatus["Removed"] = 534121003] = "Removed";
    CIStatus[CIStatus["FailedToRemove"] = 534121004] = "FailedToRemove";
})(CIStatus || (CIStatus = {}));
