var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Service } from "@cxp-shared/platform/Service";
import { getXrm } from "../CDS/Utils";
var NotificationType;
(function (NotificationType) {
    NotificationType[NotificationType["Toast"] = 1] = "Toast";
    NotificationType[NotificationType["Notify"] = 2] = "Notify";
})(NotificationType || (NotificationType = {}));
var NotificationService = /** @class */ (function (_super) {
    __extends(NotificationService, _super);
    function NotificationService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NotificationService.prototype.showToast = function (level, message, action, title, onCloseHandler) {
        var xrm = getXrm();
        if (xrm && xrm.UI) {
            return xrm.UI.addGlobalNotification({
                type: NotificationType.Toast,
                level: level,
                message: message,
                action: action,
                title: title,
                showCloseButton: true,
                onCloseHandler: onCloseHandler
            });
        }
        return Promise.reject("Xrm.UI is not defined");
    };
    NotificationService.prototype.showNotification = function (level, message, title, action, showClose) {
        if (showClose === void 0) { showClose = true; }
        var xrm = getXrm();
        if (xrm && xrm.UI) {
            return xrm.UI.addGlobalNotification({
                type: NotificationType.Notify,
                level: level,
                message: message,
                title: title,
                action: action,
                showCloseButton: showClose
            });
        }
        return Promise.reject("Xrm.UI is not defined");
    };
    NotificationService.prototype.clearNotification = function (id) {
        var xrm = getXrm();
        if (xrm && xrm.UI) {
            xrm.UI.clearGlobalNotification(id);
        }
    };
    return NotificationService;
}(Service));
export { NotificationService };
