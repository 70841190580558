var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * This class holds singleton instances of all registered services.
 * An instance of this class should be passed via the root component as React context,
 * so that all components inside its tree gets access to services via the context
 */
var AppContext = /** @class */ (function () {
    function AppContext() {
        this.serviceInstances = {};
    }
    AppContext.prototype.addService = function (serviceName, serviceClass) {
        var _a;
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        var registeredService = this.serviceInstances[serviceName];
        if (!registeredService) {
            registeredService = new (serviceClass.bind.apply(serviceClass, __spreadArray([void 0], __read(args), false)))();
            (_a = registeredService.serviceStart) === null || _a === void 0 ? void 0 : _a.call(registeredService, this);
            this.serviceInstances[serviceName] = registeredService;
        }
        return registeredService;
    };
    AppContext.prototype.getService = function (serviceName, ignoreErrorWhenNotFound) {
        var service = this.serviceInstances[serviceName];
        if (!service) {
            if (ignoreErrorWhenNotFound) {
                return undefined;
            }
            throw new Error("Service '".concat(serviceName, "' has not been registered"));
        }
        return service;
    };
    return AppContext;
}());
export { AppContext };
