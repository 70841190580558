import { PlaceholderFormat } from "@cxp-shared/contracts";
import { SourceTypes } from "@cxp-shared/metadata";
import { DataSources } from "../Constants/DataSources";
import { extractDataverseRootSourceType } from "../TargetAudience/DataSources/DataverseTargetExtractor";
export var extractType = function (type, nullable) {
    var format = null;
    if (type == null) {
        return { type: ["null", "string"], format: format };
    }
    var result = [];
    if (!!nullable) {
        result.push("null");
    }
    switch (type) {
        case "integer":
        case "decimal":
        case "bigInt":
        case "money":
        case "double":
            result.push("number");
            break;
        case "boolean":
            result.push("boolean");
            break;
        case "status":
        case "state":
        case "picklist":
        case "multiSelectPicklist":
            result.push("string");
            break;
        case "dateTime":
            result.push("string");
            format = PlaceholderFormat.DateTime;
            break;
        case "lookup":
            result.push("object");
            break;
        case "string":
            result.push("string");
            break;
        default:
            return { type: ["null", "string"], format: format };
    }
    return { type: result, format: format };
};
export var extractDataTypes = function (type, format, entityList) {
    if (format === PlaceholderFormat.DateTime) {
        return ["dateTime"];
    }
    if (type === null || type === void 0 ? void 0 : type.some(function (k) { return k === "number"; })) {
        return ["integer", "decimal", "bigInt", "money", "double"];
    }
    if (type === null || type === void 0 ? void 0 : type.some(function (k) { return k === "boolean"; })) {
        return ["boolean"];
    }
    if (type === null || type === void 0 ? void 0 : type.some(function (k) { return k === "object"; })) {
        return [
            {
                dataType: "lookup",
                entityList: entityList
            }
        ];
    }
    return ["string", "uniqueidentifier", "memo"];
};
export var extractSourceTypeFromBindingItem = function (bindingItem, productSource, isStaticBinding) {
    switch (bindingItem.source) {
        case DataSources.DataverseDataSource:
        case DataSources.CdsDataSource:
        case DataSources.BrandDataSource:
            return extractDataverseRootSourceType(bindingItem.sourceType, productSource, isStaticBinding);
        case DataSources.SalesDataSource:
            return DataSources.SalesDataSource;
        case DataSources.JourneyDataSource:
            return DataSources.JourneyDataSource;
        case DataSources.EventRegistrationFormDataSource:
            return DataSources.EventRegistrationFormDataSource;
        case DataSources.EventDataSource:
            return DataSources.EventDataSource;
        case DataSources.LegalDataSource:
            return DataSources.LegalDataSource;
        default:
            return bindingItem.source;
    }
};
export var extractSourceType = function (metadataItem, productSource, isRoot, isStaticBinding) {
    if (isRoot) {
        return extractRootSourceType(metadataItem.sourceType, metadataItem.sourceLogicalName, productSource, isStaticBinding);
    }
    switch (metadataItem.sourceType) {
        case SourceTypes.DataverseTable:
            return DataSources.DataverseDataSource;
        case SourceTypes.CjoCustomEvent:
        case SourceTypes.CjoBusinessEvent:
        case SourceTypes.CjoInteractionEvent:
            return DataSources.EventDataSource;
        case SourceTypes.Legal:
            return DataSources.LegalDataSource;
        case SourceTypes.Unspecified:
            return "unknown";
    }
};
var extractRootSourceType = function (sourceType, sourceLogicalName, productSource, isStaticBinding) {
    switch (sourceType) {
        case SourceTypes.DataverseTable:
            return extractDataverseRootSourceType(sourceLogicalName, productSource, isStaticBinding);
        case SourceTypes.CjoCustomEvent:
        case SourceTypes.CjoBusinessEvent:
        case SourceTypes.CjoInteractionEvent:
            return DataSources.EventDataSource;
        case SourceTypes.Legal:
            return DataSources.LegalDataSource;
    }
};
