function generatePredefinedPlaceholder(params) {
    var _a, _b, _c;
    var placeholderBindings = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        placeholderBindings[_i - 1] = arguments[_i];
    }
    return {
        id: (_a = params.id) !== null && _a !== void 0 ? _a : this.generateUniqSerial(),
        label: params.label,
        namespace: params.namespace,
        useCount: 0,
        usedOn: null,
        builtIn: false,
        category: (_b = params.category) !== null && _b !== void 0 ? _b : "general",
        description: params.description,
        priority: (_c = params.priority) !== null && _c !== void 0 ? _c : 1,
        placeholderdescriptor: {
            alternateBindings: placeholderBindings
        }
    };
}
function generateUniqSerial() {
    return "xxxx-xxxx-xxx-xxxx".replace(/[x]/g, function (c) {
        var r = Math.floor(Math.random() * 16);
        return r.toString(16);
    });
}
export var PredefinedPlaceholderGenerator = {
    generatePredefinedPlaceholder: generatePredefinedPlaceholder
};
