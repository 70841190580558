export var CustomerProfileLogicalName = "msdynci_customerprofile";
export var ContactLogicalName = "contact";
export var LeadLogicalName = "lead";
export var VirtualSegmentEntityConstants;
(function (VirtualSegmentEntityConstants) {
    VirtualSegmentEntityConstants.EntityName = "msdynmkt_virtualsegment";
    VirtualSegmentEntityConstants.EntityPluralName = "msdynmkt_virtualsegments";
    VirtualSegmentEntityConstants.LookupView = "{d3a64deb-241b-489d-8ae0-ac1eefc256f5}";
    VirtualSegmentEntityConstants.NameField = "msdynmkt_name";
    VirtualSegmentEntityConstants.IdField = "msdynmkt_virtualsegmentid";
    VirtualSegmentEntityConstants.StateField = "msdynmkt_statecode";
    VirtualSegmentEntityConstants.BaseEntityField = "msdynmkt_baseentity";
    VirtualSegmentEntityConstants.RequiresExport = "msdynmkt_requiresexport";
    VirtualSegmentEntityConstants.StatusCode = "msdynmkt_statuscode";
    VirtualSegmentEntityConstants.CreatedDate = "msdynmkt_createddate";
    VirtualSegmentEntityConstants.SourceEntityField = "msdynmkt_sourceentity";
    VirtualSegmentEntityConstants.SourceEntityIdField = "msdynmkt_sourceentityid";
    VirtualSegmentEntityConstants.SourceField = "msdynmkt_source";
    VirtualSegmentEntityConstants.SourceUriField = "msdynmkt_sourceuri";
    VirtualSegmentEntityConstants.MemberCount = "msdynmkt_membercount";
    VirtualSegmentEntityConstants.OwningBusinessUnit = "_msdynmkt_owningbusinessunit_value";
})(VirtualSegmentEntityConstants || (VirtualSegmentEntityConstants = {}));
export var SegmentStates;
(function (SegmentStates) {
    SegmentStates[SegmentStates["Active"] = 0] = "Active";
    SegmentStates[SegmentStates["Inactive"] = 1] = "Inactive";
})(SegmentStates || (SegmentStates = {}));
export var SegmentStatus;
(function (SegmentStatus) {
    SegmentStatus[SegmentStatus["Published"] = 0] = "Published";
    SegmentStatus[SegmentStatus["Draft"] = 1] = "Draft";
    SegmentStatus[SegmentStatus["Deactivated"] = 2] = "Deactivated";
})(SegmentStatus || (SegmentStatus = {}));
export var SegmentSource;
(function (SegmentSource) {
    SegmentSource[SegmentSource["CustomerInsightsData"] = 0] = "CustomerInsightsData";
    SegmentSource[SegmentSource["Dynamics365MarketingOutbound"] = 1] = "Dynamics365MarketingOutbound";
    SegmentSource[SegmentSource["CustomerInsightsJourneys"] = 2] = "CustomerInsightsJourneys";
})(SegmentSource || (SegmentSource = {}));
