import { PlaceholderFormat, TransformationType } from "@cxp-shared/contracts";
import { DataSources } from "./Constants/DataSources";
var generateBinding = function (_a) {
    var entityProviderName = _a.entityProviderName, inputBindings = _a.inputBindings, entityAttributeLogicalName = _a.entityAttributeLogicalName, outputType = _a.outputType, outputFormat = _a.outputFormat;
    return {
        source: entityProviderName || null,
        inputs: inputBindings,
        outputPath: entityAttributeLogicalName || null,
        outputType: outputType || null,
        outputFormat: outputFormat || undefined
    };
};
var generateBindingInputParameter = function (_a) {
    var inputParameterValue = _a.inputParameterValue, inputParameterBinding = _a.inputParameterBinding;
    var result = {
        value: inputParameterValue || null
    };
    if (inputParameterBinding != null) {
        result.binding = inputParameterBinding;
    }
    return result;
};
var generatePlaceholder = function (_a) {
    var title = _a.title, _b = _a.placeholderType, placeholderType = _b === void 0 ? ["null", "string"] : _b, defaultValue = _a.defaultValue, entityProviderName = _a.entityProviderName, entityLogicalName = _a.entityLogicalName, entityAttributeLogicalName = _a.entityAttributeLogicalName, placeholderCategory = _a.placeholderCategory, _c = _a.isInternal, isInternal = _c === void 0 ? false : _c, transformation = _a.transformation, items = _a.items, _d = _a.outputType, outputType = _d === void 0 ? "unspecified" : _d, format = _a.format, _e = _a.touched, touched = _e === void 0 ? true : _e;
    var bindingInputParameters = entityLogicalName
        ? {
            sourceType: generateBindingInputParameter({
                inputParameterValue: entityLogicalName,
                inputParameterBinding: null
            })
        }
        : null;
    return generatePlaceholderWithBindingInputParameters({
        title: title,
        placeholderType: placeholderType,
        defaultValue: defaultValue,
        entityProviderName: entityProviderName,
        entityAttributeLogicalName: entityAttributeLogicalName,
        bindingInputParameters: bindingInputParameters,
        placeholderCategory: placeholderCategory,
        isInternal: isInternal,
        outputType: outputType,
        transformation: transformation,
        items: items,
        format: format,
        touched: touched
    });
};
var generatePlaceholderWithBindingInputParameters = function (_a) {
    var title = _a.title, placeholderType = _a.placeholderType, defaultValue = _a.defaultValue, entityProviderName = _a.entityProviderName, entityAttributeLogicalName = _a.entityAttributeLogicalName, bindingInputParameters = _a.bindingInputParameters, placeholderCategory = _a.placeholderCategory, _b = _a.isInternal, isInternal = _b === void 0 ? false : _b, _c = _a.outputType, outputType = _c === void 0 ? "unspecified" : _c, outputFormat = _a.outputFormat, transformation = _a.transformation, items = _a.items, designerValue = _a.designerValue, predefinedPlaceholderId = _a.predefinedPlaceholderId, format = _a.format, _d = _a.touched, touched = _d === void 0 ? true : _d;
    var finalType = placeholderType;
    var finalOutputType = outputType;
    var finalOutputFormat = outputFormat;
    switch (placeholderCategory) {
        case "condition":
            finalType = ["null", "boolean"];
            break;
        case "list":
            finalType = ["array"];
            finalOutputType = "entityCollection";
            break;
        case "general":
            if (!finalOutputFormat && (outputType === "picklist" || outputType === "multiSelectPicklist")) {
                finalOutputFormat = "caption";
            }
            if (outputType === "picklist" && finalOutputFormat === "raw") {
                finalType = ["null", "number"];
            }
            break;
        default:
            break;
    }
    var placeholder = {
        title: title || null,
        defaultValue: defaultValue == null || defaultValue === "" ? null : defaultValue,
        type: finalType || null,
        category: placeholderCategory || null,
        isInternal: isInternal
    };
    if (format != null) {
        placeholder.format = format;
    }
    if (designerValue != null) {
        placeholder.designerValue = designerValue;
    }
    if (predefinedPlaceholderId != null) {
        placeholder.predefinedPlaceholderId = predefinedPlaceholderId;
    }
    if (transformation != null) {
        placeholder.transformation = transformation;
    }
    if (items != null) {
        placeholder.items = items;
    }
    if (entityProviderName || entityAttributeLogicalName || bindingInputParameters) {
        placeholder.binding = generateBinding({
            entityProviderName: entityProviderName,
            entityAttributeLogicalName: entityAttributeLogicalName,
            inputBindings: bindingInputParameters,
            outputType: finalOutputType,
            outputFormat: finalOutputFormat
        });
    }
    placeholder.touched = touched;
    return placeholder;
};
var generateEmptyPlaceholder = function (_a) {
    var title = _a.title, placeholderCategory = _a.placeholderCategory, type = _a.type, format = _a.format, entityLookupArgs = _a.entityLookupArgs;
    var placeholder = null;
    switch (placeholderCategory) {
        case "condition":
            placeholder = generatePlaceholder({
                title: title,
                placeholderType: ["null", "boolean"],
                defaultValue: "",
                entityProviderName: "",
                entityLogicalName: "",
                entityAttributeLogicalName: "",
                placeholderCategory: placeholderCategory,
                isInternal: false,
                transformation: {
                    expression: "",
                    type: TransformationType.PowerAutomateExpressionTemplate
                },
                outputType: "boolean",
                touched: false
            });
            break;
        case "list":
            placeholder = generatePlaceholder({
                title: title,
                placeholderType: ["array"],
                defaultValue: "",
                entityProviderName: "",
                entityLogicalName: "",
                entityAttributeLogicalName: "",
                placeholderCategory: placeholderCategory,
                isInternal: false,
                outputType: "picklist",
                items: [
                    {
                        type: ["object"],
                        properties: {}
                    }
                ],
                touched: false
            });
            break;
        default:
        case "general":
            placeholder = generatePlaceholder({
                title: title,
                placeholderType: type !== null && type !== void 0 ? type : ["null", "string"],
                defaultValue: entityLookupArgs !== null && entityLookupArgs !== void 0 ? entityLookupArgs : "",
                entityProviderName: "",
                entityLogicalName: "",
                entityAttributeLogicalName: "",
                placeholderCategory: "general",
                isInternal: false,
                outputType: (entityLookupArgs === null || entityLookupArgs === void 0 ? void 0 : entityLookupArgs.outputType) === "lookup" ? "lookup" : "string",
                format: format,
                touched: false
            });
            break;
    }
    return placeholder;
};
var generateConstantValuePlaceholder = function (constantValue, title, type, format, defaultValue) {
    var outputType = "string";
    if (format === PlaceholderFormat.DateTime) {
        outputType = "dateTime";
    }
    else if (type === null || type === void 0 ? void 0 : type.some(function (k) { return k === "number"; })) {
        outputType = "double";
    }
    else if (type === null || type === void 0 ? void 0 : type.some(function (k) { return k === "boolean"; })) {
        outputType = "boolean";
    }
    else if (type === null || type === void 0 ? void 0 : type.some(function (k) { return k === "object"; })) {
        outputType = "lookup";
    }
    return {
        type: type !== null && type !== void 0 ? type : ["null", "string"],
        format: format,
        title: title,
        touched: true,
        binding: {
            source: DataSources.Constant,
            inputs: {
                value: {
                    value: constantValue
                }
            },
            outputType: outputType
        },
        defaultValue: defaultValue || undefined
    };
};
var generateBrandingPlaceholder = function (title, type, defaultValue, outputPath, outputType, format) {
    return {
        type: type,
        format: format,
        title: title,
        touched: true,
        defaultValue: defaultValue,
        binding: {
            source: DataSources.BrandDataSource,
            inputs: {
                sourceType: {
                    value: "msdynmkt_brandprofile"
                }
            },
            outputType: outputType,
            outputPath: outputPath
        }
    };
};
var generateCompanyAddressPlaceholder = function (title) {
    return generatePlaceholder({
        title: title !== null && title !== void 0 ? title : "CompanyAddress",
        placeholderType: ["null", "string"],
        placeholderCategory: "general",
        entityProviderName: DataSources.LegalDataSource,
        entityLogicalName: "Default",
        entityAttributeLogicalName: "companyaddress"
    });
};
var generatePreferenceCenterPlaceholder = function (title) {
    return generatePlaceholder({
        title: title !== null && title !== void 0 ? title : "PreferenceCenter",
        placeholderType: ["null", "string"],
        placeholderCategory: "general",
        entityProviderName: DataSources.LegalDataSource,
        entityLogicalName: "Default",
        entityAttributeLogicalName: "preferencescenterurl"
    });
};
var generateDefaultEmailPlaceholders = function () {
    var _a;
    var legalAddressPlaceholder = generateCompanyAddressPlaceholder();
    var preferenceCenterPlaceholder = generatePreferenceCenterPlaceholder();
    return _a = {},
        _a[legalAddressPlaceholder.title] = legalAddressPlaceholder,
        _a[preferenceCenterPlaceholder.title] = preferenceCenterPlaceholder,
        _a;
};
export var PlaceholderGenerator = {
    generatePlaceholder: generatePlaceholder,
    generatePlaceholderWithBindingInputParameters: generatePlaceholderWithBindingInputParameters,
    generateEmptyPlaceholder: generateEmptyPlaceholder,
    generateCompanyAddressPlaceholder: generateCompanyAddressPlaceholder,
    generatePreferenceCenterPlaceholder: generatePreferenceCenterPlaceholder,
    generateDefaultEmailPlaceholders: generateDefaultEmailPlaceholders,
    generateBinding: generateBinding,
    generateBindingInputParameter: generateBindingInputParameter,
    generateConstantValuePlaceholder: generateConstantValuePlaceholder
};
