var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var AsyncCache = /** @class */ (function () {
    function AsyncCache(cache, logError) {
        var _this = this;
        this.cache = cache;
        this.logError = logError;
        this.promises = {};
        this.getMultiple = function (keyPrefix, itemKeys, action, allowStaleData) {
            if (allowStaleData === void 0) { allowStaleData = false; }
            return __awaiter(_this, void 0, void 0, function () {
                var cachedData, hasAllData, internalResult, itemsToLoad, data;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, Promise.all(itemKeys.map(function (key) { return _this.cache.getValue("".concat(keyPrefix, "_").concat(key), allowStaleData); }))];
                        case 1:
                            cachedData = _a.sent();
                            hasAllData = true;
                            internalResult = {};
                            itemsToLoad = [];
                            cachedData.forEach(function (item, idx) {
                                internalResult[itemKeys[idx]] = item;
                                if (item == null) {
                                    hasAllData = false;
                                    itemsToLoad.push(itemKeys[idx]);
                                }
                            });
                            if (hasAllData) {
                                return [2 /*return*/, cachedData];
                            }
                            return [4 /*yield*/, action(itemsToLoad)];
                        case 2:
                            data = _a.sent();
                            return [4 /*yield*/, Promise.all(data.map(function (item, idx) { return _this.cache.storeValue("".concat(keyPrefix, "_").concat(itemsToLoad[idx]), item); }))];
                        case 3:
                            _a.sent();
                            data.forEach(function (item, idx) { return (internalResult[itemsToLoad[idx]] = item); });
                            return [2 /*return*/, Object.values(internalResult)];
                    }
                });
            });
        };
        this.getCachedItems = function (keyPrefix, itemKeys, allowStaleData) {
            if (allowStaleData === void 0) { allowStaleData = false; }
            return __awaiter(_this, void 0, void 0, function () {
                var cachedData, internalResult;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, Promise.all(itemKeys.map(function (key) { return _this.cache.getValue("".concat(keyPrefix, "_").concat(key), allowStaleData); }))];
                        case 1:
                            cachedData = _a.sent();
                            internalResult = {};
                            cachedData.forEach(function (item, idx) {
                                internalResult[itemKeys[idx]] = {
                                    data: item,
                                    found: item != null,
                                    key: itemKeys[idx]
                                };
                            });
                            return [2 /*return*/, internalResult];
                    }
                });
            });
        };
        this.get = function (key, action, allowStaleData) {
            if (allowStaleData === void 0) { allowStaleData = false; }
            return __awaiter(_this, void 0, void 0, function () {
                var cachedData, error_1, cachedPromise, promise, data, error_2;
                var _a, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _c.trys.push([0, 6, , 7]);
                            _c.label = 1;
                        case 1:
                            _c.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, this.cache.getValue(key, allowStaleData)];
                        case 2:
                            cachedData = _c.sent();
                            if (cachedData) {
                                return [2 /*return*/, cachedData];
                            }
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _c.sent();
                            (_a = this.logError) === null || _a === void 0 ? void 0 : _a.call(this, error_1);
                            return [3 /*break*/, 4];
                        case 4:
                            cachedPromise = this.promises[key];
                            if (cachedPromise) {
                                return [2 /*return*/, cachedPromise];
                            }
                            promise = action();
                            this.promises[key] = promise;
                            return [4 /*yield*/, promise];
                        case 5:
                            data = _c.sent();
                            try {
                                this.cache.storeValue(key, data);
                            }
                            catch (error) {
                                (_b = this.logError) === null || _b === void 0 ? void 0 : _b.call(this, error);
                            }
                            this.promises[key] = null;
                            return [2 /*return*/, data];
                        case 6:
                            error_2 = _c.sent();
                            this.promises[key] = null;
                            throw error_2;
                        case 7: return [2 /*return*/];
                    }
                });
            });
        };
    }
    return AsyncCache;
}());
export { AsyncCache };
