import { getXrm } from "../CDS/Utils";
var XrmTelemetryProvider = /** @class */ (function () {
    function XrmTelemetryProvider(component) {
        var xrm = getXrm();
        if (xrm && xrm.Reporting) {
            this.xrmReportingApi = xrm.Reporting;
        }
        var hasBrackets = (component === null || component === void 0 ? void 0 : component.startsWith("[")) && (component === null || component === void 0 ? void 0 : component.endsWith("]"));
        this.component = hasBrackets ? component : "[".concat(component, "]");
    }
    XrmTelemetryProvider.prototype.cloneForComponent = function (component) {
        var newComponent = this.joinComponents(this.component, component);
        return new XrmTelemetryProvider(newComponent);
    };
    XrmTelemetryProvider.prototype.logInfo = function (logMessage, parameters) {
        if (this.xrmReportingApi) {
            var eventParameters = this.createParameters("message", logMessage, parameters);
            this.xrmReportingApi.reportSuccess(this.component, eventParameters);
        }
    };
    XrmTelemetryProvider.prototype.logError = function (logMessage, parameters) {
        if (this.xrmReportingApi) {
            var eventParameters = this.createParameters("message", logMessage, parameters);
            this.xrmReportingApi.reportFailure(this.component, new Error(logMessage), undefined, eventParameters);
        }
    };
    XrmTelemetryProvider.prototype.logFunctionalEvent = function (eventName, eventData) {
        if (this.xrmReportingApi) {
            var eventParameters = this.createParameters("functionalEventName", eventName, eventData);
            this.xrmReportingApi.reportSuccess(this.component, eventParameters);
        }
    };
    XrmTelemetryProvider.prototype.createParameters = function (messageKey, message, parameters) {
        var _a;
        var eventParameters = [{ name: messageKey, value: message }];
        if (process.env.NODE_ENV === "development") {
            eventParameters.push({ name: "isDevMode", value: 1 });
        }
        for (var key in parameters) {
            if (parameters[key]) {
                eventParameters.push({ name: key, value: (_a = parameters[key]) === null || _a === void 0 ? void 0 : _a.toString() });
            }
        }
        return eventParameters;
    };
    XrmTelemetryProvider.prototype.joinComponents = function (original, component) {
        if (original && component && original.toLocaleLowerCase().indexOf("[".concat(component.toLocaleLowerCase(), "]")) < 0) {
            return "".concat(original, " [").concat(component, "]");
        }
        return original || "[".concat(component, "]");
    };
    return XrmTelemetryProvider;
}());
export { XrmTelemetryProvider };
