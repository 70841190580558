import { events } from "../MetadataConstants";
var eventByNamesRequestPerfixLength = 200;
var singleEventQueryItemLength = 16; //&customApiNames=
var eventsMaxUrlLength = 8192;
var doubleOptInCatalogUniqueName = "<value>msdynmkt_realtimemarketingforms</value>";
var defaultCatalogUniqueNames = "<value>msdynmkt_custom</value>";
defaultCatalogUniqueNames += "<value>msdynmkt_business</value>";
defaultCatalogUniqueNames += "<value>msdynmkt_custombusiness</value>";
defaultCatalogUniqueNames += "<value>msdynmkt_marketingformsjourneystart</value>";
defaultCatalogUniqueNames += "<value>msevtmgt_marketingeventsjourneystart</value>";
defaultCatalogUniqueNames += "<value>msdynmkt_realtimemarketingformsjourneystart</value>";
defaultCatalogUniqueNames += "<value>msdynmkt_trackingjourneystart</value>";
export var buildEventNamesUrl = function (crmUrl, includeDraftEvents, isDoubleOptIn) {
    var fetchXml = "<fetch version=\"1.0\" output-format=\"xml-platform\" mapping=\"logical\" distinct=\"true\">";
    fetchXml += "<entity name=\"msdynmkt_eventmetadata\">";
    fetchXml += "<attribute name=\"msdynmkt_eventmetadataid\" />";
    fetchXml += "<attribute name=\"msdynmkt_name\" />";
    fetchXml += "<attribute name=\"msdynmkt_cataloguniquename\" />";
    fetchXml += "<attribute name=\"msdynmkt_customapiid\" />";
    fetchXml += "<attribute name=\"msdynmkt_supportedtargetentities\" />";
    fetchXml += "<attribute name=\"msdynmkt_supportedtargetentitiesdisplaynames\"/>";
    fetchXml += "<attribute name=\"msdynmkt_eventmetadatastatus\" />";
    fetchXml += "<attribute name=\"msdynmkt_state\" />";
    fetchXml += "<attribute name=\"msdynmkt_uniquename\" />";
    fetchXml += "<attribute name=\"msdynmkt_eventmetadatastatus\" />";
    fetchXml += "<attribute name=\"msdynmkt_eventmetadatastatus\" />";
    fetchXml += "<attribute name=\"owningbusinessunit\" />";
    fetchXml += "<order attribute=\"msdynmkt_name\" descending=\"false\" />";
    fetchXml += "<link-entity name=\"customapi\" from=\"customapiid\" to=\"msdynmkt_customapiid\" link-type=\"inner\" alias=\"customapi\">";
    fetchXml += "<link-entity name=\"catalogassignment\" from=\"object\" to=\"customapiid\" link-type=\"inner\" alias=\"catalogassignment\">";
    fetchXml += "<link-entity name=\"catalog\" from=\"catalogid\" to=\"catalogid\" link-type=\"inner\" alias=\"catalog\">";
    fetchXml += "<filter>";
    fetchXml += "<condition attribute=\"uniquename\" operator=\"in\">";
    fetchXml += isDoubleOptIn ? doubleOptInCatalogUniqueName : defaultCatalogUniqueNames;
    fetchXml += "</condition>";
    fetchXml += "</filter>";
    fetchXml += "</link-entity>";
    fetchXml += "</link-entity>";
    fetchXml += "</link-entity>";
    fetchXml += "<filter>";
    fetchXml += "<condition attribute=\"msdynmkt_eventmetadatastatus\" operator=\"in\">";
    fetchXml += "<value>534120001</value>";
    fetchXml += includeDraftEvents ? "<value>534120000</value>" : "";
    fetchXml += "</condition>";
    fetchXml += "</filter>";
    fetchXml += "</entity>";
    fetchXml += "</fetch>";
    return "".concat(crmUrl).concat(events, "?fetchXml=").concat(fetchXml);
};
export var batchEventNames = function (eventNames) {
    var retValue = [];
    var batchIndex = 0;
    var currentLength = eventByNamesRequestPerfixLength;
    retValue[batchIndex] = [];
    eventNames.forEach(function (name) {
        if (currentLength + singleEventQueryItemLength + name.length >= eventsMaxUrlLength) {
            currentLength = eventByNamesRequestPerfixLength;
            retValue[++batchIndex] = [name];
        }
        else {
            retValue[batchIndex].push(name);
        }
        currentLength += singleEventQueryItemLength + name.length;
    });
    return retValue;
};
