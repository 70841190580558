/// https://learn.microsoft.com/en-us/dotnet/api/microsoft.xrm.sdk.metadata.datetimebehavior?view=dataverse-sdk-latest#fields
export var DateTimeBehavior;
(function (DateTimeBehavior) {
    /// Stores the date and time value with current user local time zone information.
    DateTimeBehavior[DateTimeBehavior["UserLocal"] = 1] = "UserLocal";
    /// Stores the date value with the time value as 12:00 AM (00:00:00) without the time zone information.
    DateTimeBehavior[DateTimeBehavior["DateOnly"] = 2] = "DateOnly";
    /// Stores the date and time values without the time zone information.
    DateTimeBehavior[DateTimeBehavior["TimeZoneIndependent"] = 3] = "TimeZoneIndependent";
})(DateTimeBehavior || (DateTimeBehavior = {}));
