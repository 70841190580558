var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { deepClone, groupBy } from "./General";
import { PlaceholderUtils } from "./PlaceholderUtils";
import { DataSources } from "../Constants/DataSources";
import { PlaceholderAttributeFilter } from "./PlaceholderAttributeFilter";
export function getDisplayName(metadataItem) {
    var _a, _b, _c, _d;
    return (_d = (_b = (_a = metadataItem === null || metadataItem === void 0 ? void 0 : metadataItem.displayName) === null || _a === void 0 ? void 0 : _a.trim()) !== null && _b !== void 0 ? _b : (_c = metadataItem === null || metadataItem === void 0 ? void 0 : metadataItem.logicalName) === null || _c === void 0 ? void 0 : _c.trim()) !== null && _d !== void 0 ? _d : "UNKNOWN";
}
export function sortAndFixDisplayNames(attributes) {
    var e_1, _a;
    var sortByAttributesSorted = attributes.sort(function (a, b) { return PlaceholderUtils.compareInLocale(getDisplayName(a), getDisplayName(b)); });
    var sortByAttributesSortedClone = groupBy(deepClone(sortByAttributesSorted), function (metadataItem) { return metadataItem.displayName; });
    try {
        for (var sortByAttributesSorted_1 = __values(sortByAttributesSorted), sortByAttributesSorted_1_1 = sortByAttributesSorted_1.next(); !sortByAttributesSorted_1_1.done; sortByAttributesSorted_1_1 = sortByAttributesSorted_1.next()) {
            var attribute = sortByAttributesSorted_1_1.value;
            var displayNameHasDuplicates = sortByAttributesSortedClone[attribute.displayName].length > 1;
            if (displayNameHasDuplicates) {
                attribute.displayName = "".concat(attribute.displayName, " (").concat(attribute.logicalName, ")");
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (sortByAttributesSorted_1_1 && !sortByAttributesSorted_1_1.done && (_a = sortByAttributesSorted_1.return)) _a.call(sortByAttributesSorted_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
}
export var loadMetadata = function (targetEntitiesExtractor, metadataService, binding) { return __awaiter(void 0, void 0, void 0, function () {
    var bindings, selectedItems, audienceSources, eventSources, legalSources, brandSources, metadataTasks, _a, dataverseSourceMetadata, eventSourceMetadata, legalSourceMetadata, brandSourceMetadata;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!binding) {
                    return [2 /*return*/, []];
                }
                bindings = Array.isArray(binding) ? binding : [binding];
                selectedItems = bindings.flatMap(function (i) { return targetEntitiesExtractor.getBindingItems(i) || []; });
                audienceSources = selectedItems.filter(function (i) {
                    return i.source === DataSources.CdsDataSource ||
                        i.source === DataSources.DataverseDataSource ||
                        i.source === DataSources.SalesDataSource ||
                        i.source === DataSources.JourneyDataSource ||
                        i.source === DataSources.EventRegistrationFormDataSource;
                });
                eventSources = selectedItems.filter(function (i) { return i.source === DataSources.EventDataSource; });
                legalSources = selectedItems.filter(function (i) { return i.source === DataSources.LegalDataSource; });
                brandSources = selectedItems.filter(function (i) { return i.source === DataSources.BrandDataSource; });
                metadataTasks = [];
                audienceSources.length > 0
                    ? metadataTasks.push(loadEntities(metadataService, audienceSources.map(function (a) { return a.sourceType; })))
                    : metadataTasks.push(Promise.resolve([]));
                eventSources.length > 0
                    ? metadataTasks.push(loadEvents(metadataService, eventSources.map(function (e) { return e.sourceType; })))
                    : metadataTasks.push(Promise.resolve([]));
                legalSources.length > 0 ? metadataTasks.push(loadLegal(metadataService)) : metadataTasks.push(Promise.resolve([]));
                brandSources.length > 0 ? metadataTasks.push(loadBrand(metadataService)) : metadataTasks.push(Promise.resolve([]));
                return [4 /*yield*/, Promise.all(metadataTasks)];
            case 1:
                _a = __read.apply(void 0, [_b.sent(), 4]), dataverseSourceMetadata = _a[0], eventSourceMetadata = _a[1], legalSourceMetadata = _a[2], brandSourceMetadata = _a[3];
                return [2 /*return*/, __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(dataverseSourceMetadata), false), __read(eventSourceMetadata), false), __read(legalSourceMetadata), false), __read(brandSourceMetadata), false)];
        }
    });
}); };
export var loadMetadataForConditionExpression = function (targetEntitiesExtractor, metadataService, expression, fullData) {
    if (fullData === void 0) { fullData = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var bindings, metadata, segmentsToLoad, segments, _a, groupedMetadata;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    bindings = fullData
                        ? targetEntitiesExtractor.extractBindingFromConditionExpression(expression)
                        : targetEntitiesExtractor.extractBindingFromConditionExpression(expression).filter(function (b, i) { return i === 0; });
                    return [4 /*yield*/, loadMetadata(targetEntitiesExtractor, metadataService, bindings)];
                case 1:
                    metadata = _b.sent();
                    segmentsToLoad = bindings.filter(function (b) { return b.source === DataSources.SegmentMembershipCheckDataSource; }).map(function (b) { return b.inputs.segmentId.value; });
                    if (!(segmentsToLoad.length > 0)) return [3 /*break*/, 3];
                    return [4 /*yield*/, metadataService.getSpecificSegments(segmentsToLoad, true)];
                case 2:
                    _a = _b.sent();
                    return [3 /*break*/, 4];
                case 3:
                    _a = [];
                    _b.label = 4;
                case 4:
                    segments = _a;
                    groupedMetadata = groupBy(metadata, function (metadataItem) { return metadataItem.sourceLogicalName; });
                    return [2 /*return*/, {
                            metadata: groupedMetadata,
                            segments: segments
                        }];
            }
        });
    });
};
export var loadListAndOutputAttributeFromOneOfManyToken = function (targetEntitiesExtractor, metadataService, binding) { return __awaiter(void 0, void 0, void 0, function () {
    var usedMetadata, bindingItems, outputAttribute, listAttribute;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, loadMetadata(targetEntitiesExtractor, metadataService, binding)];
            case 1:
                usedMetadata = _a.sent();
                bindingItems = targetEntitiesExtractor.getBindingItems(binding) || [];
                outputAttribute = usedMetadata.find(function (m) { return m.logicalName === bindingItems[bindingItems.length - 1].output; });
                listAttribute = null;
                bindingItems.find(function (b) {
                    var attribute = usedMetadata.find(function (m) { return m.logicalName === b.output; });
                    if (PlaceholderAttributeFilter.hasListType(attribute)) {
                        listAttribute = attribute;
                        return true;
                    }
                    return false;
                });
                return [2 /*return*/, {
                        listAttribute: listAttribute,
                        outputAttribute: outputAttribute
                    }];
        }
    });
}); };
export var extractDisplayName = function (metadataItem) {
    var _a;
    if ((metadataItem.dataType === "manyToManyRelationship" ||
        metadataItem.dataType === "oneToManyRelationship" ||
        metadataItem.dataType === "lookup") &&
        metadataItem.referencedSources != null &&
        Object.keys(metadataItem.referencedSources).length > 0) {
        var firstKey = Object.keys(metadataItem.referencedSources)[0];
        var firstReferencedSource = metadataItem.referencedSources[firstKey];
        return { displayName: metadataItem.displayName, relatedDataDisplayName: "(".concat((_a = firstReferencedSource.displayName) !== null && _a !== void 0 ? _a : firstKey, ")") };
    }
    return { displayName: metadataItem.displayName };
};
var loadEntities = function (metadataService, entitesToLoad) { return __awaiter(void 0, void 0, void 0, function () {
    var result, filteredResult;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, metadataService.getDataverseTableMetadataItems(Array.from(new Set(entitesToLoad)), false, true)];
            case 1:
                result = (_a.sent()).value;
                filteredResult = result.filter(PlaceholderAttributeFilter.filterAllowedAttributes);
                return [2 /*return*/, filteredResult];
        }
    });
}); };
export var loadEvents = function (metadataService, eventNames) { return __awaiter(void 0, void 0, void 0, function () {
    var result, filteredResult;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, metadataService.getCjoEventsByName(Array.from(new Set(eventNames)), true)];
            case 1:
                result = (_a.sent()).value;
                filteredResult = PlaceholderAttributeFilter.filterEventAttributes(result.filter(PlaceholderAttributeFilter.filterAllowedAttributes));
                return [2 /*return*/, filteredResult];
        }
    });
}); };
var loadLegal = function (metadataService) { return __awaiter(void 0, void 0, void 0, function () {
    var result, filteredResult;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, metadataService.getAllLegalMetadataItems(true)];
            case 1:
                result = (_a.sent()).value;
                filteredResult = result.filter(PlaceholderAttributeFilter.filterAllowedAttributes);
                return [2 /*return*/, filteredResult];
        }
    });
}); };
var loadBrand = function (metadataService) { return __awaiter(void 0, void 0, void 0, function () {
    var result, filteredResult;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, metadataService.getBrandMetadataItems(true, true)];
            case 1:
                result = (_a.sent()).value;
                filteredResult = result.filter(PlaceholderAttributeFilter.filterAllowedAttributes);
                return [2 /*return*/, filteredResult];
        }
    });
}); };
