export function normalizeGuid(id) {
    // Removes spaces and braces from around a guid, if they exist
    if (id && id.length > 0) {
        id = id.trim().toLowerCase();
        if (id.startsWith("{")) {
            id = id.slice(1);
        }
        if (id.endsWith("}")) {
            id = id.slice(0, -1);
        }
    }
    return id;
}
export function looksLikeGuid(str) {
    return /^[{]?[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}[}]?$/i.test(str);
}
