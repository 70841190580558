var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { Service } from "@cxp-shared/platform/Service";
var TelemetryService = /** @class */ (function (_super) {
    __extends(TelemetryService, _super);
    function TelemetryService(providers) {
        var _this = _super.call(this) || this;
        providers = providers || [];
        _this.providers = providers.slice();
        return _this;
    }
    TelemetryService.prototype.logInfo = function (logMessage, parameters) {
        var e_1, _a;
        try {
            for (var _b = __values(this.providers), _c = _b.next(); !_c.done; _c = _b.next()) {
                var provider = _c.value;
                provider.logInfo(logMessage, parameters);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    TelemetryService.prototype.logError = function (logMessage, parameters) {
        var e_2, _a;
        try {
            for (var _b = __values(this.providers), _c = _b.next(); !_c.done; _c = _b.next()) {
                var provider = _c.value;
                provider.logError(logMessage, parameters);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    TelemetryService.prototype.logFunctionalEvent = function (eventName, eventData) {
        var e_3, _a;
        try {
            for (var _b = __values(this.providers), _c = _b.next(); !_c.done; _c = _b.next()) {
                var provider = _c.value;
                provider.logFunctionalEvent(eventName, eventData);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    TelemetryService.prototype.cloneForComponent = function (component) {
        return new TelemetryService(this.providers.map(function (p) { return p.cloneForComponent(component); }));
    };
    return TelemetryService;
}(Service));
export { TelemetryService };
