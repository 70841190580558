var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Service } from "@cxp-shared/platform/Service";
import { CommonDataServiceName } from "@cxp-shared/services/CDS";
import { TelemetryServiceName } from "@cxp-shared/services/Telemetry";
import { TargetEntitiesExtractorServiceName } from "../../TargetAudience/ITargetEntitiesExtractor";
import { clearEmailLiveEditabilityCache, fetchJourneyDependencies, getEmailLiveEditabilityFromCache, setEmailLiveEditabilityToCache } from "./EmailLiveEditabilityUtils";
import { normalizeGuid } from "@cxp-shared/string-utilities/normalize-guid";
import { CachedMetadataStoreServiceName } from "@cxp-shared/metadata";
var PersonalizationEntityLiveEditabilityService = /** @class */ (function (_super) {
    __extends(PersonalizationEntityLiveEditabilityService, _super);
    function PersonalizationEntityLiveEditabilityService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PersonalizationEntityLiveEditabilityService.prototype.serviceStart = function (appContext) {
        _super.prototype.serviceStart.call(this, appContext);
        this.cds = this.appContext.getService(CommonDataServiceName);
        this.telemetryService = this.appContext.getService(TelemetryServiceName);
        this.targetEntitiesExtractor = this.appContext.getService(TargetEntitiesExtractorServiceName);
        this.metadataStoreService = this.appContext.getService(CachedMetadataStoreServiceName);
    };
    PersonalizationEntityLiveEditabilityService.prototype.getEmailLiveEditability = function (emailEntityId, forceRefresh) {
        if (forceRefresh === void 0) { forceRefresh = false; }
        return __awaiter(this, void 0, void 0, function () {
            var normalizedId, cachedEmailLiveEditability, _a, isPersoLiveEditable, originalDefinedPlaceholders, newEmailLiveEditability, _b, targetAudience, segmentAudience;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        normalizedId = normalizeGuid(emailEntityId);
                        cachedEmailLiveEditability = forceRefresh ? null : getEmailLiveEditabilityFromCache(normalizedId);
                        if ((cachedEmailLiveEditability === null || cachedEmailLiveEditability === void 0 ? void 0 : cachedEmailLiveEditability.isPersoLiveEditable) != null &&
                            (cachedEmailLiveEditability === null || cachedEmailLiveEditability === void 0 ? void 0 : cachedEmailLiveEditability.originalDefinedPlaceholders) != null &&
                            (cachedEmailLiveEditability === null || cachedEmailLiveEditability === void 0 ? void 0 : cachedEmailLiveEditability.originalTargetAudience) != null &&
                            (cachedEmailLiveEditability === null || cachedEmailLiveEditability === void 0 ? void 0 : cachedEmailLiveEditability.originalSegmentAudience) != null) {
                            return [2 /*return*/, cachedEmailLiveEditability];
                        }
                        return [4 /*yield*/, Promise.all([
                                (cachedEmailLiveEditability === null || cachedEmailLiveEditability === void 0 ? void 0 : cachedEmailLiveEditability.isPersoLiveEditable) == null
                                    ? this.getIsPersoLiveEditable(normalizedId)
                                    : cachedEmailLiveEditability.isPersoLiveEditable,
                                (cachedEmailLiveEditability === null || cachedEmailLiveEditability === void 0 ? void 0 : cachedEmailLiveEditability.originalDefinedPlaceholders)
                                    ? cachedEmailLiveEditability.originalDefinedPlaceholders
                                    : this.fetchOriginalEmailDefinedPlaceholders(normalizedId)
                            ])];
                    case 1:
                        _a = __read.apply(void 0, [_c.sent(), 2]), isPersoLiveEditable = _a[0], originalDefinedPlaceholders = _a[1];
                        newEmailLiveEditability = {};
                        newEmailLiveEditability.isPersoLiveEditable = isPersoLiveEditable;
                        newEmailLiveEditability.originalDefinedPlaceholders = originalDefinedPlaceholders;
                        if (!((cachedEmailLiveEditability === null || cachedEmailLiveEditability === void 0 ? void 0 : cachedEmailLiveEditability.originalTargetAudience) && (cachedEmailLiveEditability === null || cachedEmailLiveEditability === void 0 ? void 0 : cachedEmailLiveEditability.originalSegmentAudience))) return [3 /*break*/, 2];
                        newEmailLiveEditability.originalTargetAudience = cachedEmailLiveEditability.originalTargetAudience;
                        newEmailLiveEditability.originalSegmentAudience = cachedEmailLiveEditability.originalSegmentAudience;
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.targetEntitiesExtractor.extractCdsAndSegmentTargetAudience(originalDefinedPlaceholders || {})];
                    case 3:
                        _b = _c.sent(), targetAudience = _b.targetAudience, segmentAudience = _b.segmentAudience;
                        newEmailLiveEditability.originalTargetAudience = targetAudience;
                        newEmailLiveEditability.originalSegmentAudience = segmentAudience;
                        _c.label = 4;
                    case 4:
                        // newEmailLiveEditability.originalTargetAudience =
                        //     cachedEmailLiveEditability?.originalTargetAudience ||
                        //     (await this.targetEntitiesExtractor.extractTargetAudience(originalDefinedPlaceholders || {}));
                        newEmailLiveEditability.timestamp = (cachedEmailLiveEditability === null || cachedEmailLiveEditability === void 0 ? void 0 : cachedEmailLiveEditability.timestamp) || Date.now();
                        setEmailLiveEditabilityToCache(normalizedId, newEmailLiveEditability);
                        return [2 /*return*/, newEmailLiveEditability];
                }
            });
        });
    };
    PersonalizationEntityLiveEditabilityService.prototype.clearLiveEditabilityCache = function () {
        clearEmailLiveEditabilityCache();
    };
    PersonalizationEntityLiveEditabilityService.prototype.canUseSegmentsInLiveEdit = function (segmentIds) {
        return __awaiter(this, void 0, void 0, function () {
            var result, segmentMap_1, segments, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.metadataStoreService.getSpecificSegments(segmentIds)];
                    case 1:
                        result = _a.sent();
                        segmentMap_1 = result.filter(function (segment) { return segment; }).reduce(function (acc, segment) {
                            acc[segment.id] = segment;
                            return acc;
                        }, {});
                        segments = segmentIds.map(function (segmentId) {
                            var segment = segmentMap_1[segmentId];
                            if (segment) {
                                return {
                                    canUseSegmentInLiveEdit: !segment.requiresExport,
                                    segmentId: segmentId,
                                    segment: segment
                                };
                            }
                            else {
                                return {
                                    canUseSegmentInLiveEdit: false,
                                    segmentId: segmentId,
                                    segment: null
                                };
                            }
                        });
                        return [2 /*return*/, segments];
                    case 2:
                        error_1 = _a.sent();
                        this.telemetryService.logError("Error checking segment live editability", error_1);
                        return [2 /*return*/, segmentIds.map(function (segmentId) { return ({
                                canUseSegmentInLiveEdit: false,
                                segmentId: segmentId,
                                segment: null,
                                errorMessage: error_1 === null || error_1 === void 0 ? void 0 : error_1.message
                            }); })];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PersonalizationEntityLiveEditabilityService.prototype.getIsPersoLiveEditable = function (emailEntityId) {
        return __awaiter(this, void 0, void 0, function () {
            var data, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, fetchJourneyDependencies(emailEntityId)];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, data === null || data === void 0 ? void 0 : data.isPersoLiveEditable];
                    case 2:
                        error_2 = _a.sent();
                        this.telemetryService.logError("Error getting live edit status", error_2);
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PersonalizationEntityLiveEditabilityService.prototype.fetchOriginalEmailDefinedPlaceholders = function (emailEntityId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, placeholders, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.cds.getRecord("msdynmkt_emails", emailEntityId, ["msdynmkt_placeholders"])];
                    case 1:
                        result = _a.sent();
                        placeholders = JSON.parse(result.msdynmkt_placeholders);
                        return [2 /*return*/, placeholders];
                    case 2:
                        error_3 = _a.sent();
                        this.telemetryService.logError("Error fetching original placeholders for email", error_3);
                        return [2 /*return*/, null];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return PersonalizationEntityLiveEditabilityService;
}(Service));
export { PersonalizationEntityLiveEditabilityService };
