var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Service } from "@cxp-shared/platform/Service";
export var MessageFormatterName = "MessageFormatter";
var MessageFormatter = /** @class */ (function (_super) {
    __extends(MessageFormatter, _super);
    function MessageFormatter(dataFormatter) {
        var _this = _super.call(this) || this;
        _this.dataFormatter = dataFormatter;
        return _this;
    }
    MessageFormatter.prototype.serviceStart = function (appContext) {
        _super.prototype.serviceStart.call(this, appContext);
    };
    MessageFormatter.prototype.formatMessage = function (message) {
        if (!message) {
            return null;
        }
        var result = message.messageTemplate;
        if (message.messageParts) {
            for (var i = 0; i < message.messageParts.length; i++) {
                var parameterValue = this.formatParameter(message.messageParts[i]);
                result = this.replaceParameter(result, parameterValue, i);
            }
        }
        return result;
    };
    MessageFormatter.prototype.formatParameter = function (messagePart) {
        try {
            switch (messagePart.type) {
                case "string":
                    return messagePart.value;
                case "integer":
                    return this.dataFormatter.formatInteger(messagePart.value);
                case "decimal":
                    return this.dataFormatter.formatDecimal(messagePart.value);
                case "date":
                    return this.dataFormatter.formatDateShort(new Date(Date.parse(messagePart.value)), false);
                case "date-time":
                    return this.dataFormatter.formatDateShort(new Date(Date.parse(messagePart.value)), true);
                case "time":
                    return this.dataFormatter.formatTime(new Date(Date.parse(messagePart.value)), 1);
                default:
                    return JSON.stringify(messagePart.value);
            }
        }
        catch (_a) {
            return JSON.stringify(messagePart.value);
        }
    };
    MessageFormatter.prototype.replaceParameter = function (input, parameterValue, parameterIndex) {
        var formatPlaceholder = "\\{".concat(parameterIndex, "\\}");
        return input.replace(new RegExp(formatPlaceholder, "g"), parameterValue);
    };
    return MessageFormatter;
}(Service));
export { MessageFormatter };
// TODO: Replace with proper XRM data formatter
var DefaultDataFormatter = /** @class */ (function () {
    function DefaultDataFormatter() {
    }
    DefaultDataFormatter.prototype.formatDateShort = function (value, includeTime) {
        return value.toLocaleString();
    };
    DefaultDataFormatter.prototype.formatInteger = function (value) {
        throw value.toLocaleString();
    };
    DefaultDataFormatter.prototype.formatDecimal = function (value, precision) {
        throw value.toLocaleString();
    };
    DefaultDataFormatter.prototype.formatTime = function (value) {
        throw value.toLocaleString();
    };
    return DefaultDataFormatter;
}());
export { DefaultDataFormatter };
var XrmDataFormatter = /** @class */ (function () {
    function XrmDataFormatter() {
        this.cultureName = window.Xrm.Utility.getGlobalContext().userSettings.formatInfoCultureName;
    }
    XrmDataFormatter.prototype.formatDateShort = function (value, includeTime) {
        if (includeTime) {
            return value.toLocaleString(this.cultureName);
        }
        return value.toLocaleDateString(this.cultureName);
    };
    XrmDataFormatter.prototype.formatTime = function (value) {
        return value.toLocaleTimeString(this.cultureName);
    };
    XrmDataFormatter.prototype.formatInteger = function (value) {
        return value.toLocaleString(this.cultureName);
    };
    XrmDataFormatter.prototype.formatDecimal = function (value, precision) {
        if (precision) {
            var options = {
                maximumFractionDigits: precision
            };
            return value.toLocaleString(this.cultureName, options);
        }
        return value.toLocaleString(this.cultureName);
    };
    return XrmDataFormatter;
}());
export { XrmDataFormatter };
export function formatString(str) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    return str.replace(/{(\d+)}/g, function (match, idx) {
        return args[idx] !== undefined ? args[idx] : match;
    });
}
