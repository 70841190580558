import { PlaceholderToken } from "../Constants/PlaceholderToken";
var placeholderMatchRegex = new RegExp("".concat(PlaceholderToken.LeftHandlebars, "(.*?)").concat(PlaceholderToken.RightHandlebars), "gi");
export var getPlaceholders = function (message) {
    var placeholdersInfo = [];
    var placeholdersMatch = message.match(placeholderMatchRegex);
    if (placeholdersMatch == null) {
        return placeholdersInfo;
    }
    var searchFromIndex = 0;
    placeholdersMatch.forEach(function (match) {
        var startIndex = message.indexOf(match, searchFromIndex);
        var endIndex = startIndex + match.length - 1;
        searchFromIndex = endIndex + 1;
        placeholdersInfo.push(match);
    });
    return placeholdersInfo;
};
