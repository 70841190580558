var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { Service } from "@cxp-shared/platform/Service";
import { CommonDataServiceName } from "@cxp-shared/services/CDS";
import { TelemetryServiceName } from "@cxp-shared/services/Telemetry";
import { CachedMetadataStoreServiceName } from "@cxp-shared/metadata";
import { PredefinedPlaceholderDataService, TargetEntitiesExtractorServiceName, PredefinedPlaceholderUtils, PlaceholderUtils, PlaceholderGenerator, extractType, DataSources, BindingGenerator, PlaceholderAttributeFilter } from "@cxp-shared/personalization-core";
import { PlaceholderVisualizationServiceName } from "../PlaceholderVisualization/PlaceholderVisualizationService.Types";
import { ExpirationCacheServiceName } from "@cxp-shared/caching";
import { getStaticBindingList } from "../../Utilities/StaticBindingService";
import { isBusinessUnitScopingEnabled } from "@cxp-shared/business-unit";
import { resolveBusinessUnit } from "@cxp-shared/business-unit/src/BusinessUnitResolver";
var ExistingPlaceholdersCacheKey = "PersoAI-ExistingPlaceholders";
var PredefinedPlaceholdersCacheKey = "PersoAI-PredefinedPlaceholders";
var ExistingPlaceholderRetrivalService = /** @class */ (function (_super) {
    __extends(ExistingPlaceholderRetrivalService, _super);
    function ExistingPlaceholderRetrivalService() {
        var _this = _super.call(this) || this;
        _this.retriveUsedPlaceholders = function (_a) {
            var definedPlaceholders = _a.definedPlaceholders, entities = _a.entities, predefinedPlaceholderNamespaces = _a.predefinedPlaceholderNamespaces, lcid = _a.lcid, businessUnit = _a.businessUnit;
            return __awaiter(_this, void 0, void 0, function () {
                var resolvedBusinessUnit, businessUnitFilter, predefinedPlaceholdersPromises, usedPlaceholderPromise, _b, usedPlaceholdersData, predefinedPlaceholdersData, usedPlaceholders, predefinedPlaceholders, predefined, existingPlaceholders, groupedPlaceholders, promises, results;
                var _this = this;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0: return [4 /*yield*/, resolveBusinessUnit(businessUnit)];
                        case 1:
                            resolvedBusinessUnit = _c.sent();
                            return [4 /*yield*/, this.getBusinessUnitFilter(resolvedBusinessUnit)];
                        case 2:
                            businessUnitFilter = _c.sent();
                            predefinedPlaceholdersPromises = Promise.all(predefinedPlaceholderNamespaces.map(function (namespace) {
                                return _this.expirationCacheService.get("".concat(PredefinedPlaceholdersCacheKey, "-").concat(namespace, "-").concat(lcid), function () { return _this.predefinedPlaceholderService.fetchByNamespace(namespace, lcid).catch(function () { return []; }); }, true);
                            }));
                            usedPlaceholderPromise = Promise.all(entities.map(function (entity) {
                                return _this.expirationCacheService.get("".concat(ExistingPlaceholdersCacheKey, "-").concat(entity.entity, "-").concat(entity.placeholderAttribute, "-").concat(resolvedBusinessUnit), function () {
                                    return _this.cds
                                        .getRecords(entity.entity, "$select=".concat(entity.placeholderAttribute, ",createdon").concat(businessUnitFilter))
                                        .then(function (records) {
                                        return records.map(function (record) { return ({
                                            placeholders: record[entity.placeholderAttribute],
                                            createdon: record["createdon"]
                                        }); });
                                    })
                                        .catch(function () { return []; });
                                }, true);
                            }));
                            return [4 /*yield*/, Promise.all([usedPlaceholderPromise, predefinedPlaceholdersPromises])];
                        case 3:
                            _b = __read.apply(void 0, [_c.sent(), 2]), usedPlaceholdersData = _b[0], predefinedPlaceholdersData = _b[1];
                            usedPlaceholders = usedPlaceholdersData.flat();
                            predefinedPlaceholders = predefinedPlaceholdersData.flat();
                            predefined = predefinedPlaceholders.reduce(function (acc, item) {
                                item.placeholderdescriptor.alternateBindings.forEach(function (binding) {
                                    var _a;
                                    var _b, _c;
                                    var placeholder = PredefinedPlaceholderUtils.getPlaceholderData({}, item, { binding: binding.binding });
                                    acc.push({
                                        createdon: ((_c = (_b = item.usedOn) === null || _b === void 0 ? void 0 : _b.toISOString) === null || _c === void 0 ? void 0 : _c.call(_b)) || new Date().toISOString(),
                                        placeholders: JSON.stringify((_a = {},
                                            _a[item.description] = placeholder.placeholder,
                                            _a))
                                    });
                                });
                                return acc;
                            }, []);
                            existingPlaceholders = { createdon: new Date().toISOString(), placeholders: JSON.stringify(definedPlaceholders || {}) };
                            groupedPlaceholders = __spreadArray(__spreadArray(__spreadArray([], __read(usedPlaceholders), false), __read(predefined), false), [existingPlaceholders], false).reduce(function (acc, data) {
                                if (!data.placeholders) {
                                    return acc;
                                }
                                var placeholders = JSON.parse(data.placeholders);
                                Object.entries(placeholders).forEach(function (_a) {
                                    var _b;
                                    var _c = __read(_a, 2), name = _c[0], placeholder = _c[1];
                                    var rootBindingSource = (_b = PlaceholderUtils.getRootBinding(placeholder === null || placeholder === void 0 ? void 0 : placeholder.binding)) === null || _b === void 0 ? void 0 : _b.source;
                                    if (placeholder.category === "general" &&
                                        !PlaceholderUtils.isStaticBinding(placeholder === null || placeholder === void 0 ? void 0 : placeholder.binding) &&
                                        !PlaceholderUtils.isFirstOfManyPlaceholder(placeholder === null || placeholder === void 0 ? void 0 : placeholder.binding) && // Lets filter them out for now, the AI does not yet understand the concept of first of many
                                        (rootBindingSource === DataSources.CdsDataSource || rootBindingSource === DataSources.BrandDataSource || rootBindingSource === DataSources.EventDataSource || rootBindingSource === DataSources.LegalDataSource) &&
                                        !(placeholder === null || placeholder === void 0 ? void 0 : placeholder.isInternal)) {
                                        var descriptor = JSON.stringify(_this.targetEntitiesExtractor.getBindingItems(placeholder.binding));
                                        if (!acc[descriptor]) {
                                            acc[descriptor] = {
                                                names: [name],
                                                description: "",
                                                placeholder: placeholder,
                                                count: 1,
                                                lastUsed: data.createdon
                                            };
                                        }
                                        else {
                                            acc[descriptor].names = Array.from(new Set(__spreadArray(__spreadArray([], __read(acc[descriptor].names), false), [name], false)));
                                            acc[descriptor].lastUsed = acc[descriptor].lastUsed > data.createdon ? acc[descriptor].lastUsed : data.createdon;
                                            acc[descriptor].count++;
                                        }
                                    }
                                });
                                return acc;
                            }, {});
                            promises = Object.values(groupedPlaceholders).map(function (placeholder) {
                                var promise = _this.placeholderVisualization.loadBindingVisualization({
                                    placeholderCategory: "general",
                                    selectedBinding: placeholder.placeholder.binding
                                });
                                return promise;
                            });
                            return [4 /*yield*/, Promise.all(promises)];
                        case 4:
                            results = _c.sent();
                            results.forEach(function (result, index) {
                                var placeholder = groupedPlaceholders[Object.keys(groupedPlaceholders)[index]];
                                placeholder.description = result.selectedBindingItems.map(function (item) { return item.displayName; }).join(" > ");
                            });
                            return [2 /*return*/, {
                                    existingPlaceholders: groupedPlaceholders
                                }];
                    }
                });
            });
        };
        _this.createAttributePlaceholders = function (request) { return __awaiter(_this, void 0, void 0, function () {
            var groupedMetadata, attributePlaceholderDescriptors, attributePlaceholders;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadDataverseMetadata(request.entities, request.allowPicklistsForTokens, request.type)];
                    case 1:
                        groupedMetadata = _a.sent();
                        attributePlaceholderDescriptors = {};
                        attributePlaceholders = {};
                        Object.entries(groupedMetadata).forEach(function (_a) {
                            var _b = __read(_a, 2), entity = _b[0], attributes = _b[1];
                            attributes.forEach(function (attribute) {
                                var placeholderType = extractType(attribute.dataType, true);
                                var placeholder = PlaceholderGenerator.generatePlaceholder({
                                    placeholderCategory: "general",
                                    placeholderType: placeholderType.type,
                                    title: PlaceholderUtils.sanitizePlaceholderName(attribute.displayName),
                                    defaultValue: "",
                                    entityLogicalName: entity,
                                    entityAttributeLogicalName: attribute.logicalName,
                                    entityProviderName: DataSources.CdsDataSource,
                                    outputType: attribute.dataType,
                                    format: placeholderType.format
                                });
                                var descriptor = JSON.stringify(_this.targetEntitiesExtractor.getBindingItems(placeholder.binding));
                                attributePlaceholderDescriptors[descriptor] = {
                                    names: [placeholder.title],
                                    description: [attribute.sourceDisplayName, attribute.displayName].join(" > "),
                                    placeholder: placeholder,
                                    count: 1,
                                    lastUsed: new Date().toISOString()
                                };
                                attributePlaceholders[placeholder.title] = placeholder;
                            });
                        });
                        return [2 /*return*/, {
                                placeholders: attributePlaceholders,
                                placeholderDescriptors: attributePlaceholderDescriptors
                            }];
                }
            });
        }); };
        _this.createRelationshipPlaceholders = function (request) { return __awaiter(_this, void 0, void 0, function () {
            var lastEntities, groupedMetadata, relationshipPlaceholderDescriptors, relationshipPlaceholders;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        lastEntities = __spreadArray([], __read(new Set(request.relationships.map(function (relationship) { return relationship.entities.at(-1); }).flat())), false);
                        return [4 /*yield*/, this.loadDataverseMetadata(lastEntities, request.allowPicklistsForTokens, request.type)];
                    case 1:
                        groupedMetadata = _a.sent();
                        relationshipPlaceholderDescriptors = {};
                        relationshipPlaceholders = {};
                        request.relationships.forEach(function (relationship) {
                            var e_1, _a;
                            var _b;
                            var bindingPath = _this.createBindingPathItems(relationship.items);
                            var attributes = groupedMetadata[relationship.entities.at(-1)];
                            var placeholderKeyPrefix = relationship.entities.join(".");
                            try {
                                for (var attributes_1 = __values(attributes), attributes_1_1 = attributes_1.next(); !attributes_1_1.done; attributes_1_1 = attributes_1.next()) {
                                    var attribute = attributes_1_1.value;
                                    var placeholderType = extractType(attribute.dataType, true);
                                    var binding = (_b = BindingGenerator.generateComplexBinding(PlaceholderGenerator, __spreadArray(__spreadArray([], __read(bindingPath), false), [{ type: "Attribute", item: attribute }], false), "general")) === null || _b === void 0 ? void 0 : _b.binding;
                                    var placeholder = PlaceholderGenerator.generatePlaceholderWithBindingInputParameters({
                                        placeholderCategory: "general",
                                        title: PlaceholderUtils.sanitizePlaceholderName(attribute.displayName),
                                        placeholderType: placeholderType.type,
                                        defaultValue: "",
                                        entityProviderName: binding === null || binding === void 0 ? void 0 : binding.source,
                                        entityAttributeLogicalName: attribute.logicalName,
                                        bindingInputParameters: binding === null || binding === void 0 ? void 0 : binding.inputs,
                                        outputType: attribute.dataType,
                                        format: placeholderType.format
                                    });
                                    var descriptor = JSON.stringify(_this.targetEntitiesExtractor.getBindingItems(placeholder.binding));
                                    relationshipPlaceholderDescriptors[descriptor] = {
                                        names: [placeholder.title],
                                        description: [relationship.description, attribute.displayName].join(" > "),
                                        placeholder: placeholder,
                                        count: 1,
                                        lastUsed: new Date().toISOString()
                                    };
                                    relationshipPlaceholders["".concat(placeholderKeyPrefix, ".").concat(placeholder.title)] = placeholder;
                                }
                            }
                            catch (e_1_1) { e_1 = { error: e_1_1 }; }
                            finally {
                                try {
                                    if (attributes_1_1 && !attributes_1_1.done && (_a = attributes_1.return)) _a.call(attributes_1);
                                }
                                finally { if (e_1) throw e_1.error; }
                            }
                        });
                        return [2 /*return*/, {
                                placeholders: relationshipPlaceholders,
                                placeholderDescriptors: relationshipPlaceholderDescriptors
                            }];
                }
            });
        }); };
        _this.createEventPlaceholders = function (request) { return __awaiter(_this, void 0, void 0, function () {
            var eventEntities, groupedMetadata, placeholderDescriptors, placeholders;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        eventEntities = __spreadArray([], __read(new Set(request.events.map(function (event) { var _a; return Object.keys(((_a = event.item) === null || _a === void 0 ? void 0 : _a.referencedSources) || {}); }).flat())), false);
                        return [4 /*yield*/, this.loadDataverseMetadata(eventEntities, request.allowPicklistsForTokens, request.type)];
                    case 1:
                        groupedMetadata = _a.sent();
                        placeholderDescriptors = {};
                        placeholders = {};
                        request.events.forEach(function (event) {
                            var _a;
                            if (event.item) { // event lookup attribute > entity attributes
                                var entityLogicalName = Object.keys(event.item.referencedSources || {}).length > 0 ? Object.keys(event.item.referencedSources)[0] : null;
                                var bindingPath_1 = _this.createBindingPathItems([event.item]);
                                var attributes = groupedMetadata[entityLogicalName];
                                var placeholderKeyPrefix_1 = "".concat(event.item.sourceLogicalName, ".").concat(event.item.logicalName);
                                attributes === null || attributes === void 0 ? void 0 : attributes.forEach(function (attribute) {
                                    var _a;
                                    var placeholderType = extractType(attribute.dataType, true);
                                    var binding = (_a = BindingGenerator.generateComplexBinding(PlaceholderGenerator, __spreadArray(__spreadArray([], __read(bindingPath_1), false), [{ type: "Attribute", item: attribute }], false), "general")) === null || _a === void 0 ? void 0 : _a.binding;
                                    var placeholder = PlaceholderGenerator.generatePlaceholderWithBindingInputParameters({
                                        placeholderCategory: "general",
                                        title: PlaceholderUtils.sanitizePlaceholderName(attribute.displayName),
                                        placeholderType: placeholderType.type,
                                        defaultValue: "",
                                        entityProviderName: binding === null || binding === void 0 ? void 0 : binding.source,
                                        entityAttributeLogicalName: attribute.logicalName,
                                        bindingInputParameters: binding === null || binding === void 0 ? void 0 : binding.inputs,
                                        outputType: attribute.dataType,
                                        format: placeholderType.format
                                    });
                                    var descriptor = JSON.stringify(_this.targetEntitiesExtractor.getBindingItems(placeholder.binding));
                                    placeholderDescriptors[descriptor] = {
                                        names: [placeholder.title],
                                        description: [event.description, attribute.displayName].join(" > "),
                                        placeholder: placeholder,
                                        count: 1,
                                        lastUsed: new Date().toISOString()
                                    };
                                    placeholders["".concat(placeholderKeyPrefix_1, ".").concat(placeholder.title)] = placeholder;
                                });
                            }
                            else { // event non-lookup attributes
                                (_a = event.attributes) === null || _a === void 0 ? void 0 : _a.forEach(function (attribute) {
                                    if (attribute.dataType === "lookup") {
                                        return;
                                    }
                                    var placeholderKeyPrefix = "".concat(attribute.sourceLogicalName, ".").concat(attribute.logicalName);
                                    var placeholderType = extractType(attribute.dataType, true);
                                    var placeholder = PlaceholderGenerator.generatePlaceholder({
                                        placeholderCategory: "general",
                                        placeholderType: placeholderType.type,
                                        title: PlaceholderUtils.sanitizePlaceholderName(attribute.displayName),
                                        defaultValue: "",
                                        entityLogicalName: attribute.sourceLogicalName,
                                        entityAttributeLogicalName: attribute.logicalName,
                                        entityProviderName: DataSources.EventDataSource,
                                        outputType: attribute.dataType,
                                        format: placeholderType.format
                                    });
                                    var descriptor = JSON.stringify(_this.targetEntitiesExtractor.getBindingItems(placeholder.binding));
                                    placeholderDescriptors[descriptor] = {
                                        names: [placeholder.title],
                                        description: [event.description, attribute.displayName].join(" > "),
                                        placeholder: placeholder,
                                        count: 1,
                                        lastUsed: new Date().toISOString()
                                    };
                                    placeholders[placeholderKeyPrefix] = placeholder;
                                });
                            }
                        });
                        return [2 /*return*/, {
                                placeholders: placeholders,
                                placeholderDescriptors: placeholderDescriptors
                            }];
                }
            });
        }); };
        _this.createBrandPlaceholders = function (request) { return __awaiter(_this, void 0, void 0, function () {
            var placeholderDescriptors, placeholders, metadata, filteredItems;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        placeholderDescriptors = {};
                        placeholders = {};
                        return [4 /*yield*/, this.metadataService.getBrandMetadataItems(true)];
                    case 1:
                        metadata = (_a.sent()).value;
                        filteredItems = metadata.filter(function (item) { return _this.filterAllowedTokenAttributes(item, request.allowPicklistsForTokens, request.type); });
                        filteredItems === null || filteredItems === void 0 ? void 0 : filteredItems.forEach(function (item) {
                            var placeholderKeyPrefix = "".concat(item.sourceLogicalName, ".").concat(item.logicalName);
                            var placeholderType = extractType(item.dataType, true);
                            var placeholder = PlaceholderGenerator.generatePlaceholder({
                                placeholderCategory: "general",
                                placeholderType: placeholderType.type,
                                title: PlaceholderUtils.sanitizePlaceholderName(item.displayName),
                                defaultValue: "",
                                entityLogicalName: item.sourceLogicalName,
                                entityAttributeLogicalName: item.logicalName,
                                entityProviderName: DataSources.BrandDataSource,
                                outputType: item.dataType,
                                format: placeholderType.format
                            });
                            var descriptor = JSON.stringify(_this.targetEntitiesExtractor.getBindingItems(placeholder.binding));
                            placeholderDescriptors[descriptor] = {
                                names: [placeholder.title],
                                description: ["Brand profile", item.displayName].join(" > "),
                                placeholder: placeholder,
                                count: 1,
                                lastUsed: new Date().toISOString()
                            };
                            placeholders[placeholderKeyPrefix] = placeholder;
                        });
                        return [2 /*return*/, {
                                placeholders: placeholders,
                                placeholderDescriptors: placeholderDescriptors
                            }];
                }
            });
        }); };
        _this.createCompliancePlaceholders = function (type) {
            var _a;
            var placeholders = {};
            if (type === "string") {
                var companyAddressPlaceholder = PlaceholderGenerator.generateCompanyAddressPlaceholder();
                var preferenceCenterPlaceholder = PlaceholderGenerator.generatePreferenceCenterPlaceholder();
                placeholders = (_a = {},
                    _a["Compliance.".concat(companyAddressPlaceholder.title)] = companyAddressPlaceholder,
                    _a["Compliance.".concat(preferenceCenterPlaceholder.title)] = preferenceCenterPlaceholder,
                    _a);
            }
            return {
                placeholders: placeholders,
                placeholderDescriptors: {} // not used
            };
        };
        _this.extractRelationships = function (request) { return __awaiter(_this, void 0, void 0, function () {
            var allowedEntities, depth, groupedMetadata, result, index;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        allowedEntities = this.getAllowedEntities(request.additionalEntities);
                        depth = request.depth > 0 ? Math.min(request.depth, 5) : 2;
                        return [4 /*yield*/, this.loadRelationships(request.startEntities, allowedEntities, request.allowAllForLastHop, depth)];
                    case 1:
                        groupedMetadata = _a.sent();
                        result = {};
                        request.startEntities.forEach(function (entity) {
                            var entityAttributes = groupedMetadata[entity];
                            entityAttributes === null || entityAttributes === void 0 ? void 0 : entityAttributes.forEach(function (item) {
                                var _a;
                                var isEntityAllowed = request.allowSameEntity || entity !== Object.keys(item.referencedSources)[0];
                                if (item.dataType === "lookup" && Object.keys(item.referencedSources || {}).length > 0 && isEntityAllowed) {
                                    result["".concat(entity, " > ").concat(item.logicalName)] = {
                                        description: "".concat(item.sourceDisplayName, " > ").concat(item.displayName, " (").concat((_a = Object.values(item.referencedSources)[0]) === null || _a === void 0 ? void 0 : _a.displayName, ")"),
                                        relationships: [item.logicalName],
                                        entities: [entity, Object.keys(item.referencedSources)[0]],
                                        items: [item]
                                    };
                                }
                            });
                        });
                        for (index = 1; index < depth; index++) {
                            Object.entries(result).forEach(function (_a) {
                                var _b = __read(_a, 2), description = _b[0], relationship = _b[1];
                                var entity = relationship.entities[relationship.entities.length - 1];
                                var entityAttributes = groupedMetadata[entity];
                                entityAttributes === null || entityAttributes === void 0 ? void 0 : entityAttributes.forEach(function (item) {
                                    var _a;
                                    var isEntityAllowed = request.allowSameEntity || !relationship.entities.includes(Object.keys(item.referencedSources)[0]);
                                    if (item.dataType === "lookup" &&
                                        Object.keys(item.referencedSources || {}).length > 0 &&
                                        !relationship.relationships.includes(item.logicalName) &&
                                        isEntityAllowed) {
                                        var newDescription = "".concat(description, " > ").concat(item.logicalName);
                                        result[newDescription] = {
                                            description: "".concat(relationship.description, " > ").concat(item.displayName, " (").concat((_a = Object.values(item.referencedSources)[0]) === null || _a === void 0 ? void 0 : _a.displayName, ")"),
                                            relationships: __spreadArray(__spreadArray([], __read(relationship.relationships), false), [item.logicalName], false),
                                            entities: __spreadArray(__spreadArray([], __read(relationship.entities), false), [Object.keys(item.referencedSources)[0]], false),
                                            items: __spreadArray(__spreadArray([], __read(relationship.items), false), [item], false)
                                        };
                                    }
                                });
                            });
                        }
                        return [2 /*return*/, {
                                relationships: result
                            }];
                }
            });
        }); };
        _this.extractEventsDescriptions = function (request) { return __awaiter(_this, void 0, void 0, function () {
            var loadedEventNames, loadedEventAttributes, eventAttributes, events, eventsDescriptions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.metadataService.getAllCjoEventsNames(request.includeDraftEvents, request.businessUnit, true)];
                    case 1:
                        loadedEventNames = (_a.sent()).value.map(function (t) { return t.sourceLogicalName; });
                        return [4 /*yield*/, this.metadataService.getCjoEventsByName(loadedEventNames, true)];
                    case 2:
                        loadedEventAttributes = (_a.sent()).value;
                        eventAttributes = PlaceholderAttributeFilter.filterEventAttributes(loadedEventAttributes);
                        events = {};
                        eventAttributes.forEach(function (eventAttribute) {
                            if (!events[eventAttribute.sourceLogicalName]) {
                                events[eventAttribute.sourceLogicalName] = {
                                    displayName: eventAttribute.sourceDisplayName,
                                    attributes: []
                                };
                            }
                            events[eventAttribute.sourceLogicalName].attributes.push(eventAttribute);
                        });
                        eventsDescriptions = [];
                        Object.entries(events).forEach(function (_a) {
                            var _b = __read(_a, 2), key = _b[0], event = _b[1];
                            if (request.targetEvent && key !== request.targetEvent) {
                                return;
                            }
                            eventsDescriptions.push({
                                description: event.displayName,
                                item: null,
                                attributes: event.attributes
                            });
                            event.attributes.forEach(function (attribute) {
                                if (attribute.dataType === "lookup") {
                                    eventsDescriptions.push({
                                        description: "".concat(event.displayName, " > ").concat(attribute.displayName),
                                        item: attribute,
                                        attributes: []
                                    });
                                }
                            });
                        });
                        return [2 /*return*/, eventsDescriptions];
                }
            });
        }); };
        _this.filterAllowedTokenAttributes = function (attribute, allowPicklistsForTokens, type) {
            if (!type) {
                return (attribute.dataType === "bigInt" ||
                    attribute.dataType === "money" ||
                    attribute.dataType === "dateTime" ||
                    attribute.dataType === "decimal" ||
                    attribute.dataType === "integer" ||
                    attribute.dataType === "string" ||
                    attribute.dataType === "double" ||
                    attribute.dataType === "memo" ||
                    (allowPicklistsForTokens && (attribute.dataType === "picklist" || attribute.dataType === "multiSelectPicklist")));
            }
            else if (type === "date") {
                return attribute.dataType === "dateTime";
            }
            else if (type === "number") {
                return (attribute.dataType === "bigInt" ||
                    attribute.dataType === "money" ||
                    attribute.dataType === "decimal" ||
                    attribute.dataType === "integer" ||
                    attribute.dataType === "double");
            }
            else {
                return attribute.dataType === "string" || attribute.dataType === "memo";
            }
        };
        _this.getAllowedEntities = function (additionalEntities) {
            if (additionalEntities === void 0) { additionalEntities = []; }
            var configEntities = getStaticBindingList();
            var allowedEntities = new Set(__spreadArray(__spreadArray([
                "contact",
                "account",
                "lead",
                "opportunity",
                "currency",
                "msevtmgt_checkin",
                "msevtmgt_event",
                "msevtmgt_eventregistration",
                "msevtmgt_waitlistitem"
            ], __read(configEntities), false), __read(additionalEntities), false));
            // Remove systemuser as it would lead to perf issues
            allowedEntities.delete("systemuser");
            return allowedEntities;
        };
        _this.loadRelationships = function (entities, allowedEntities, allowAllForLastHop, remainingDepth) { return __awaiter(_this, void 0, void 0, function () {
            var metadata, groupedMetadata, nextEntities, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.metadataService.getDataverseTableMetadataItems(entities, false, true)];
                    case 1:
                        metadata = (_a.sent()).value;
                        groupedMetadata = metadata
                            .filter(function (item) {
                            return item.dataType === "lookup" &&
                                item.referencedSources != null &&
                                Object.keys(item.referencedSources).length > 0 &&
                                Object.keys(item.referencedSources)[0] !== "systemuser" && // Remove systemuser as it would lead to perf issues
                                (allowAllForLastHop || allowedEntities.has(Object.keys(item.referencedSources)[0]));
                        })
                            .reduce(function (acc, item) {
                            acc[item.sourceLogicalName] = acc[item.sourceLogicalName] || [];
                            acc[item.sourceLogicalName].push(item);
                            return acc;
                        }, {});
                        if (!(remainingDepth > 0)) return [3 /*break*/, 3];
                        nextEntities = Array.from(new Set(Object.values(groupedMetadata)
                            .flat()
                            .map(function (item) { return Object.keys(item.referencedSources)[0]; }))).filter(function (entity) { return !Object.keys(groupedMetadata).includes(entity) && allowedEntities.has(entity); });
                        return [4 /*yield*/, this.loadRelationships(nextEntities, allowedEntities, allowAllForLastHop, remainingDepth - 1)];
                    case 2:
                        result = _a.sent();
                        Object.entries(result).forEach(function (_a) {
                            var _b = __read(_a, 2), key = _b[0], value = _b[1];
                            groupedMetadata[key] = value;
                        });
                        _a.label = 3;
                    case 3: return [2 /*return*/, groupedMetadata];
                }
            });
        }); };
        _this.loadDataverseMetadata = function (entities, allowPicklistsForTokens, type) { return __awaiter(_this, void 0, void 0, function () {
            var metadata, groupedMetadata;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.metadataService.getDataverseTableMetadataItems(entities, false, true)];
                    case 1:
                        metadata = (_a.sent()).value;
                        groupedMetadata = entities.reduce(function (acc, entity) {
                            var filtered = metadata.filter(function (item) { return item.sourceLogicalName === entity; }).filter(function (item) { return _this.filterAllowedTokenAttributes(item, allowPicklistsForTokens, type); });
                            acc[entity] = filtered;
                            return acc;
                        }, {});
                        return [2 /*return*/, groupedMetadata];
                }
            });
        }); };
        _this.createBindingPathItems = function (items) {
            var bindingTreeItems = [];
            if (items === null || items === void 0 ? void 0 : items.length) {
                for (var i = 0; i < items.length; i++) {
                    bindingTreeItems.push({
                        type: "RelatedData",
                        item: items[i]
                    });
                }
            }
            return bindingTreeItems;
        };
        return _this;
    }
    ExistingPlaceholderRetrivalService.prototype.serviceStart = function (appContext) {
        _super.prototype.serviceStart.call(this, appContext);
        this.cds = this.appContext.getService(CommonDataServiceName);
        this.telemetryService = this.appContext.getService(TelemetryServiceName);
        this.targetEntitiesExtractor = this.appContext.getService(TargetEntitiesExtractorServiceName);
        this.placeholderVisualization = this.appContext.getService(PlaceholderVisualizationServiceName);
        this.predefinedPlaceholderService = new PredefinedPlaceholderDataService(this.cds);
        this.expirationCacheService = this.appContext.getService(ExpirationCacheServiceName);
        this.metadataService = this.appContext.getService(CachedMetadataStoreServiceName);
    };
    ExistingPlaceholderRetrivalService.prototype.getBusinessUnitFilter = function (businessUnit) {
        return __awaiter(this, void 0, void 0, function () {
            var isBuScopingEnabled;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, isBusinessUnitScopingEnabled()];
                    case 1:
                        isBuScopingEnabled = _a.sent();
                        return [2 /*return*/, isBuScopingEnabled ? "&$filter=_owningbusinessunit_value eq '".concat(businessUnit, "'") : ""];
                }
            });
        });
    };
    return ExistingPlaceholderRetrivalService;
}(Service));
export { ExistingPlaceholderRetrivalService };
