var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { DataTypeCRM, DateTimeBehaviorCRM, SourceVirtualTableTypeCRM, StringFormatTypeCRM } from "./InternalInterfaces";
var MaxUrlLength = 2083;
var CommaAndQuotesLength = 3;
export function extractDisplayNames(displayName, languageCode, logicalName) {
    var _a, _b, _c;
    var retValue = (_b = (_a = displayName === null || displayName === void 0 ? void 0 : displayName.LocalizedLabels) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.LanguageCode === languageCode; })) === null || _b === void 0 ? void 0 : _b.Label;
    if (!retValue) {
        retValue = ((_c = displayName === null || displayName === void 0 ? void 0 : displayName.UserLocalizedLabel) === null || _c === void 0 ? void 0 : _c.LanguageCode) === languageCode ? displayName.UserLocalizedLabel.Label : logicalName;
    }
    return retValue;
}
export function removeCommaFromEnd(value) {
    if (value.charAt(value.length - 1) !== ",") {
        return value;
    }
    return value.slice(0, -1);
}
export function isMaxUrlLengthReached(currentLogicalNamesLength, currentLogicalNameLength, urlLengthWithoutEntities) {
    return currentLogicalNamesLength + currentLogicalNameLength + CommaAndQuotesLength + urlLengthWithoutEntities > MaxUrlLength;
}
export function metadataItemCRMToMetadataItem(miCRM) {
    return {
        sourceType: miCRM.msdynmkt_sourcetype,
        sourceLogicalName: miCRM.msdynmkt_sourcelogicalname,
        sourcePrimaryId: miCRM.msdynmkt_sourceprimaryid,
        sourceSetName: miCRM.msdynmkt_sourcesetname,
        sourceDisplayName: miCRM.msdynmkt_sourcedisplayname,
        sourceIsVirtual: miCRM.msdynmkt_sourceisvirtual,
        sourceVirtualTableType: sourceVirtualTableTypeCRMToDomain(miCRM.msdynmkt_sourcevirtualtabletype),
        nativeId: miCRM.msdynmkt_nativeid,
        logicalName: miCRM.msdynmkt_logicalname,
        displayName: miCRM.msdynmkt_displayname,
        languageCode: miCRM.msdynmkt_languagecode,
        iconPath: miCRM.msdynmkt_iconpath || null,
        isSecured: miCRM.msdynmkt_issecured,
        isRequired: miCRM.msdynmkt_isrequired,
        dataType: dataTypeCRMToDataType(miCRM.msdynmkt_datatype),
        referencedSources: referencedSourcesStringToObject(miCRM.msdynmkt_referencedsourcesasjson),
        navigationPaths: miCRM.msdynmkt_navigationpathsasjson == null ? null : JSON.parse(miCRM.msdynmkt_navigationpathsasjson),
        fullMetadata: miCRM.msdynmkt_fullmetadataasjson || null,
        targetAudience: miCRM.msdynmkt_targetaudienceasjson == null ? null : JSON.parse(miCRM.msdynmkt_targetaudienceasjson),
        dateTimeBehavior: dateTimeBehaviorCRMToDomain(miCRM.msdynmkt_datetimebehavior),
        stringFormatType: stringFormatTypeCRMToDomain(miCRM.msdynmkt_stringformattype),
        businessUnit: null
    };
}
function referencedSourcesStringToObject(referencedSourcesJson) {
    var e_1, _a;
    var _b;
    if (referencedSourcesJson == null) {
        return null;
    }
    var res = {};
    var parsed = JSON.parse(referencedSourcesJson);
    try {
        for (var _c = __values(Object.keys(parsed)), _d = _c.next(); !_d.done; _d = _c.next()) {
            var key = _d.value;
            var refSource = parsed[key];
            res[key] = {
                displayName: (_b = refSource === null || refSource === void 0 ? void 0 : refSource.displayName) !== null && _b !== void 0 ? _b : key,
                primaryId: refSource === null || refSource === void 0 ? void 0 : refSource.primaryId,
                referencingAttribute: refSource === null || refSource === void 0 ? void 0 : refSource.referencingAttribute,
                referencedAttribute: refSource === null || refSource === void 0 ? void 0 : refSource.referencedAttribute,
                isTargetAudience: refSource === null || refSource === void 0 ? void 0 : refSource.isTargetAudience,
                isVirtual: refSource === null || refSource === void 0 ? void 0 : refSource.isVirtual,
                virtualTableType: refSource === null || refSource === void 0 ? void 0 : refSource.virtualTableType,
            };
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return res;
}
function dateTimeBehaviorCRMToDomain(dateTimeBehavior) {
    if (dateTimeBehavior) {
        switch (dateTimeBehavior) {
            case DateTimeBehaviorCRM.DateOnly:
                return "dateOnly";
            case DateTimeBehaviorCRM.UserLocal:
                return "userLocal";
            case DateTimeBehaviorCRM.TimeZoneIndependent:
            default:
                return "timeZoneIndependent";
        }
    }
}
function stringFormatTypeCRMToDomain(stringFormatType) {
    if (stringFormatType) {
        switch (stringFormatType) {
            case StringFormatTypeCRM.Email:
                return "email";
            case StringFormatTypeCRM.TextArea:
                return "textArea";
            case StringFormatTypeCRM.Url:
                return "url";
            case StringFormatTypeCRM.TickerSymbol:
                return "tickerSymbol";
            case StringFormatTypeCRM.PhoneticGuide:
                return "phoneticGuide";
            case StringFormatTypeCRM.VersionNumber:
                return "versionNumber";
            case StringFormatTypeCRM.Phone:
                return "phone";
            case StringFormatTypeCRM.Json:
                return "json";
            case StringFormatTypeCRM.RichText:
                return "richText";
            case StringFormatTypeCRM.Text:
            default:
                return "text";
        }
    }
}
function dataTypeCRMToDataType(dataTypeCRM) {
    switch (dataTypeCRM) {
        case DataTypeCRM.BigInt:
            return "bigInt";
        case DataTypeCRM.Money:
            return "money";
        case DataTypeCRM.DateTime:
            return "dateTime";
        case DataTypeCRM.Decimal:
            return "decimal";
        case DataTypeCRM.Integer:
            return "integer";
        case DataTypeCRM.String:
            return "string";
        case DataTypeCRM.File:
            return "file";
        case DataTypeCRM.Double:
            return "double";
        case DataTypeCRM.Image:
            return "image";
        case DataTypeCRM.Lookup:
            return "lookup";
        case DataTypeCRM.MultiSelectPicklist:
            return "multiSelectPicklist";
        case DataTypeCRM.Memo:
            return "memo";
        case DataTypeCRM.Picklist:
            return "picklist";
        case DataTypeCRM.Status:
            return "status";
        case DataTypeCRM.State:
            return "state";
        case DataTypeCRM.Boolean:
            return "boolean";
        case DataTypeCRM.Uniqueidentifier:
            return "uniqueidentifier";
        case DataTypeCRM.OneToManyRelationship:
            return "oneToManyRelationship";
        case DataTypeCRM.ManyToManyRelationship:
            return "manyToManyRelationship";
        case DataTypeCRM.EntityValue:
            return "entityValue";
        case DataTypeCRM.EntityCollection:
            return "entityCollection";
        case DataTypeCRM.ManyToOneRelationship:
            return "manyToOneRelationship";
        case DataTypeCRM.Other:
        default:
            return "other";
    }
}
function sourceVirtualTableTypeCRMToDomain(sourceVirtualTableType) {
    if (sourceVirtualTableType) {
        switch (sourceVirtualTableType) {
            case SourceVirtualTableTypeCRM.Elastic:
                return "elastic";
            case SourceVirtualTableTypeCRM.Other:
                return "other";
            case SourceVirtualTableTypeCRM.None:
            default:
                return "none";
        }
    }
}
