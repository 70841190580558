import { BinaryOperator, ConditionOperandType, ConditionTypes, ParameterTypes, TransformationType, UnaryOperator } from "@cxp-shared/contracts";
import { ExpressionParser } from "@cxp-shared/power-automate-transformation";
import { DataSources } from "../Constants/DataSources";
import { PlaceholderGenerator } from "../PlaceholderGenerator";
var generateTransformation = function (isUnary, operator, operand) {
    var expression = isUnary
        ? {
            type: "UnaryInlineOperatorExpression",
            operator: operator
        }
        : {
            type: "BinaryInlineOperatorExpression",
            operator: operator,
            rightOperand: operand
        };
    return {
        type: TransformationType.InlineExpression,
        expression: expression,
        handlesNull: isUnary && (operator === UnaryOperator.isEmpty || operator === UnaryOperator.isNotEmpty)
    };
};
var createPropertyWithExpression = function (expression) {
    return {
        transformation: {
            type: TransformationType.ComplexExpression,
            expression: expression
        },
        type: ["boolean"]
    };
};
var parseTransformation = function (operandType, transformation) {
    try {
        var typedTransformation = transformation;
        // Old PA expressions get converted
        if (typedTransformation.type === TransformationType.PowerAutomateExpressionTemplate) {
            var parsedExpression = ExpressionParser.parseExpressionWithBinding(operandType, typedTransformation.expression);
            return {
                type: "BinaryInlineOperatorExpression",
                operator: parsedExpression.operator,
                rightOperand: {
                    type: ParameterTypes.static,
                    valueType: operandType,
                    value: sanitazeValue(operandType, parsedExpression.operator, parsedExpression.value)
                }
            };
        }
        if (typedTransformation.type === TransformationType.InlineExpression) {
            return typedTransformation.expression;
        }
        return null;
    }
    catch (error) {
        return null;
    }
};
var extractOperandType = function (dataType) {
    switch (dataType) {
        case "integer":
        case "decimal":
        case "bigInt":
        case "money":
        case "double":
            return ConditionOperandType.number;
        case "boolean":
            return ConditionOperandType.boolean;
        case "state":
        case "status":
        case "picklist":
            return ConditionOperandType.picklist;
        case "multiSelectPicklist":
            return ConditionOperandType.multiselectpicklist;
        case "memo":
        case "string":
        case "uniqueidentifier":
            return ConditionOperandType.string;
        case "lookup":
            return ConditionOperandType.lookup;
        case "dateTime":
            return ConditionOperandType.datetime;
        default:
            return ConditionOperandType.unknown;
    }
};
var sanitazeValue = function (operandType, operator, value) {
    switch (operandType) {
        case ConditionOperandType.number:
            return parseFloat(value) ? "".concat(parseFloat(value)) : "0";
        case ConditionOperandType.string:
        case ConditionOperandType.datetime:
            return value || "";
        case ConditionOperandType.boolean:
            return value === "true" ? "true" : "false";
        case ConditionOperandType.picklist:
        case ConditionOperandType.multiselectpicklist:
            // Use first selected item, if switching to only single selection
            if (operator === BinaryOperator.equals || operator === BinaryOperator.notEquals) {
                var selectedValues = (value === null || value === void 0 ? void 0 : value.split(",")) || [];
                if (selectedValues.length > 1) {
                    return selectedValues[0];
                }
            }
            return value || null;
        default:
            return value;
    }
};
var convertInlinePlaceholderToComplex = function (placeholder) {
    if (!placeholder) {
        return placeholder;
    }
    var binding = placeholder.binding;
    var transformation = placeholder.transformation;
    var newExpression;
    if ((transformation === null || transformation === void 0 ? void 0 : transformation.type) === TransformationType.InlineExpression) {
        var expression = transformation.expression;
        var leftOperand = PlaceholderGenerator.generateEmptyPlaceholder({ placeholderCategory: "general", title: null });
        leftOperand.binding = binding;
        if (expression.type === "BinaryInlineOperatorExpression") {
            newExpression = {
                type: ConditionTypes.binaryOperator,
                operator: expression.operator,
                leftOperand: leftOperand,
                rightOperand: expression.rightOperand
            };
        }
        else {
            newExpression = {
                type: ConditionTypes.unaryOperator,
                operator: expression.operator,
                operand: leftOperand
            };
        }
    }
    else if ((transformation === null || transformation === void 0 ? void 0 : transformation.type) === TransformationType.ComplexExpression) {
        newExpression = transformation.expression;
    }
    var result = PlaceholderGenerator.generatePlaceholderWithBindingInputParameters({
        title: placeholder.title,
        placeholderType: ["boolean", "null"],
        entityProviderName: DataSources.None,
        entityAttributeLogicalName: null,
        bindingInputParameters: {},
        outputType: undefined,
        placeholderCategory: "condition",
        isInternal: placeholder.isInternal,
        defaultValue: false,
        transformation: {
            type: TransformationType.ComplexExpression,
            expression: newExpression,
            handlesNull: true
        }
    });
    return JSON.parse(JSON.stringify(result));
};
var convertExpressionSourceToReference = function (expression, reference) {
    if (!expression) {
        return expression;
    }
    try {
        var expressionCopy = JSON.parse(JSON.stringify(expression));
        switch (expressionCopy.type) {
            case ConditionTypes.binaryOperator: {
                var binaryOperatorExpression = expressionCopy;
                var placeholder = binaryOperatorExpression.leftOperand;
                placeholder.binding = {
                    source: reference,
                    outputPath: placeholder.binding.outputPath,
                    outputType: placeholder.binding.outputType
                };
                break;
            }
            case ConditionTypes.unaryOperator: {
                var unaryOperatorExpression = expressionCopy;
                var placeholder = unaryOperatorExpression.operand;
                placeholder.binding = {
                    source: reference,
                    outputPath: placeholder.binding.outputPath,
                    outputType: placeholder.binding.outputType
                };
                break;
            }
            case ConditionTypes.grouping: {
                var groupingExpression = expressionCopy;
                groupingExpression.expressions = groupingExpression.expressions.map(function (e) { return convertExpressionSourceToReference(e, reference); });
                break;
            }
        }
        return expressionCopy;
    }
    catch (_a) {
        return null;
    }
};
var convertExpressionReferenceToSource = function (expression, source, sourceType) {
    if (!expression) {
        return expression;
    }
    try {
        var expressionCopy = JSON.parse(JSON.stringify(expression));
        switch (expressionCopy.type) {
            case ConditionTypes.binaryOperator: {
                var binaryOperatorExpression = expressionCopy;
                var placeholder = binaryOperatorExpression.leftOperand;
                placeholder.binding = {
                    source: source,
                    inputs: {
                        sourceType: {
                            value: sourceType
                        }
                    },
                    outputPath: placeholder.binding.outputPath,
                    outputType: placeholder.binding.outputType
                };
                break;
            }
            case ConditionTypes.unaryOperator: {
                var unaryOperatorExpression = expressionCopy;
                var placeholder = unaryOperatorExpression.operand;
                placeholder.binding = {
                    source: source,
                    inputs: {
                        sourceType: {
                            value: sourceType
                        }
                    },
                    outputPath: placeholder.binding.outputPath,
                    outputType: placeholder.binding.outputType
                };
                break;
            }
            case ConditionTypes.grouping: {
                var groupingExpression = expressionCopy;
                groupingExpression.expressions = groupingExpression.expressions.map(function (e) { return convertExpressionReferenceToSource(e, source, sourceType); });
                break;
            }
        }
        return expressionCopy;
    }
    catch (_a) {
        return null;
    }
};
export var ConditionalPlaceholderUtils = {
    generateTransformation: generateTransformation,
    parseTransformation: parseTransformation,
    extractOperandType: extractOperandType,
    sanitazeValue: sanitazeValue,
    convertInlinePlaceholderToComplex: convertInlinePlaceholderToComplex,
    convertExpressionSourceToReference: convertExpressionSourceToReference,
    convertExpressionReferenceToSource: convertExpressionReferenceToSource,
    createPropertyWithExpression: createPropertyWithExpression
};
