export function getExternalConfiguration() {
    if (!window) {
        return {};
    }
    var extensionName = "d365mkt";
    if (!window[extensionName]) {
        window[extensionName] = {};
    }
    return window[extensionName];
}
