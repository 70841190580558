export var StatusCode;
(function (StatusCode) {
    StatusCode[StatusCode["Draft"] = 1] = "Draft";
    StatusCode[StatusCode["ReadyToSend"] = 2] = "ReadyToSend";
    StatusCode[StatusCode["LiveEditable"] = 3] = "LiveEditable";
})(StatusCode || (StatusCode = {}));
export var StateCode;
(function (StateCode) {
    StateCode[StateCode["Active"] = 0] = "Active";
    StateCode[StateCode["Inactive"] = 1] = "Inactive";
})(StateCode || (StateCode = {}));
export var Attributes = {
    state: "statecode",
    status: "statuscode"
};
export var preventDeleteStatuses = [StatusCode.ReadyToSend, StatusCode.LiveEditable];
export var preventDeactivateStatuses = [StatusCode.ReadyToSend];
export var EntityNames = {
    PushNotification: "msdynmkt_pushnotification",
    Sms: "msdynmkt_sms",
    Email: "msdynmkt_email",
    MarketingForm: "msdynmkt_marketingform",
    CustomChannelMessage: "msdynmkt_customchannelmessage",
    EmailTemplate: "msdynmkt_emailtemplate"
};
export var PushNotificationEntity = {
    title: "msdynmkt_title",
    subtitle: "msdynmkt_subtitle",
    message: "msdynmkt_message",
    imageId: "msdynmkt_imageid",
    onClickBehavior: "msdynmkt_onclickbehavior",
    link: "msdynmkt_link",
    name: "msdynmkt_name",
    appName: "msdynmkt_appname",
    placeholders: "msdynmkt_placeholders",
    messageDesignation: "msdynmkt_messagedesignation",
    header_placeholders: "header_msdynmkt_placeholders",
    entityId: "msdynmkt_pushnotificationid",
    statuscode: "statuscode"
};
export var SmsEntity = {
    name: "msdynmkt_name",
    senderPhoneNumber: "msdynmkt_senderphonenumber",
    recipient: "msdynmkt_recipient",
    designerText: "msdynmkt_designertext",
    text: "msdynmkt_text",
    messageDesignation: "msdynmkt_messagedesignation",
    placeholders: "msdynmkt_placeholders",
    statusCode: "statuscode"
};
