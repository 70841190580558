import { DataSources } from "../../Constants/DataSources";
import { PlaceholderUtils } from "../../Utilities/PlaceholderUtils";
var sourceTypePerSourceLogicalNames = {
    msdynmkt_brandprofile: DataSources.BrandDataSource
};
export var extractDataverseTargetAudience = function (dataverseBindings) {
    if (dataverseBindings.length === 0) {
        return [];
    }
    return [PlaceholderUtils.extractSourceType(dataverseBindings[0])];
};
export var extractDataverseRootSourceType = function (sourceLogicalName, productSource, isStaticBinding) {
    if (productSource === "Sales") {
        return DataSources.SalesDataSource;
    }
    if (productSource === "Journey") {
        return DataSources.JourneyDataSource;
    }
    if (productSource === "EventRegistrationForm") {
        return DataSources.EventRegistrationFormDataSource;
    }
    if (isStaticBinding) {
        return DataSources.DataverseDataSource;
    }
    var sourceType = sourceTypePerSourceLogicalNames[sourceLogicalName];
    return sourceType ? sourceType : DataSources.CdsDataSource;
};
