var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { DataSources } from "../Constants/DataSources";
import { PlaceholderAttributeFilter } from "../Utilities/PlaceholderAttributeFilter";
import { extractSourceType } from "../Utilities/TypeExtractor";
function generateComplexBinding(placeholderGenerator, pathItems, placeholderCategory, productSource, isStaticBinding) {
    var e_1, _a;
    var attribute = null;
    var bindingInputParameters;
    var binding;
    try {
        for (var pathItems_1 = __values(pathItems), pathItems_1_1 = pathItems_1.next(); !pathItems_1_1.done; pathItems_1_1 = pathItems_1.next()) {
            var pathItem = pathItems_1_1.value;
            switch (pathItem.type) {
                case "DataSource":
                case "Entity":
                    break;
                case "RelatedData":
                case "Attribute":
                    attribute = pathItem.item;
                    bindingInputParameters = {
                        sourceType: placeholderGenerator.generateBindingInputParameter({
                            inputParameterValue: attribute.sourceLogicalName
                        })
                    };
                    if (binding != null) {
                        bindingInputParameters.sourcePrimaryIdName = placeholderGenerator.generateBindingInputParameter({
                            inputParameterValue: attribute.sourcePrimaryId
                        });
                        bindingInputParameters.recordsetType = placeholderGenerator.generateBindingInputParameter({
                            inputParameterValue: attribute.sourceSetName
                        });
                        bindingInputParameters.recordId = placeholderGenerator.generateBindingInputParameter({
                            inputParameterBinding: binding
                        });
                    }
                    binding = placeholderGenerator.generateBinding({
                        entityProviderName: extractSourceType(attribute, productSource, binding == null, isStaticBinding),
                        entityAttributeLogicalName: attribute.logicalName,
                        inputBindings: bindingInputParameters,
                        outputType: attribute.dataType
                    });
                    break;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (pathItems_1_1 && !pathItems_1_1.done && (_a = pathItems_1.return)) _a.call(pathItems_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    if (PlaceholderAttributeFilter.hasListType(attribute)) {
        var firstReferenceKey = Object.keys(attribute.referencedSources).find(function (k) { return k; });
        var firstReferenceSource = attribute.referencedSources[firstReferenceKey];
        bindingInputParameters.sourceType = placeholderGenerator.generateBindingInputParameter({
            inputParameterValue: firstReferenceKey
        });
        bindingInputParameters.sourcePrimaryIdName = placeholderGenerator.generateBindingInputParameter({
            inputParameterValue: firstReferenceSource.primaryId
        });
        bindingInputParameters.relatingRecordId = placeholderGenerator.generateBindingInputParameter({
            inputParameterBinding: placeholderGenerator.generateBinding({
                entityProviderName: extractSourceType(attribute, productSource, bindingInputParameters.recordId == null, isStaticBinding),
                entityAttributeLogicalName: attribute.sourcePrimaryId,
                inputBindings: {
                    sourceType: placeholderGenerator.generateBindingInputParameter({
                        inputParameterValue: attribute.sourceLogicalName
                    }),
                    sourcePrimaryIdName: placeholderGenerator.generateBindingInputParameter({
                        inputParameterValue: attribute.sourcePrimaryId
                    }),
                    recordId: bindingInputParameters.recordId
                },
                outputType: "uniqueidentifier"
            })
        });
        delete bindingInputParameters.recordId;
        bindingInputParameters.topCount = placeholderGenerator.generateBindingInputParameter({
            inputParameterValue: 10
        });
        bindingInputParameters.orderBy = placeholderGenerator.generateBindingInputParameter({
            inputParameterValue: []
        });
        bindingInputParameters.relationshipName = placeholderGenerator.generateBindingInputParameter({
            inputParameterValue: attribute.logicalName
        });
        bindingInputParameters.relatingRecordName = placeholderGenerator.generateBindingInputParameter({
            inputParameterValue: attribute.sourceLogicalName
        });
    }
    return {
        binding: placeholderGenerator.generateBinding({
            inputBindings: bindingInputParameters,
            entityProviderName: placeholderCategory === "list" ? DataSources.DataverseDataSource : binding.source,
            entityAttributeLogicalName: placeholderCategory === "list" ? "" : attribute === null || attribute === void 0 ? void 0 : attribute.logicalName,
            outputType: placeholderCategory === "list" ? "entityCollection" : attribute === null || attribute === void 0 ? void 0 : attribute.dataType
        }),
        attribute: attribute
    };
}
export var BindingGenerator = {
    generateComplexBinding: generateComplexBinding
};
