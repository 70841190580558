var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { FeatureFlagsServiceName } from "@cxp-shared/feature-flags";
import { Service } from "@cxp-shared/platform/Service";
import { CommonDataServiceName } from "@cxp-shared/services/CDS";
import { ContactLogicalName, CustomerProfileLogicalName, LeadLogicalName, VirtualSegmentEntityConstants } from "./Segments.Service.Types";
var SEGMENT_EXPORT_CUSTOM_ACTION = "msdyncrm_StartSegmentationExport";
var SEGMENT_EXPORT_CUSTOM_ACTION_LOCAL_DEBUG = "msdynmkt_StartSegmentationExport";
//Used to validate that request is coming from CXP and we should create CXP segment entity
var SEGMENT_EXPORT_SOURCE = "CXP";
var SegmentService = /** @class */ (function (_super) {
    __extends(SegmentService, _super);
    function SegmentService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.segmentPromises = new Map();
        _this.cachedSegments = new Map();
        return _this;
    }
    SegmentService.prototype.serviceStart = function (appContext) {
        _super.prototype.serviceStart.call(this, appContext);
        this.cds = this.appContext.getService(CommonDataServiceName);
        this.featureFlagService = this.appContext.getService(FeatureFlagsServiceName);
    };
    SegmentService.buildSegment = function (segmentRecord) {
        return {
            id: segmentRecord.msdynmkt_virtualsegmentid.toLowerCase(),
            name: segmentRecord.msdynmkt_name,
            state: segmentRecord.msdynmkt_statecode,
            baseEntity: segmentRecord.msdynmkt_baseentity,
            requiresExport: segmentRecord.msdynmkt_requiresexport,
            status: segmentRecord.msdynmkt_statuscode,
            sourceEntityId: segmentRecord.msdynmkt_sourceentityid,
            memberCount: segmentRecord.msdynmkt_membercount,
            owningBusinessUnit: segmentRecord._msdynmkt_owningbusinessunit_value,
            source: segmentRecord.msdynmkt_source
        };
    };
    SegmentService.prototype.getSpecificSegmentsAsync = function (ids, bypassCache) {
        if (bypassCache === void 0) { bypassCache = false; }
        return __awaiter(this, void 0, void 0, function () {
            var promises, ids_1, ids_1_1, id;
            var e_1, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!ids || ids.length === 0) {
                            return [2 /*return*/, []];
                        }
                        promises = [];
                        try {
                            for (ids_1 = __values(ids), ids_1_1 = ids_1.next(); !ids_1_1.done; ids_1_1 = ids_1.next()) {
                                id = ids_1_1.value;
                                promises.push(this.getSegmentAsync(undefined, id, bypassCache));
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (ids_1_1 && !ids_1_1.done && (_a = ids_1.return)) _a.call(ids_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        return [4 /*yield*/, Promise.all(promises)];
                    case 1: return [2 /*return*/, (_b.sent()).filter(function (s) { return !!s; })];
                }
            });
        });
    };
    SegmentService.prototype.getPreferredSegmentsAsync = function (entityLogicalName) {
        return __awaiter(this, void 0, void 0, function () {
            var segments, filter, query, results;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        segments = [];
                        filter = "";
                        if (entityLogicalName) {
                            filter = "&$filter=".concat(VirtualSegmentEntityConstants.BaseEntityField, " eq '").concat(entityLogicalName, "'");
                        }
                        query = "$select=".concat(SegmentService.requiredFields.join(",")).concat(filter, "&$top=1000&$orderby=").concat(VirtualSegmentEntityConstants.CreatedDate, " desc");
                        return [4 /*yield*/, this.cds.getRecords(VirtualSegmentEntityConstants.EntityPluralName, query)];
                    case 1:
                        results = _a.sent();
                        results.forEach(function (record) {
                            var segment = SegmentService.buildSegment(record);
                            _this.addToCache(segment);
                            segments.push(segment);
                        });
                        return [2 /*return*/, segments];
                }
            });
        });
    };
    SegmentService.prototype.getQueryBasedSegmentsAsync = function (entityLogicalName, condition) {
        return __awaiter(this, void 0, void 0, function () {
            var segments, filter, queryConditon, query, results;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        segments = [];
                        filter = "";
                        queryConditon = condition.map(function (item) { return "startswith(msdynmkt_name,'" + item + "')"; }).join(" or ");
                        if (entityLogicalName) {
                            filter = "&$filter=".concat(VirtualSegmentEntityConstants.BaseEntityField, " eq '").concat(entityLogicalName, "' and (").concat(queryConditon, ")");
                        }
                        else {
                            filter = "&$filter=".concat(queryConditon);
                        }
                        query = "$select=".concat(SegmentService.requiredFields.join(",")).concat(filter);
                        return [4 /*yield*/, this.cds.getRecords(VirtualSegmentEntityConstants.EntityPluralName, query)];
                    case 1:
                        results = _a.sent();
                        results.forEach(function (record) {
                            var segment = SegmentService.buildSegment(record);
                            _this.addToCache(segment);
                            segments.push(segment);
                        });
                        return [2 /*return*/, segments];
                }
            });
        });
    };
    SegmentService.prototype.getSegmentAsync = function (entityType, id, bypassCache) {
        if (bypassCache === void 0) { bypassCache = false; }
        return __awaiter(this, void 0, void 0, function () {
            var segment;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!id) {
                            return [2 /*return*/, undefined];
                        }
                        id = id.toLowerCase();
                        segment = bypassCache === false ? this.getSegmentInternal(entityType, id) : undefined;
                        if (segment === null) {
                            // We have already tried to retrieve this segment and failed.
                            return [2 /*return*/, undefined];
                        }
                        else if (segment) {
                            return [2 /*return*/, segment];
                        }
                        return [4 /*yield*/, this.getSegmentAsyncInternal(id)];
                    case 1:
                        // First time being requested, retrieve it from the service.
                        segment = _a.sent();
                        if (this.matchesEntityName(segment, entityType)) {
                            return [2 /*return*/, segment];
                        }
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    SegmentService.prototype.getSegment = function (entityLogicalName, id) {
        if (!id) {
            return undefined;
        }
        id = id.toLowerCase();
        var segment = this.getSegmentInternal(entityLogicalName, id);
        if (this.matchesEntityName(segment, entityLogicalName)) {
            return segment;
        }
        return undefined;
    };
    /**
     * Will only return entities that match the name which have previously been cached.
     */
    SegmentService.prototype.getSegmentByName = function (entityLogicalName, name) {
        var e_2, _a;
        try {
            // Only retrieves cached segments by name.
            for (var _b = __values(this.cachedSegments), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), key = _d[0], segment = _d[1];
                if (segment.name.toLocaleLowerCase() === name.toLocaleLowerCase()) {
                    if (this.matchesEntityName(segment, entityLogicalName)) {
                        return segment;
                    }
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return undefined;
    };
    SegmentService.prototype.getSegmentFromAllEntitiesAsync = function (id, bypassCache) {
        if (bypassCache === void 0) { bypassCache = false; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.getSegmentAsync(undefined, id, bypassCache)];
            });
        });
    };
    SegmentService.prototype.getSegmentBySourceEntityIdAsync = function (sourceEntityLogicalName, sourceEntityRecordId) {
        return __awaiter(this, void 0, void 0, function () {
            var query, queryResults, segment;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = "$top=1";
                        query += "&$select=".concat(SegmentService.requiredFields.join(","));
                        query += "&$filter=".concat(VirtualSegmentEntityConstants.SourceEntityField, " eq '").concat(sourceEntityLogicalName, "' and ").concat(VirtualSegmentEntityConstants.SourceEntityIdField, " eq '").concat(sourceEntityRecordId, "'");
                        return [4 /*yield*/, this.cds.getRecords(VirtualSegmentEntityConstants.EntityPluralName, query)];
                    case 1:
                        queryResults = _a.sent();
                        if ((queryResults === null || queryResults === void 0 ? void 0 : queryResults.length) > 0) {
                            segment = SegmentService.buildSegment(queryResults[0]);
                            this.addToCache(segment);
                            return [2 /*return*/, segment];
                        }
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    SegmentService.prototype.getSegmentFromAllEntities = function (id) {
        if (!id) {
            return undefined;
        }
        id = id.toLowerCase();
        // Only retrieves cached segments by id.
        return this.cachedSegments.get(id);
    };
    SegmentService.prototype.startSegmentExport = function (sourceId, segmentId) {
        return __awaiter(this, void 0, void 0, function () {
            var segmentExportPluginName, result, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        segmentExportPluginName = SEGMENT_EXPORT_CUSTOM_ACTION;
                        return [4 /*yield*/, this.featureFlagService.isFeatureEnabledAsync("LOCAL_DEBUG")];
                    case 1:
                        if (_a.sent()) {
                            segmentExportPluginName = SEGMENT_EXPORT_CUSTOM_ACTION_LOCAL_DEBUG;
                        }
                        return [4 /*yield*/, this.cds.executePlugin(segmentExportPluginName, {
                                SegmentationExportRequest: JSON.stringify({ SegmentId: segmentId, Source: SEGMENT_EXPORT_SOURCE })
                            }, {
                                sourceId: sourceId,
                                segmentId: segmentId
                            })];
                    case 2:
                        result = _a.sent();
                        response = JSON.parse(result.SegmentationExportResponse);
                        response.id = segmentId;
                        return [2 /*return*/, response];
                }
            });
        });
    };
    SegmentService.prototype.isSupportedSegmentation = function (entityLogicalName) {
        switch (entityLogicalName) {
            case ContactLogicalName:
            case CustomerProfileLogicalName:
            case LeadLogicalName:
                return true;
            default:
                return false;
        }
    };
    SegmentService.prototype.addToCache = function (segment) {
        this.cachedSegments.set(segment.id, segment);
    };
    SegmentService.prototype.getSegmentInternal = function (entityLogicalName, id) {
        var segment;
        if (this.cachedSegments.has(id)) {
            segment = this.cachedSegments.get(id);
        }
        if (segment) {
            if (this.matchesEntityName(segment, entityLogicalName)) {
                return segment;
            }
        }
        return undefined;
    };
    SegmentService.prototype.getSegmentAsyncInternal = function (id) {
        var _this = this;
        // See if we have already requested it, if so return the promise
        var promise = this.segmentPromises.get(id);
        if (promise) {
            return promise;
        }
        promise = this.cds
            .getRecord(VirtualSegmentEntityConstants.EntityPluralName, id, SegmentService.requiredFields)
            .then(function (segmentRecord) {
            if (!(segmentRecord === null || segmentRecord === void 0 ? void 0 : segmentRecord.msdynmkt_virtualsegmentid)) {
                // This means that the segment does not exist.
                _this.cachedSegments.set(id, null);
                return undefined;
            }
            var segment = SegmentService.buildSegment(segmentRecord);
            _this.addToCache(segment);
            _this.segmentPromises.delete(id);
            return segment;
        })
            .catch(function () {
            _this.cachedSegments.set(id, null);
            _this.segmentPromises.delete(id);
            return undefined;
        });
        this.segmentPromises.set(id, promise);
        return this.segmentPromises.get(id);
    };
    SegmentService.prototype.matchesEntityName = function (segment, entityName) {
        if (!segment) {
            return false;
        }
        return !entityName || entityName.toLowerCase() === segment.baseEntity.toLowerCase();
    };
    SegmentService.requiredFields = [
        VirtualSegmentEntityConstants.IdField,
        VirtualSegmentEntityConstants.NameField,
        VirtualSegmentEntityConstants.StateField,
        VirtualSegmentEntityConstants.BaseEntityField,
        VirtualSegmentEntityConstants.RequiresExport,
        VirtualSegmentEntityConstants.StatusCode,
        VirtualSegmentEntityConstants.SourceEntityIdField,
        VirtualSegmentEntityConstants.MemberCount,
        VirtualSegmentEntityConstants.OwningBusinessUnit,
        VirtualSegmentEntityConstants.SourceField
    ];
    return SegmentService;
}(Service));
export { SegmentService };
