import { Localization } from "./Localization";
import { CURRENTLY_EXECUTING_SCRIPT_BASE_URL } from "@cxp-shared/resource-loader/CurrentlyExecutingScriptInfo";
import { resolveFormPlaceholders } from "./resolveFormPlaceholders";
import { Logger } from "./Logger";
import { createFormLoaderFactory } from "./CreateFormLoaderFactory";
import { FormPlaceholder } from "./FormPlaceholder";
import { getExternalConfiguration } from "./GetExternalConfiguration";
import { createForm } from "./CreateForm";
import { renderFailedSubmission, renderSuccessfulSubmission } from "./ShowFeedback";
// load CJO forms
(function () {
    Localization.Initialize(CURRENTLY_EXECUTING_SCRIPT_BASE_URL, navigator.language);
    whenLoaded().then(function () {
        var externalConfiguration = getExternalConfiguration();
        if (!externalConfiguration.formLoadingStarted) {
            externalConfiguration.formLoadingStarted = true;
            var logger = new Logger();
            var formLoaderFactory = createFormLoaderFactory(logger);
            resolveFormPlaceholders(logger, formLoaderFactory);
        }
    });
})();
function whenLoaded() {
    return new Promise(function (resolve) {
        if (document.readyState !== "loading") {
            resolve();
        }
        else {
            var listener_1 = function () {
                document.removeEventListener("DOMContentLoaded", listener_1);
                resolve();
            };
            document.addEventListener("DOMContentLoaded", listener_1);
        }
    });
}
var ExternalApi = /** @class */ (function () {
    function ExternalApi() {
    }
    ExternalApi.FormPlaceholder = FormPlaceholder;
    ExternalApi.createForm = createForm;
    ExternalApi.renderFailedSubmission = renderFailedSubmission;
    ExternalApi.renderSuccessfulSubmission = renderSuccessfulSubmission;
    return ExternalApi;
}());
export { ExternalApi };
