var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var FormFieldsFilterRegistry = /** @class */ (function () {
    function FormFieldsFilterRegistry() {
        this.filters = [];
        this.filters = [];
    }
    FormFieldsFilterRegistry.prototype.initFilters = function (filters) {
        this.filters = filters;
    };
    FormFieldsFilterRegistry.prototype.getFilteredFields = function (unfilteredFields) {
        var filteredFields = [];
        this.filters.forEach(function (filterFunction) {
            filteredFields.push.apply(filteredFields, __spreadArray([], __read(unfilteredFields.filter(filterFunction)), false));
        });
        return filteredFields;
    };
    return FormFieldsFilterRegistry;
}());
export { FormFieldsFilterRegistry };
