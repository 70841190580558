var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Service } from "@cxp-shared/platform/Service";
import { TelemetryService } from "./TelemetryService";
var TelemetryServiceDecorator = /** @class */ (function (_super) {
    __extends(TelemetryServiceDecorator, _super);
    function TelemetryServiceDecorator(telemetryProviders, parametersProviders) {
        if (parametersProviders === void 0) { parametersProviders = []; }
        var _this = _super.call(this) || this;
        _this.telemetryProviders = telemetryProviders;
        _this.parametersProviders = parametersProviders;
        _this.telemetryProviders = telemetryProviders || [];
        _this.telemetryService = new TelemetryService(_this.telemetryProviders);
        return _this;
    }
    TelemetryServiceDecorator.prototype.logInfo = function (logMessage, parameters) {
        var extraLogParameters = this.parametersProviders.reduce(function (acc, provider) { return Object.assign(acc, provider.getLogParameters()); }, {});
        this.telemetryService.logInfo(logMessage, Object.assign(extraLogParameters, parameters));
    };
    TelemetryServiceDecorator.prototype.logError = function (logMessage, parameters) {
        var extraLogParameters = this.parametersProviders.reduce(function (acc, provider) { return Object.assign(acc, provider.getLogParameters()); }, {});
        this.telemetryService.logError(logMessage, Object.assign(extraLogParameters, parameters));
    };
    TelemetryServiceDecorator.prototype.logFunctionalEvent = function (eventName, eventData) {
        var extraLogParameters = this.parametersProviders.reduce(function (acc, provider) { return Object.assign(acc, provider.getLogParameters()); }, {});
        this.telemetryService.logFunctionalEvent(eventName, Object.assign(extraLogParameters, eventData));
    };
    TelemetryServiceDecorator.prototype.cloneForComponent = function (component) {
        return new TelemetryServiceDecorator(this.telemetryProviders.map(function (provider) { return provider.cloneForComponent(component); }), this.parametersProviders);
    };
    return TelemetryServiceDecorator;
}(Service));
export { TelemetryServiceDecorator };
