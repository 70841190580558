var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
function getTime() {
    if (performance) {
        return performance.now();
    }
    return Date.now();
}
var PerformanceScenario = /** @class */ (function () {
    function PerformanceScenario(scenarioName, properties, onScenarioComplete) {
        this.duration = -1;
        this.endTime = -1;
        this.properties = {};
        this.splitTimings = [];
        this.scenarioName = scenarioName;
        this.startTime = getTime();
        this.properties = properties ? __assign({}, properties) : {};
        this.onScenarioComplete = onScenarioComplete;
        this.splitTimings.push({
            name: "Scenario.Start",
            timestamp: 0
        });
    }
    PerformanceScenario.prototype.addSplitTiming = function (timingName) {
        this.splitTimings.push({
            name: timingName,
            timestamp: getTime() - this.startTime
        });
    };
    PerformanceScenario.prototype.startSplitTiming = function (timingName) {
        this.addSplitTiming("".concat(timingName, ".Start"));
    };
    PerformanceScenario.prototype.endSplitTiming = function (timingName) {
        this.addSplitTiming("".concat(timingName, ".End"));
    };
    PerformanceScenario.prototype.complete = function (properties) {
        if (properties) {
            this.addProperties(properties);
        }
        this.endTime = getTime();
        this.duration = this.endTime - this.startTime;
        this.splitTimings.push({
            name: "Scenario.End",
            timestamp: this.duration
        });
        this.onScenarioComplete(this);
    };
    PerformanceScenario.prototype.throw = function (errorMessage, errorCode, properties) {
        this.complete(__assign(__assign({}, (properties || {})), { errorMessage: errorMessage, errorCode: errorCode }));
    };
    PerformanceScenario.prototype.addProperties = function (properties) {
        this.properties = __assign(__assign({}, this.properties), properties);
    };
    return PerformanceScenario;
}());
export { PerformanceScenario };
